.myApplication_section_head {
  width: 58%;
  margin: auto;
  padding-top: 9rem;
}

.application-history {
  font-family: inherit;
  padding: 20px 0px 0px 0px;
  background-color: #ffffff;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0px 1px 7px 2px #0000001c;
}

h2 {
  margin-bottom: 20px;
}

.status-filters {
  display: flex;
  gap: 10px;
}

.filter {
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  background-color: #fff;
  color: #333;
}

.Shortlisted_active {
  background-color: #00854a;
  color: #ffffff;
}

.review_active {
  background-color: #f38d00;
  color: #fff;
}

.rejected_active {
  background-color: #ff652d;
  color: #fff;
}

.filter_shortlisted {
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  border: 1px solid #00854a;
  cursor: pointer;
  background-color: #fff;
  color: #00854a;
}

.filter_review {
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  border: 1px solid #f38d00;
  cursor: pointer;
  background-color: #fff;
  color: #f38d00;
}

.filter_rejected {
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  border: 1px solid #ff652d;
  cursor: pointer;
  background-color: #fff;
  color: #ff652d;
}

.applications-list {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* overflow: hidden; */
}

.application-item {
  display: flex;
  align-items: center;
  padding: 16px 0px;
  border-bottom: 1px solid #eee;
  width: 92%;
  justify-content: space-between;
  margin: auto;
}

.application-company-logo {
  width: 49px;
  height: 45px;
  border-radius: 8px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 15px;
  border: 2px solid #acbac7a6;
}

.application-company-logo img {
  width: 30px;
  object-fit: cover;
}

.application-details {
  flex-grow: 1;
}

.application-details h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #111111;
}

.application-details p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #2c2c2c;
  font-weight: 400;
}

.application-date {
  text-align: right;
  margin-right: 15px;
}

.application-date p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}

.application-date p:first-child {
  color: #111111;
  font-weight: 600;
  font-size: 15px;
}

.status {
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  margin-right: 15px;
}

.status.shortlisted {
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  border: 1px solid #00854a;
  cursor: pointer;
  background-color: #fff;
  color: #00854a;
}

.status.offered {
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  border: 1px solid #00854a;
  cursor: pointer;
  background-color: #fff;
  color: #00854a;
}

.status.inreview {
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  border: 1px solid #f38d00;
  cursor: pointer;
  background-color: #fff;
  color: #f38d00;
}

.status.interviewed {
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  border: 1px solid #f38d00;
  cursor: pointer;
  background-color: #fff;
  color: #f38d00;
}

.status.rejected {
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  border: 1px solid #ff652d;
  cursor: pointer;
  background-color: #fff;
  color: #ff652d;
}

.more-options {
  background: none;
  border: none;
  font-size: 39px;
  cursor: pointer;
  color: #2c2c2c;
}

.myApplication_top_header {
  padding: 0px 1.6rem 20px 1.6rem;
  border-bottom: 1px solid #c0c0c07d;
}

.myApplication_top_header b {
  font-size: 20px;
  color: #111111;
  font-weight: 500;
}

.more-options-dropdown {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 2px 1px 8px 1px #0000003d;
  color: black;
  z-index: 33;
  top: 35px;
  left: 21px;
  border-radius: 7px;
}

.student_widthdraw_button {
  color: inherit;
  border: none;
  background: none;
  outline: none;
}

.more-options-dropdown ul {
  padding: 9px 23px;
  margin: 0;
}

.more-options-dropdown ul li {
  list-style: none;
}

.more-options-dropdown ul li a {
  text-decoration: none;
  color: #2c2c2c;
}

.more-options-dropdown ul li a:hover {
  color: #f38d00;
}

.new_letral_interview_details_join_now button {
  font-size: 12px !important;
  padding: 6px 10px 6px 10px !important;
  background-color: #00854a !important;
}

.status_with_zero_width {
  width: auto !important;
  height: auto !important;
}

.application_active_filer_flex_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}

.application_interview_flex_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 71%;
}

.letral-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-left: 14px;
  padding-right: 18px;
}

.letral-custom-dropdown {
  position: relative;
  display: inline-block;
}

.letral-dropdown-btn {
  padding: 8px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  border-radius: 63px;
}

.letral-dropdown-btn .letral-arrow {
  margin-left: 10px;
}

.letral-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
  padding: 0;
  list-style: none;
  max-height: 282px;
  overflow-y: auto;
}

.letral-dropdown-item {
  padding: 10px;
  font-size: 11px;
  cursor: pointer;
  text-align: left;
  transition: all 0.3s ease-in-out;
  border-radius: 4px;
  margin: 5px;
  text-align: center;
  white-space: nowrap;
}

.letral-dropdown-item:hover {
  background-color: #f0f0f0;
}

.letral-dropdown-item.letral-active {
  font-weight: bold;
  color: white !important;
}

.letral-dropdown-item.letral-active.letral-in-review {
  background-color: #f38d00 !important;
  border: 2px solid #f38d00 !important;
  color: white !important;
}

.letral-dropdown-item.letral-active[style*="00854a"] {
  background-color: #00854a !important;
  border: 2px solid #00854a !important;
}

.letral-dropdown-item.letral-active[style*="ffac3a"] {
  background-color: #ffac3a !important;
  border: 2px solid #ffac3a !important;
}

.letral-dropdown-item.letral-active[style*="ff652d"] {
  background-color: #ff652d !important;
  border: 2px solid #ff652d !important;
}

.letral-dropdown-item.letral-active[style*="00a55c"] {
  background-color: #00a55c !important;
  border: 2px solid #00a55c !important;
}

@media (max-width: 1400px) and (min-width: 1100px) {
  .filter {
    font-size: 12px;
  }
}