.job-list {
  flex-wrap: wrap;
  gap: 20px;
}

.job-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 15px;
  margin: 20px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px #00000026; /* Shadow from Figma */
  flex: 1 1 calc(33.333% - 40px); /* Responsive width for 3 columns */
}

.job-left {
  display: flex;
  align-items: center;
}

.company-logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.job-info h6 {
  margin: 0;
  font-size: 16px;
}

.job-info p {
  margin: 0;
  color: #555;
}

.job_right_invitations {
  font-size: 14px;
  color: #f38d00;
  /* border: 1px solid #f38d00; */
  padding: 10px;
  border-radius: 50px;
}

.allbutton_invitations img {
  padding-right: 8px;
}
.allbutton_invitations {
  background-color: white;
  width: 7rem;
  border: none;
  border-radius: 50px;
  padding: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.job-right span {
  color: #f38d00;
  
}
.all-job-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  padding: 10px 15px 0px 20px;
}

.all-job-header h5 {
  margin-bottom: 0;
}

.all-job-header-right {
  gap: 10px;
  top: 6%;
  padding-left: 16px;
  display: flex;
  align-items: center;
}

.all-jobs-search-input-div {
  position: relative;
}

.all-jobs-search-input-div img {
  position: absolute;
  left: 18px;
  top: 14px;
}

.accept_accept_invitations {
  background-color: #f38d00;
  color: #fff;
  padding: 8px;
  width: 7rem;
  border-radius: 50px;
  border: none;
  margin: 15px;
}

.accept_accept_invitations:hover {
  background-color: #f38200;
}

.accept_reject_invitations {
  background-color: white;
  color: #f38d00;
  border: 1px solid #f38d00;
  padding: 8px;
  width: 7rem;
  border-radius: 50px;
  margin: 15px;
}

.accept_reject_invitations:hover {
  background-color: #f38200;
  color: white;
}

.all-job-search-input {
  padding: 7px 7px 7px 20px;
  width: 17rem;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  outline: none;
  height: 2.6rem;
  border-radius: 50px;
  padding-left: 47px;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
