.zoom_meeting_guide {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1rem 2rem;
  border-radius: 2rem;
  background-color: #fff;
}

.submit_credential {
  background-color: #f38d00;
  font-weight: 700;
  padding: 0.4rem 0.5rem;
  border-radius: 24px;
  border: none;
  color: #fff;
}
