.student_campus_myapplication_page {
  width: 100%;
  height: 100%;
  display: grid;
  gap: 20px;
}

.student_campus_Myapplication_parrent {
  padding: 20px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px #00000026;
}

.student_campus_keep_heading {
  width: fit-content;
  height: 24px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #111111;
}

.student_campus_keep_paragraf {
  width: fit-content;
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #333333;
}

.student_campus_reset {
  width: 71px;
  height: 40px;
  padding-left: 15px;
  padding-right: 16px;
  padding-top: 8px;
  /* gap: 10px; */
  border-radius: 10px;
  /* opacity: 0px; */
  background: #f38d00;
  margin-top: 5px;
  cursor: pointer;
  color: white;
}

.student_campus_day_date {
  width: 147px;
  height: 40px;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: center;
  color: #111111;
  margin-top: 5px !important;
  border: 0.6px solid #222222;
}

.student_campus_tab_div {
  border-radius: 16px;
  padding: 20;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px #00000026;
}

.student_campus_button_tab {
  width: 200px;
  height: 32px;
  border: none;
  text-align: left;
  border-right: 0.6px solid #c0c0c0;
  background: #ffffff;
}

.student_campus_button_tab1 {
  width: 242px;
  height: 32px;
  border: none;
  text-align: center;
  border-right: 0.6px solid #c0c0c0;
  background: #ffffff;
}

.student_campus_button_tab5 {
  width: 250px;
  height: 32px;
  top: 146px;
  left: 496px;
  gap: 0px;
  /* opacity: 0px; */
  border: none;
  text-align: center;
  /* padding-left: 143px; */
  background: #ffffff;
}

.student_campus_paragraf_alljobs {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.04px;
  text-align: left;
  color: #111111;
}

.student_campus_paragraf_inreview {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.04px;
  color: #111111;
}

.student_campus_paragraf_Offered {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.04px;
  text-align: center;
  color: #111111;
}

.student_campus_tab-buttons {
  width: 100%;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  top: 292px;
  left: 312px;
  gap: 0px;
  /* opacity: 0px; */
  border-bottom: 0.8px solid #c0c0c0;
}

.activealljob {
  color: #00854a;
}

.activeinreview {
  color: #f38d00;
}

.Shortlisted {
  color: #00854a;
}

.discarded {
  background-color: #ff8c7a;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  padding: 8px 16px;
}

.student_campus_alljobs_heading {
  padding-top: 20px;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.04px;
  text-align: left;
  color: #111111;
}

.student_campus_table_div {
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.student_campus_styled-table {
  border-bottom: 0.6px solid #c0c0c0;
  width: 100%;
  table-layout: fixed;
  height: fit-content;
  /* opacity: 0px; */
  background: #ffffff;
  border-left: 0.6px solid #c0c0c0;
  border-right: 0.6px solid #c0c0c0;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 16px;
}

.student_campus_styled-table tbody tr td {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.32px;
  color: #111111;
  border-bottom: 0.6px solid #c0c0c0;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  position: relative;
}

.student_campus_styled-table tbody tr td img {
  position: relative;
}

.student_campus_styled-table tbody tr {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.student_campus_styled-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 16px;
}

.student_campus_styled-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 16px;
}

.student_campus_styled-table tbody tr td .student_campus_short_list {
  top: 442px;
  left: 1135px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  gap: 10px;
  border-radius: 40px;
  /* opacity: 0px; */
  background: #00854a;
  border: none;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.32px;
  text-align: left;
  color: #ffffff;
}

.student_campus_img_Threedot {
  cursor: pointer;
}

.student_widthdraw_button:hover {
  color: #f38d00;
}

.student_campus_styled-table tbody tr td .In-Review {
  top: 498px;
  left: 1138px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  gap: 10px;
  border-radius: 40px;
  /* opacity: 0px; */
  background: #ffffff;
  border: 1px solid #f38d00;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.32px;
  text-align: left;
  color: #f38d00;
}

.student_campus_styled-table tbody tr td .Declined {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  gap: 10px;
  border-radius: 40px;
  /* opacity: 0px; */
  border: 1px solid #ff652d;
  background: #ffffff;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.32px;
  text-align: left;
  color: #ff652d;
}

.student_campus_styled-table thead tr {
  width: 100%;
  height: 56px;
  background-color: #011e39;
  color: #ffffff;
}

.student_campus_styled-table thead tr th:first-child {
  border-top-left-radius: 10px;
}

.student_campus_styled-table thead tr th:last-child {
  border-top-right-radius: 10px;
}

.student_campus_styled-table thead tr th {
  width: 23px;
  height: 19px;
  top: 391px;
  left: 360px;
  gap: 0px;
  /* opacity: 0px; */
  font-size: 14px;
  font-weight: 600;
  line-height: 19.04px;
  text-align: center;
  color: #ffffff;
}

.student_campus_popup_box {
  width: 96px;
  height: 50px;
  position: absolute;
  z-index: 1;
  margin-left: 25px;
  margin-top: 5px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px #00000040;
}

.student_campus_popup_withdraw {
  width: 24px;
  height: 14px;
  font-size: 10px;
  font-weight: 400;
  line-height: 13.6px;
  padding-left: 28px;
  padding-top: 10px;
  cursor: pointer;
  color: #000000;
}

.student_campus_popup_view {
  width: 24px;
  height: 14px;
  padding-left: 35px;
  padding-top: 10px;
  font-size: 10px;
  font-weight: 400;
  line-height: 18.6px;
  color: #000000;
}

.student_campus_job_div {
  padding-top: 20px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  gap: 12px;
  /* opacity: 0px; */
}

.student_campus_job_div .student_campus_job_content {
  width: 90px;
  height: 24px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

.student_campus_job_div .student_campus_img_rrow {
  width: 13.2px;
  height: 10px;
  gap: 0px;
  /* opacity: 0px; */
  color: #222222;
}

.student_campus_view_detailes {
  width: 97%;
  height: 96px;
  margin-top: 10px !important;
  padding-top: 20px;
  padding-left: 10px;
  margin-left: 30px;
  gap: 0px;
  border-radius: 16px;
  /* opacity: 0px; */
  background: #ffffff;
  box-shadow: 0px 4px 6px 0px #00000026;
}

.student_campus_view_detailes .student_campus_view_job {
  top: 178px;
  display: flex;
  gap: 12px;
  /* opacity: 0px; */
}

.student_campus_Frontend_developer {
  gap: 0px;
  /* opacity: 0px; */
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #111111;
}

.student_campus_job_companyname {
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
}

.student_campus_job_logo {
  margin-right: 15px;
  color: red;
  width: 40px;
  height: 40px;
  border: 2px solid gray;
  border-radius: 5px;
  padding: 5px;
  opacity: 0.8px;
  object-fit: contain;
}

.student_campus_date_grupe {
  width: Fixed (85px) px;
  height: Hug (41px) px;
  /* opacity: 0px; */
}

.student_campus_post_date {
  width: 85px;
  height: 21px;
  gap: 0px;
  /* opacity: 0px; */
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.student_campus_date {
  width: 85px;
  height: 20px;
  gap: 0px;
  /* opacity: 0px; */
  font-size: 14px;
  font-weight: 400;
  line-height: 19.5px;
}

.student_campus_flex_content {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
}

.student_campus_job_description {
  width: 97%;
  height: 543px;
  margin-top: 0 !important;
  margin-left: 30px;
  padding: 40px;
  border-radius: 16px;
  /* opacity: 0px; */
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px #00000026;
}

.student_campus_job_description .student_campus_job_section {
  width: Hug (778px) px;
  gap: 24px;
  /* opacity: 0px; */
}

.student_campus_job_description .student_campus_job_section .student_campus_job_contentgrup {
  width: Fill (778px) px;
  gap: 26px;
  /* opacity: 0px; */
  line-height: 19.04px;
}

.student_campus_job_description .student_campus_job_section .student_campus_job_contentgrup .student_campus_heading_description {
  width: 778px;
  height: 19px;
  gap: 0px;
  /* opacity: 0px; */
  font-size: 14px;
  font-weight: 600;
  line-height: 19.04px;
  text-align: left;
}

.student_campus_job_description .student_campus_job_section .student_campus_job_contentgrup .student_campus_pragraf_content {
  width: 978px;
  height: 16px;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 16.32px;
  text-align: left;
  color: #3c3c3c;
  padding-top: 20px;
}

.student_campus_list_grup {
  gap: 0px;
  /* opacity: 0px; */
  margin-top: 40px;
}

.student_campus_list_grup li {
  font-size: 14px;
  font-weight: 400;
  line-height: 26.64px;
  text-align: left;
  color: #3c3c3c;
}

.student_campus_roll_profile {
  width: Fixed (357px) px;
  gap: 12px;
  /* opacity: 0px; */
}

.student_campus_roll_profile .student_campus_roll_paragraf {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.32px;
  text-align: left;
  color: #3c3c3c;
  line-height: 30.32px !important;
}

.student_campus_roll_profile .student_campus_roll_paragraf .student_campus_roll_span {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.32px;
  text-align: left;
  color: #3c3c3c;
}

.student_campus_about_company {
  width: 97%;
  height: 200px;
  margin-top: 0 !important;
  margin-left: 30px;
  padding: 40px;
  border-radius: 16px;
  /* opacity: 0px; */
  background: #ffffff;
  box-shadow: 0px 4px 6px 0px #00000026;
}

.student_campus_about_company .student_campus_about_heading {
  font-size: 14px;
  font-weight: 600;
  line-height: 19.04px;
  text-align: left;
  color: #222222;
}

.student_campus_about_company .student_campus_about_paragraf {
  width: 1042px;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.32px;
  text-align: left;
  color: #3c3c3c;
}

.student_campus_social_media {
  display: flex;
  gap: 25px;
  /* opacity: 0px; */
  padding-top: 40px;
}

.social_media .student_campus_img_social_media {
  width: 34px;
  height: 34px;
}

.social_media .student_campus_img_social_Mditwitter {
  width: 34px;
  height: 34px;
  background: #f38d00;
  padding: 5px;
  border-radius: 100px;
}

.social_media .student_campus_img_social_Indeed {
  width: 34px;
  height: 34px;
  background: #f38d00;
  padding: 8px;
  border-radius: 100px;
}