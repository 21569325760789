:root {
  --color-primary: #f38d00;
  --color-primary-hover: #0056b3;
  --color-success: #28a745;
  --color-success-hover: #218838;
  --color-danger: #dc3545;
  --color-danger-hover: #c82333;
  --color-blue: #007bff;
  --color-text-primary: #333;
  --color-text-secondary: #555;
  --color-text-tertiary: #666;
  --color-border: #ddd;
  --color-background: #f8f9fa;
  --color-background-modal: rgba(0, 0, 0, 0.5);
  --color-background-light: #f0f0f0;
  --color-background-light-hover: #e0e0e0;
}

.assessment_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-background-modal);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.assessment_modal_content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
}

.assessment_modal_content h3 {
  margin-bottom: 1.5rem;
  color: var(--color-text-primary);
  font-size: 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--color-text-secondary);
  font-weight: 500;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  font-size: 1rem;
}

.form-group textarea {
  min-height: 100px;
  resize: vertical;
}

.options-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.cancel-btn,
.submit-btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cancel-btn {
  background: var(--color-background-light);
  color: var(--color-text-tertiary);
}

.submit-btn {
  background: var(--color-primary);
  color: white;
}

.cancel-btn:hover {
  background: var(--color-background-light-hover);
}

.submit-btn:hover {
  background: var(--color-primary-hover);
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.question-actions {
  display: flex;
  gap: 0.5rem;
}

.edit-btn,
.delete-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.3s ease;
}

.edit-btn {
  background: var(--color-success);
  color: white;
}

.delete-btn {
  background: var(--color-danger);
  color: white;
}

.edit-btn:hover {
  background: var(--color-success-hover);
}

.delete-btn:hover {
  background: var(--color-danger-hover);
}

.assessment_question_sub_container {
  background: var(--color-background);
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-question-btn {
  background: var(--color-blue);
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.add-question-btn:hover {
  background: var(--color-primary-hover);
}
