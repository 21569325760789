.hr-stat-container {
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  margin-left: 0 !important;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-bottom: 30px;
  gap: 0.5rem;
}

.hr-stat-card {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  width: auto;
  background-color: white;
}

._main_content_hr {
  display: flex;
  gap: 20px;
  width: 100%;
  margin: auto;
  flex-direction: column;
  min-height: 100vh;
  max-height: auto;
}

._recent_jobs_header {
  padding-top: 0.8rem;
  padding-left: 2rem;
  font-size: 14px;
  font-weight: 500;
}

.recent-job-header th {
  padding: 0.5rem !important;
  background-color: #f5f5f5;
  font-weight: 500 !important;
  border: none;
  font-size: 14px;
  font-family: "Poppins" sans-serif;
}

.recent-job-body td {
  padding: 0.6rem !important;
  font-size: 13px;
  font-family: "Poppins" sans-serif;
  cursor: pointer;
}

.recent_job_view_all {
  width: 100%;
  text-align: center;
  font-size: 15px;
}

/* campus bar chart css */

.campus_main_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1.2rem 1.2rem 1.2rem;
}

.campus_bar_char {
  width: 100%;
  height: 100%;
}

.campus_bar_char_container {
  width: 75%;
  margin-left: 25%;
}

.campus_bar_char_chart_counter {
  display: flex;
  justify-content: space-between;
  list-style: none;
  align-items: start;
}

.campus_bar_char_clg_container {
  display: flex;
  height: 100%;
}

.campus_bar_char_clg_container_sub {
  display: flex;
  width: 25%;
  flex-direction: column;
  justify-content: space-between;
}

.hr_home_first_section {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

._chart_section,
._chart_section {
  width: 70%;
  min-height: 420px;
}

.ht_home_received_resume {
  width: 30%;
  max-height: 420px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.ht_home_received_resume_and_upcoming_drives {
  max-height: auto;
  width: 35%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ht_home_received_resume h6,
.hr_dashboard_upcoming_drives h6 {
  padding: 1.2rem 0.8rem 0rem 2rem;
  font-size: 14px;
  /* background-color: aquamarine; */
}

.received_resume {
  position: absolute;
  top: 15%;
  left: 75.5%;
}

.received_resume_most_job_display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.received_resume_most_job_display_container {
  width: 75%;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins" sans-serif;
  display: flex;
  justify-content: start;
}

/* hr dashboard received resume section end  */

/* hr dashboard table  */
.hr_dashboard_second_container {
  display: flex;
  padding-bottom: 2rem;
  justify-content: center;
  gap: 20px;
}

.hr_dashboard_recent_jobs_table {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 70%;
  min-height: 425px;
}

/* upcoming drive  start*/
.hr_dashboard_upcoming_drives {
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 30%;
  background-color: #ffffff;
  min-height: 425px;
}

.hr_dashboard_upcoming_drives_container {
  padding: 0 0.8rem 0.8rem 1rem;
  width: 100%;
}

.hr_dashboard_upcoming_drives_sub_container {
  border-radius: 15px;
  width: 100%;
  padding: 0.5rem;
}

.hr_dashboard_upcoming_drives_card {
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.hr_dashboard_upcoming_drives_date_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  padding: 2rem;
  flex-direction: column;
  font-size: 12px;
  height: 50px;
  font-weight: 500;
  width: 50px;
}

.id_hr_dashboard_upcoming_drives_date_container {
  border-radius: 10px 10px 0px 0px;
}

.last_id_hr_dashboard_upcoming_drives_date_container {
  border-radius: 0px 0px 10px 10px;
}

.hr_dashboard_upcoming_drives_clg_details {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hr_dashboard_upcoming_drives_clg_img_container {
  height: 35px;
  width: 35px;
  border: 1px solid #bcbbbb;
  border-radius: 5px;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.hr_dashboard_upcoming_drives_clg_img_container img {
  height: 28px;
  width: 28px;
  object-fit: cover;
  border-radius: 5px;
}

.hr_dashboard_upcoming_drives_text_container {
  font-size: 12px;
  width: 150px;
  font-weight: 500;
}

.hr_dashboard_upcoming_drives_text_container p {
  width: 100%;
  text-align: left;
  padding-left: 1rem;
}

.upcoming_drive_clg_title {
  font-size: 15px;
}

#chart {
  margin: auto;
}

.char_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* end */

@media (max-width: 1650px) and (min-width: 1250px) {
}
