.nav_bar_main {
  position: fixed;
  z-index: 999;
  width: 100%;
  background: #ffffff;
  top: 0;
  border-bottom: 1px solid #00000014;
  font-family: "Poppins", sans-serif;
}

.imagetext {
  display: none;
}

.nav_container {
  width: 63%;
  margin: auto;
}

.nav_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent;
  padding-top: 16px;
  padding-bottom: 16px;
}

.nav_logo img {
  width: 206px;
  height: 36px;
}

p {
  margin: 0;
  padding: 0;
}

.nav_tabs {
  height: 3rem;
}

.nav_tabs ul {
  margin: 0;
  display: flex;
  padding: 0;
  height: inherit;
}

.nav_tabs ul li {
  padding: 0px 20px;
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1c1c1c;
  font-variant: proportional-nums;
}

#nav_soluction {
  border-bottom: none;
}

.nav_tabs ul li p {
  cursor: pointer;
  color: #1c1c1c;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.3s ease-in-out;
  white-space: nowrap;
}

.nav_tabs ul li p a {
  text-decoration: none;
  color: inherit;
}

.nav_tabs ul li p:hover {
  border-bottom: 2px solid #f38d00;
}

.nav_tabs ul li span {
  padding-left: 4px;
  display: flex;
  align-items: baseline;
}

.feature {
  position: relative;
  /* Ensure relative positioning for the dropdown */
}

#nav_solution p {
  border-bottom: none !important;
}

.feature .nav_drop_down {
  display: none;
  position: absolute;
  top: calc(100% + 0px);
  left: 0;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 5px 11px 5px 12px;
  z-index: 0;
  width: fit-content;
  border-radius: 3px 3px 6px 6px;
  line-height: 25px;
  border-top: 2px solid #f38d00;
  opacity: 1;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.feature:hover .nav_drop_down {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

.nav_drop_down p:hover {
  color: #f38d00;
  border-bottom: none !important;
}

.nav_drop_down p {
  border-bottom: none !important;
  font-size: 14px;
}

.nav_login button {
  padding: 5px 20px 5px 20px;
  margin: 0px 5px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: white;
  color: #1c1c1c;
}

.nav_login button:hover {
  border: 1px solid #f38d00;
  color: #f38d00;
}

.nav_tabs select {
  border: 0px solid transparent;
  padding: 0px 5px;
  border: none;
}

.nav_tabs select option {
  border: none;
  padding: 10px 10px;
}

.nav_login #btn {
  padding: 7px 25px;
  background-color: #f38d00;
  border: 1px solid transparent;
  border-radius: 8px;
  color: white;
}

.nav_login #btn:hover {
  background-color: #dd850c;
}

/* large screen-size start menu bar styling */
.menu_icon,
.sidebar {
  display: none;
}

.react-tel-input .selected-flag {
  background: white !important;
}

.nav-active-tabs {
  border-bottom: 2px solid #f38d00 !important;
}

.dropDown-active-tabs {
  color: #f38d00 !important;
}

.navactivemobile {
  color: #f38d00 !important;
}

@media (max-width: 1650px) and (min-width: 1450px) {
  .nav_container {
    width: 85%;
  }

  .menu_icon {
    display: none;
  }

  .calendly-inline-widget {
    width: 69%;
  }
}

@media (max-width: 1450px) and (min-width: 1200px) {
  .nav_container {
    width: 85%;
  }

  .menu_icon {
    display: none;
  }

  .calendly-inline-widget {
    width: 69% !important;
  }
}

/* sidebar - menu style */
@media (max-width: 1200px) and (min-width: 150px) {
  .nav_container {
    width: 85%;
  }

  .imagetextdiv {
    padding-bottom: 2rem;
  }

  .imagetext {
    display: block;
    width: 140px;
    height: 36px;
  }

  .menu_icon {
    display: block;
    height: 35px;
    width: 35px;
    cursor: pointer;
  }

  .nav_tabs,
  .nav_login {
    display: none;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    height: 100%;
    background-color: #011e39;
    color: white;
    padding: 20px;
    z-index: 9999 !important;
    transform: translateX(-100%);
    transition: transform 0.5s ease-in-out;
    display: block;
  }

  .sidebar.open {
    transform: translateX(-1%);
  }

  .sidebar ul {
    list-style: none;
    padding: 0 18px;
    padding-top: 1rem;
  }

  .sidebar ul li {
    margin-bottom: 20px;
  }

  .sidebar ul li p {
    margin: 0;
  }

  .feature_sub_menu p a {
    color: #a89999;
    transition: 0.3s;
    font-size: 14px;
  }

  .feature_sub_menu p a:hover {
    color: #f0e5e5 !important;
  }

  .sidebar ul li p a {
    text-decoration: none;
    color: white;
  }

  .feature #sidebar_solution {
    display: flex;
    width: 100%;
  }

  .feature #sidebar_solution p {
    transition: 0.4s;
  }

  .feature #sidebar_solution p:hover,
  .sidebar ul li p a:hover {
    color: #f38d00;
  }

  .menu_login_btn {
    background-color: #fff;
  }

  .menu_sign_up_btn {
    background-color: #f38d00;
  }

  .menu_sign_up_btn:hover {
    border: 1px solid #f38d00;
  }

  .down-arrow img {
    height: 15px;
    width: 15px;
  }

  .menu_logo_main_container {
    display: flex;
    gap: 25px;
  }

  .calendly-inline-widget {
    width: 70% !important;
  }
}

@media (max-width: 500px) and (min-width: 150px) {
  .name {
    display: none;
  }

  .nav_logo img {
    display: none;
  }

  .menu_logo_main_container {
    gap: 0px;
  }

  .imagetextdiv {
    padding-bottom: 2rem;
  }

  .imagetext {
    display: block;
    width: 140px;
    height: 36px;
  }

  .nav_container {
    width: 95%;
  }
}

/* close Navbar menu style */

/* ################################################################### */

/* hero section start  */

.hero_container {
  width: 63%;
  margin: auto;
}

.hero_main {
  padding-top: 50px;
  background: radial-gradient(46.51% 46.51% at 0% 50%,
      #e6e5e5 0%,
      #f2f2f2 100%);
  font-family: "Poppins", sans-serif;
  height: 645px;
}

.hero_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 0px 0px 0px;
}

.hero_content {
  margin-top: 7rem;
}

.student_hero_content {
  margin-top: 1rem;
}

.hero_content h1 {
  font-weight: 600;
  color: #111111;
  font-size: 58px;
}

.hero_content span {
  color: #f38d00;
}

.hero_content p {
  color: #222222;
  font-size: 16px;
  padding-top: 8px;
  font-weight: 500;
  display: grid;
}

.hero_content p span {
  color: #222222;
}

.hero_content_btn button {
  padding: 10px 30px;
  background-color: #f38d00;
  color: #ffffff;
  border: 1px solid transparent;
  border-radius: 10px;
  margin-top: 40px;
  font-weight: 600;
}

.hero_content_btn button:hover {
  background-color: #dd850c;
}

.hero_content h6 {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 12px;
}

.hero_content_title {
  display: grid;
}

.hero_content_title span {
  color: #111111;
}

.hero_content .hero_content_find_your_dream {
  color: #111111;
}

.hero_content_start_naukari {
  color: #f38d00 !important;
}

/* naukri section start  */

.naukri_image {
  margin-top: 25px;
}

.naukri_image img {
  width: 410px;
}

.think-about-img-new {
  width: 90px !important;
  height: 90px !important;
  border-radius: 50% !important;
  object-fit: cover !important;
}

/* naukri section end  */

/* media query start responsive design */

@media (max-width: 1650px) and (min-width: 1450px) {
  .hero_container {
    width: 85%;
    margin: auto;
  }
}

@media (max-width: 1450px) and (min-width: 1200px) {
  .hero_container {
    width: 85%;
    margin: auto;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .hero_container {
    width: 85%;
    margin: auto;
  }

  .hero_main {
    padding-top: 15px;
    height: 600px;
  }

  .hero_content p {
    color: #222222;
    font-size: 14px;
    padding-top: 8px;
    font-weight: 500;
    display: grid;
  }

  .hero_content h6 {
    font-size: 18px;
  }

  .hero_content_title span {
    color: #111111;
  }

  .naukri_image {
    margin-top: 70px;
  }

  .naukri_image img {
    width: 320px;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .hero_container {
    width: 85%;
    margin: auto;
  }

  .hero_main {
    padding: 15px 0px 25px 0px;
    height: auto;
  }

  .hero_row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .hero_content_btn {
    text-align: center;
  }

  .hero_content h1 {
    font-weight: 600;
    color: #111111;
    font-size: 54px;
    text-align: center;
  }

  .hero_content p {
    color: #222222;
    font-size: 14px;
    padding-top: 8px;
    font-weight: 500;
    display: grid;
    text-align: center;
  }

  .hero_content h6 {
    font-size: 18px;
    text-align: center;
  }

  .hero_content_title span {
    color: #111111;
  }

  .naukri_image {
    margin-top: 30px;
    /* display: none; */
  }

  .naukri_image img {
    width: 300px;
    margin-left: -30px;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .hero_container {
    width: 85%;
    margin: auto;
  }

  .hero_main {
    padding: 10px 0px 25px 0px;
    height: auto;
  }

  .hero_row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .hero_content {
    margin-top: 5rem;
  }

  .hero_content h1 {
    font-weight: 600;
    color: #111111;
    text-align: center;
    font-size: 36px;
  }

  .hero_content p {
    color: #222222;
    font-size: 14px;
    padding-top: 8px;
    text-align: center;
    font-weight: 500;
    display: grid;
  }

  .hero_content h6 {
    font-size: 18px;
    text-align: center;
  }

  .hero_content_btn {
    text-align: center;
  }

  .hero_content_btn button {
    padding: 8px 24px;
    margin-top: 25px;
    font-weight: 600;
  }

  .hero_content_title span {
    color: #111111;
  }

  .naukri_image {
    margin-top: 30px;
    /* display: none; */
  }

  .naukri_image img {
    width: 300px;
    margin-left: -40px;
  }
}

@media (max-width: 576px) and (min-width: 150px) {
  .hero_container {
    width: 85%;
    margin: auto;
  }

  .hero_main {
    padding: 40px 0px 25px 0px;
    height: auto;
  }

  .hero_row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .hero_content {
    margin-top: 4rem;
  }

  .hero_content h1 {
    font-weight: 600;
    color: #111111;
    text-align: center;
    font-size: 24px;
  }

  .hero_content p {
    color: #222222;
    font-size: 12px;
    padding-top: 8px;
    text-align: center;
    font-weight: 500;
    display: grid;
  }

  .hero_content h6 {
    font-size: 14px;
    text-align: center;
  }

  .hero_content_btn {
    text-align: center;
  }

  .hero_content_btn button {
    padding: 8px 24px;
    margin-top: 25px;
    font-weight: 600;
  }

  .hero_content_title span {
    color: #111111;
  }

  .naukri_image {
    margin-top: 30px;
    text-align: center;
  }

  .naukri_image img {
    width: 250px;
    margin-left: -30px;
  }
}

/* hero section end  */

/* counter section start  */

.counter_main {
  background-color: #011e39;
  font-family: "Poppins", sans-serif;
  height: 166px;
}

.counter_container {
  width: 63%;
  margin: auto;
}

.counter_value {
  padding: 38px 5%;
  display: flex;
  justify-content: space-between;
}

.counter_value h6 {
  color: #ffffff;
  font-size: 19px;
  font-weight: 300;
}

.counter_value span {
  color: #ffffff;
  font-size: 40px;
  font-weight: bold;
}

/* responsive design here  */
@media (max-width: 1650px) and (min-width: 1450px) {
  .counter_container {
    width: 85%;
    margin: auto;
  }
}

@media (max-width: 1450px) and (min-width: 992px) {
  .counter_container {
    width: 85%;
    margin: auto;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .counter_container {
    width: 85%;
    margin: auto;
  }

  .counter_main {
    background-color: #011e39;
    font-family: "Poppins", sans-serif;
    height: 100px;
  }

  .counter_value {
    padding: 15px 5%;
    display: flex;
    justify-content: space-between;
  }

  .counter_value h6 {
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
  }

  .counter_value span {
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .counter_container {
    width: 85%;
    margin: auto;
  }

  .counter_main {
    background-color: #011e39;
    font-family: "Poppins", sans-serif;
    height: 150px;
  }

  .counter_value {
    padding: 15px 5%;
    display: flex;
    justify-content: space-between;
  }

  .counter_value h6 {
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
  }

  .counter_value span {
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
  }
}

@media (max-width: 576px) and (min-width: 150px) {
  .counter_container {
    width: 95%;
    margin: auto;
  }

  .counter_main {
    background-color: #011e39;
    font-family: "Poppins", sans-serif;
    height: 160px;
  }

  .counter_value {
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
  }

  .counter_value h6 {
    font-size: 13px;
    font-weight: 300;
    text-align: center;
  }

  .counter_value span {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
  }
}

/* responsive design end  */
/* counter section end  */

/* profile section start */

.profile_main {
  font-family: "Poppins", sans-serif;
  padding-top: 172px;
  padding-bottom: 172px;
}

.profile_container {
  width: 63%;
  margin: auto;
}

.profile_start {
  display: flex;
  justify-content: space-between;
  border: 2px solid #dde3ea;
  padding: 40px 40px;
  border-radius: 30px;
  gap: 4.1rem;
}

.profile_image {
  display: flex;
  align-items: baseline;
}

.profile_content {
  padding-top: 10px;
}

.profile_content h3 {
  font-size: 47px;
  font-weight: 600;
}

.profile_content p {
  padding-top: 20px;
  font-weight: 500;
  font-size: 17px;
}

.profile_btn button {
  margin-top: 2rem;
  padding: 10px 40px;
  background-color: #f38d00;
  color: #ffffff;
  border: 1px solid transparent;
  border-radius: 10px;
  font-weight: 600;
}

.profile_btn button:hover {
  background-color: #dd850c;
}

/* responsive design here  */
@media (max-width: 1650px) and (min-width: 1450px) {
  .profile_main {
    font-family: "Poppins", sans-serif;
    padding-top: 172px;
    padding-bottom: 172px;
  }

  .profile_container {
    width: 80%;
    margin: auto;
  }
}

@media (max-width: 1450px) and (min-width: 1200px) {
  .profile_main {
    font-family: "Poppins", sans-serif;
    padding-top: 132px;
    padding-bottom: 132px;
  }

  .profile_container {
    width: 85%;
  }

  .profile_content {
    padding-top: 10px;
  }

  .profile_content h3 {
    font-size: 42px;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .profile_container {
    width: 85%;
  }

  .profile_main {
    font-family: "Poppins", sans-serif;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .profile_start {
    display: flex;
    justify-content: space-between;
    border: 2px solid #dde3ea;
    padding: 30px 30px;
    border-radius: 24px;
    gap: 2.5rem;
  }

  .profile_image {
    display: flex;
    align-items: baseline;
  }

  .profile_image img {
    width: 370px;
  }

  .profile_content {
    padding-top: 10px;
  }

  .profile_content h3 {
    font-size: 32px;
  }

  .profile_content p {
    padding-top: 8px;
    font-weight: 500;
    font-size: 14px;
  }

  .profile_btn button {
    margin-top: 1rem;
    padding: 8px 36px;
    background-color: #f38d00;
    color: #ffffff;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 600;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .profile_container {
    width: 80%;
  }

  .profile_main {
    font-family: "Poppins", sans-serif;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .profile_start {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .profile_image {
    display: flex;
    align-items: baseline;
  }

  .profile_content {
    padding: 0px 10%;
    margin-top: -25px;
  }

  .profile_content h3 {
    font-size: 38px;
    text-align: center;
  }

  .profile_content p {
    padding-top: 8px;
    font-size: 15px;
    text-align: center;
  }

  .profile_btn {
    text-align: center;
  }

  .profile_btn button {
    margin-top: 1rem;
    padding: 8px 36px;
    border-radius: 8px;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .profile_container {
    width: 85%;
  }

  .profile_main {
    font-family: "Poppins", sans-serif;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .profile_start {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .profile_image {
    display: flex;
    align-items: baseline;
  }

  .profile_image img {
    height: 250px;
  }

  .profile_content {
    padding: 0px 10%;
    margin-top: -25px;
    text-align: center;
  }

  .profile_content h3 {
    font-size: 36px;
    text-align: center;
  }

  .profile_content p {
    padding-top: 8px;
    font-size: 14px;
    text-align: center;
  }

  .profile_btn {
    text-align: center;
  }

  .profile_btn button {
    margin-top: 1rem;
    padding: 8px 36px;
    border-radius: 8px;
  }
}

@media (max-width: 576px) and (min-width: 520px) {
  .profile_container {
    width: 85%;
  }

  .profile_main {
    font-family: "Poppins", sans-serif;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .profile_start {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px 25px;
  }

  .profile_image {
    display: flex;
    align-items: baseline;
  }

  .profile_image img {
    height: 250px;
  }

  .profile_content {
    padding: 0px 0%;
    margin-top: -40px;
  }

  .profile_content h3 {
    font-size: 28px;
    text-align: center;
  }

  .profile_content p {
    padding-top: 4px;
    font-size: 12px;
    text-align: center;
  }

  .profile_btn {
    text-align: center;
  }

  .profile_btn button {
    margin-top: 1rem;
    padding: 6px 24px;
    border-radius: 6px;
  }
}

@media (max-width: 522px) and (min-width: 400px) {
  .profile_container {
    width: 85%;
  }

  .profile_main {
    font-family: "Poppins", sans-serif;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .profile_start {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px 25px;
  }

  .profile_image {
    display: flex;
    align-items: baseline;
  }

  .profile_image img {
    height: 195px;
  }

  .profile_content {
    padding: 0px 10%;
    margin-top: -40px;
  }

  .profile_content h3 {
    font-size: 24px;
    text-align: center;
  }

  .profile_content p {
    padding-top: 4px;
    font-size: 12px;
    text-align: center;
  }

  .profile_btn {
    text-align: center;
  }

  .profile_btn button {
    margin-top: 1rem;
    padding: 6px 24px;
    border-radius: 6px;
  }
}

@media (max-width: 400px) and (min-width: 150px) {
  .profile_container {
    width: 85%;
  }

  .profile_main {
    font-family: "Poppins", sans-serif;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .profile_start {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px 25px;
  }

  .profile_image {
    display: flex;
    align-items: baseline;
  }

  .profile_image img {
    height: 145px;
  }

  .profile_content {
    padding: 0px 0%;
    margin-top: -40px;
  }

  .profile_content h3 {
    font-size: 22px;
    text-align: center;
  }

  .profile_content p {
    padding-top: 4px;
    font-size: 12px;
    text-align: center;
  }

  .profile_btn {
    text-align: center;
  }

  .profile_btn button {
    margin-top: 1rem;
    padding: 6px 24px;
    border-radius: 6px;
  }
}

/* profile section end  */

/* register section start  */

.register_container {
  width: 63%;
  margin: auto;
}

.register_start {
  padding: 50px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  gap: 3rem;
}

.register_resume {
  position: relative;
  cursor: pointer;
}

.register_resume .hr {
  width: 100%;
  height: 3px;
  background-color: #dde3ea;
  margin-bottom: 1.5rem;
  position: relative;
  overflow: hidden;
}

.register_resume .hr::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #f38d00;
  transition: width 0.5s ease-in-out;
}

.register_resume:hover .hr::after {
  width: 100%;
}

.register_resume span {
  font-weight: 500;
  font-size: 1rem;
  color: #222222;
}

.register_resume h5 {
  font-size: 17px;
  font-weight: 600;
  padding-top: 12px;
}

.register_resume p {
  font-weight: 400;
  font-size: 14px;
  padding-top: 5px;
}

.calendly-inline-widget-main {
  position: fixed;
  background-color: #1c1b1bb0;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
}

.calendly-inline-widget {
  position: absolute;
  width: 55%;
  margin: auto;
  height: 90vh;
  top: 9%;
  overflow: hidden !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.calendly-inline-widget::-webkit-scrollbar {
  display: none !important;
}

.calande_popup_close_button {
  position: absolute !important;
  top: 3rem !important;
  left: 49%;
  z-index: 10000 !important;
  background: #f74040 !important;
  padding: 0 10px 0 0px !important;
  margin: 0 !important;
}

/* media query */

@media (max-width: 1650px) and (min-width: 280px) {
  .register_container {
    width: 85%;
    margin: auto;
  }
}

/* register section end  */

/* .employee section start */

.employee_main {
  background-image: url("../../utils/landingpage/images/Employers.svg");
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Poppins", sans-serif;
}

.employee_container {
  background: #011e39d9;
  width: 100%;
  height: auto;
  padding: 3%;
  display: flex;
  justify-content: center;
}

.employee_row {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.employee_content h6 {
  font-weight: 500;
  font-size: 37px;
  align-items: center;
  color: #ffffff;
}

.employee_content p {
  font-weight: 400;
  font-size: 16px;
  padding-top: 13px;
  color: #ffffff;
}

.employee_company {
  display: flex;
  width: 55%;
  float: right;
  padding: 2%;
  justify-content: space-evenly;
  align-items: center;
}

.employee_inner {
  width: 60%;
  padding: auto;
}

.employee_inner div {
  width: 90%;
  background-color: #ffffff;
  border-radius: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 215px;
  width: 200px;
}

.employee_content_title {
  text-align: center;
  padding: 10px;
}

.employee_content_button {
  padding: 8px 18px;
  border-radius: 8px;
  border: none;
  color: #ffffff;
  background: #011e39;
  font-weight: 600;
}

.employee_content_button:hover {
  padding: 8px 18px;
  opacity: 0.9;
  border-radius: 8px;
  border: none;
  color: #ffffff;
  background: #011e39;
  font-weight: 600;
}

.employee_company .slider-container {
  background-color: transparent;
  width: 100%;
  height: 250px;
}

.employee_company .slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  border-radius: 10px;
}

.slick-dots {
  bottom: -45px !important;
}

.slick-dots li {
  width: 13px !important;
}

.employee_company .slick-dots li button:before {
  font-size: 8px;
  color: #ffff !important;
}

.employee_company .slick-dots li.slick-active button:before {
  font-size: 8px;
  color: #ffff !important;
}

@media (max-width: 1750px) and (min-width: 1450px) {
  .employee_row {
    width: 85%;
  }

  .employee_company {
    display: flex;
    width: 60%;
    float: right;
    padding: 2%;
    justify-content: space-evenly;
    align-items: center;
  }
}

@media (max-width: 1450px) and (min-width: 1200px) {
  .employee_row {
    width: 95%;
  }

  .employee_company {
    display: flex;
    width: 64%;
    float: right;
    padding-top: 2%;
    justify-content: space-evenly;
    align-items: center;
  }

  .employee_content h6 {
    font-weight: 500;
    font-size: 30px;
    padding-left: 3%;
    color: #ffffff;
  }

  .employee_content p {
    font-weight: 400;
    font-size: 14px;
    padding-left: 3%;
    padding-top: 13px;
    color: #ffffff;
  }
}

@media (max-width: 1200px) and (min-width: 1100px) {
  .employee_row {
    width: 95%;
  }

  .employee_company {
    display: flex;
    width: 55%;
    float: right;
    padding: 2%;
    justify-content: space-evenly;
    align-items: center;
  }

  .employee_content h6 {
    font-size: 30px;
  }

  .employee_inner {
    width: 50%;
    padding: auto;
  }

  .employee_inner div {
    height: 185px;
    width: 155px;
    border-radius: 4%;
  }

  .employee_content_title {
    text-align: center;
    padding: 5px;
    font-size: 13px;
    font-weight: 500;
  }

  .employee_content_button {
    padding: 4px 14px;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    background: #011e39;
    font-weight: 500;
    font-size: 12px;
  }

  .employee_content_button:hover {
    padding: 8px 18px;
    opacity: 0.9;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    background: #011e39;
    font-weight: 400;
  }

  .employee_company .slider-container {
    background-color: transparent;
    width: 100%;
    height: 200px;
  }
}

@media (max-width: 1100px) and (min-width: 992px) {
  .employee_row {
    width: 95%;
  }

  .employee_company {
    display: flex;
    width: 43%;
    float: right;
    padding: 2%;
    justify-content: space-evenly;
    align-items: center;
  }

  .employee_content h6 {
    font-size: 38px;
  }

  .employee_inner {
    width: 50%;
    padding: auto;
  }

  .employee_inner div {
    height: 185px;
    width: 155px;
    border-radius: 4%;
  }

  .employee_content_title {
    text-align: center;
    padding: 5px;
    font-size: 13px;
    font-weight: 500;
  }

  .employee_content_button {
    padding: 6px 14px;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    background: #011e39;
    font-weight: 500;
    font-size: 12px;
  }

  .employee_content_button:hover {
    padding: 6px 14px;
    opacity: 0.9;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    background: #011e39;
    font-weight: 400;
  }

  .employee_company .slider-container {
    background-color: transparent;
    width: 100%;
    height: 200px;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .employee_row {
    width: 95%;
  }

  .calande_popup_close_button {
    top: 2rem !important;
    left: 46% !important;
  }

  .employee_company {
    display: flex;
    width: 55%;
    float: right;
    padding: 2%;
    justify-content: space-evenly;
    align-items: center;
  }

  .employee_inner {
    width: 50%;
    padding: auto;
  }

  .employee_company .slick-dots {
    margin-left: -8%;
  }

  .employee_inner div {
    height: 185px;
    width: 155px;
    border-radius: 4%;
  }

  .employee_content_title {
    text-align: center;
    padding: 5px;
    font-size: 13px;
    font-weight: 500;
  }

  .employee_content_button {
    padding: 8px 14px;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    background: #011e39;
    font-weight: 500;
    font-size: 12px;
  }

  .employee_content_button:hover {
    padding: 8px 14px;
    opacity: 0.9;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    background: #011e39;
    font-weight: 400;
  }

  .employee_company .slider-container {
    background-color: transparent;
    width: 100%;
    height: 200px;
  }

  .employee_content h6 {
    font-weight: 500;
    font-size: 28px;
    padding-left: 3%;
    color: #ffffff;
  }

  .employee_content p {
    font-weight: 400;
    font-size: 12px;
    padding-left: 3%;
    padding-top: 14px;
    color: #ffffff;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .employee_row {
    width: 95%;
    flex-direction: column;
  }

  .employee_company {
    width: 80%;
    margin-left: 10%;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .employee_inner {
    width: 0%;
    padding: auto;
  }

  .employee_inner div {
    height: 185px;
    width: 155px;
    border-radius: 4%;
  }

  .employee_content_title {
    text-align: center;
    padding: 5px;
    font-size: 13px;
    font-weight: 500;
  }

  .employee_content_button {
    padding: 6px 14px;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    background: #011e39;
    font-weight: 500;
    font-size: 12px;
  }

  .employee_content_button:hover {
    padding: 6px 14px;
    opacity: 0.9;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    background: #011e39;
    font-weight: 400;
  }

  .employee_company .slider-container {
    background-color: transparent;
    width: 100%;
    height: 200px;
  }

  .employee_content h6 {
    font-weight: 500;
    margin-top: 50px;
    font-size: 24px;
    text-align: center;
    color: #ffffff;
  }

  .employee_content p {
    font-weight: 400;
    font-size: 14px;
    padding-top: 14px;
    text-align: center;
    color: #ffffff;
  }

  .employee_company .slick-dots {
    margin-left: -10%;
  }
}

@media (max-width: 576px) and (min-width: 280px) {

  .calande_popup_close_button {
    top: 2rem !important;
    left: 40% !important;
  }

  .employee_row {
    width: 100%;
    flex-direction: column;
  }

  .employee_company {
    width: 210px;
    display: block;
    padding: 2%;

    margin-top: 50px;
    margin-bottom: 30px;
  }

  .employee_inner {
    width: 50%;
    padding: auto;
  }

  .employee_inner div {
    height: 185px;
    width: 170px;
    border-radius: 4%;
  }

  .employee_company .slick-dots {
    display: block;
    margin-left: -5%;
  }

  .employee_content_title {
    text-align: center;
    padding: 6px;
    font-size: 14px;
    font-weight: 500;
  }

  .employee_content_button {
    padding: 10px 18px;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    background: #011e39;
    font-weight: 500;
    font-size: 12px;
  }

  .employee_content_button:hover {
    opacity: 0.9;
    padding: 6px 14px;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    background: #011e39;
    font-weight: 500;
    font-size: 12px;
  }

  .employee_company .slider-container {
    background-color: transparent;
    width: 100%;
    height: 200px;
  }

  .employee_content h6 {
    font-weight: 500;
    margin-top: 50px;
    font-size: 28px;
    text-align: center;
    color: #ffffff;
  }

  .employee_content p {
    font-weight: 400;
    font-size: 14px;
    padding-top: 14px;
    text-align: center;
    color: #ffffff;
  }
}

/* .employee section end */

/* .service section start */
.service_main {
  background-image: url(../../utils/landingpage/images/services_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 480px;
  font-family: "Poppins", sans-serif;
}

.service_content_btn {
  padding-top: 14px;
}

.service_content_btn button {
  padding: 10px 30px;
  background-color: #f38d00;
  color: #ffffff;
  border: 1px solid transparent;
  border-radius: 10px;
  font-weight: 600;
}

.service_content_btn button:hover {
  background-color: #dd850c;
}

.service_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
}

.service_container_semi {
  margin: auto;
  text-align: center;
  color: #ffffff;
  display: grid;
  gap: 25px;
}

.service_container_content h2 {
  font-size: 48px;
  font-weight: 600;
  padding: 0px 50px 0px 50px;
  display: grid;
  place-items: center;
}

.service_container_description {
  width: 70%;
  margin: auto;
}

.service_container_description b {
  font-size: 16px;
  font-weight: 300;
}

.counter-inner {
  display: grid;
  place-items: center;
}

@media (max-width: 768px) and (min-width: 576px) {
  .service_container_content h2 {
    font-size: 36px;
    padding: 0px 50px 0px 50px;
  }

  .service_container_description b {
    font-size: 14px;
  }

  .service_content_btn button {
    padding: 9px 27px;
    background-color: #f38d00;
    color: #ffffff;
    border: 1px solid transparent;
    border-radius: 10px;
    font-weight: 600;
  }
}

@media (max-width: 576px) and (min-width: 180px) {
  .service_container_content h2 {
    font-size: 24px;
    padding: 0 4px;
  }

  .service_container_description b {
    font-size: 12px;
  }

  .service_content_btn button {
    padding: 8px 24px;
    background-color: #f38d00;
    color: #ffffff;
    border: 1px solid transparent;
    border-radius: 10px;
    font-weight: 600;
  }

  .service_content_btn button:hover {
    background-color: #dd850c;
  }
}

/* service section end  */

/* .faq section start */
.faq-secion {
  font-family: "Poppins", sans-serif;
  width: 63%;
  margin: auto;
  padding-top: 172px;
  padding-bottom: 172px;
}

.faq-header {
  text-align: center;
  padding-bottom: 50px;
}

.faq-header h1 {
  font-size: 48px;
  font-weight: 600;
  line-height: 52.8px;
  text-align: center;
  margin-bottom: 10px;
  color: #111111;
}

.faq-text {
  font-size: 20px;
  color: #000000;
  padding-left: 10px;
  font-weight: 400;
}

.faq-header p {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.76px;
  text-align: center;
  color: #222222;
  padding-top: 1.2rem;
}

.faq-item {
  gap: 20px;
  background: #ffffff;
  border-bottom: 1px solid #f38d00;
}

/* .faq-item:hover {
  border-bottom: none;
  box-shadow: 0px 0px 5px 2px #00000033;
  border-radius: 20px;  
} */

.faq-item.active {
  border-radius: 20px;
  margin-bottom: 10px;
  border: 1px solid #00000014;
  box-shadow: 1px 1px 6px 0px #00000033;
}

.new-faq-items .active {
  display: grid;
}

.faq-item.active .number {
  color: #f38d00;
  font-weight: 700;
}

.faq-question {
  height: 5rem;
  padding: 0px 35px 0px 35px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.faq-question:hover .number {
  color: #f38d00;
}

.number {
  width: 29px;
  gap: 0px;
  opacity: 1px !important;
  font-size: 24px;
  font-weight: 500;
  line-height: 42px;
  text-align: left;
  color: rgba(76, 76, 76, 1);
}

.number:hover {
  color: #f38d00;
}

.faq-question span {
  margin-right: 10px;
}

.faq-question .arrow::before {
  font-size: 1em;
}

.faq-header h1 {
  font-size: 42px;
}

.faq-answer {
  padding: 0px 15px 25px 5rem;
  background: #fff;
  border-radius: 20px;
  display: none;
}

.faq-answer p {
  width: 80%;
  margin: 0;
  color: #666;
}

@media (max-width: 1650px) and (min-width: 280px) {
  .faq-secion {
    width: 85%;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .faq-secion {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .faq-header h1 {
    font-size: 32px;
  }

  .faq-text {
    font-size: 16px;
    font-weight: 500;
  }

  .number {
    font-size: 18px;
  }
}

@media (max-width: 576px) and (min-width: 180px) {
  .faq-secion {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .faq-question {
    height: 6rem;
    padding: 0px 10px 0px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .faq-answer p {
    font-size: 14px;
    width: 100%;
  }

  .faq-header h1 {
    font-size: 28px;
  }

  .faq-header p {
    font-size: 14px;
    padding-top: 0.5rem;
  }

  .faq-text {
    font-size: 14px;
    font-weight: 500;
  }

  .faq-question div {
    display: flex;
    align-items: baseline;
  }

  .faq-answer {
    padding: 0px 15px 25px 3rem;
    background: #fff;
    border-radius: 20px;
    display: none;
  }

  .number {
    font-size: 14px;
  }
}

/* faq end here ======================================== */

/* Testimonial start */
.testimonial_main {
  width: 63%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 687px;
}

.testimonial_container {
  width: 78%;
  padding-top: 172px;
  padding-bottom: 178px;
}

.testimonial_slider_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  padding: 36px;
}

.testimonial_heading h3 {
  font-size: 44px;
  font-weight: 600;
  font-family: "Poppins";
  text-align: center;
  color: #222222;
}

.testimonial_heading h4 {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins";
  color: #3c3c3c;
}

.testimonial_hero_section {
  margin-top: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonial_img_background {
  height: 170px;
  border-radius: 50%;
  width: 170px;
  background-color: #f38d00;
}

.testimonial_image {
  position: relative;
  margin-left: -2%;
}

.testimonial_image img {
  position: absolute;
  top: 0;
  /* left: ; */
}

.slick-slide img {
  display: block;
  /* margin-top: 11px; */
  /* margin-left: 16px !important; */
  object-fit: cover;
}

.employee_company .slick-slide img {
  display: block;
  margin-top: 11px;
  margin-left: 0 !important;
  object-fit: cover;
}

.testimonial_content {
  width: 61%;
  font-family: "Poppins";
  height: 100%;
  padding-left: 8%;
}

.testimonial_content p {
  font-weight: 400;
  font-size: 16px;
}

.testimonial_content h5 {
  font-weight: 600;
  font-size: 20px;
  padding-top: 20px;
}

.testimonial_content h6 {
  padding-top: 20px;
  font-weight: 400;
  font-size: 14px;
  padding-top: 0px;
  margin-top: -3px;
}

.testimonial_container .slick-dots li button:before {
  content: "";
  width: 17px;
  height: 4px;
  border-radius: 12px !important;
  display: block;
  background-color: gray;
  border-radius: 0;
  font-size: 0;
  margin-top: 40px;
}

.testimonial_container .slick-dots li.slick-active button:before {
  background-color: #f38d00 !important;
}

.testimonial_user_image {
  object-fit: cover !important;
  border-radius: 50% !important;
}

.testimonial_user_image .slick-slide img {
  margin-left: 8px !important;
}

.testimonial_user_image img {
  width: 173px !important;
  height: 173px !important;
  margin: 0 !important;
  border-radius: 50% !important;
  padding: 0.6rem;
  object-fit: cover !important;
}

.employee_company .slick-slide img {
  display: block;
  margin-top: 11px;
  margin-left: 0 !important;
  object-fit: cover;
  width: 90px;
}

.testimonial_content {
  width: 61%;
  font-family: "Poppins";
  height: 100%;
  padding-left: 8%;
}

.testimonial_content p {
  font-weight: 400;
  font-size: 16px;
}

.testimonial_content h5 {
  font-weight: 600;
  font-size: 20px;
  padding-top: 20px;
}

.testimonial_content h6 {
  padding-top: 20px;
  font-weight: 400;
  font-size: 14px;
  padding-top: 0px;
  margin-top: -3px;
}

.testimonial_container .slick-dots li button:before {
  content: "";
  width: 17px;
  height: 4px;
  border-radius: 12px !important;
  display: block;
  background-color: gray;
  border-radius: 0;
  font-size: 0;
  margin-top: 40px;
}

.testimonial_container .slick-dots li.slick-active button:before {
  background-color: #f38d00 !important;
}

.testimonial_user_image {
  object-fit: cover !important;
  border-radius: 50% !important;
}

.testimonial_user_image .slick-slide img {
  margin-left: 8px !important;
}

.testimonial_user_image img {
  width: 140 !important;
  height: 102 !important;
  margin-top: 0 !important;
  border-radius: 50% !important;
  object-fit: cover !important;
}

@media (max-width: 1450px) and (min-width: 280px) {
  .testimonial_main {
    width: 85%;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .testimonial_main {
    width: 85%;
    height: 500px;
  }

  .testimonial_container {
    width: 85%;
  }

  .testimonial_heading h3 {
    font-size: 34px;
  }

  .testimonial_content p {
    font-size: 12px;
  }

  .testimonial_content h5 {
    font-size: 14px;
  }

  .testimonial_user_image img {
    height: 150px !important;
    width: 150px !important;
  }

  .testimonial_img_background {
    height: 150px !important;
    width: 150px !important;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .testimonial_main {
    width: 85%;
    height: 460px;
  }

  .testimonial_container {
    width: 95% !important;
  }

  .testimonial_heading h3 {
    font-size: 28px;
  }

  .testimonial_content p {
    font-size: 12px;
  }

  .testimonial_content h5 {
    font-size: 14px;
  }

  .testimonial_user_image img {
    height: 135px !important;
    width: 135px !important;
  }

  .testimonial_img_background {
    height: 135px !important;
    width: 135px !important;
  }
}

@media (max-width: 576px) and (min-width: 180px) {
  .testimonial_main {
    width: 85%;
    height: 680px;
  }

  .testimonial_container {
    width: 85% !important;
    height: auto;
  }

  .testimonial_hero_section {
    display: grid;
    place-content: center;
    align-items: center;
    width: 100%;
  }

  .testimonial_img_background {
    height: 120px !important;
    border-radius: 50%;
    width: 120px !important;
    background-color: #f38d00;
  }

  .testimonial_image {
    position: relative;
    margin-left: 36%;
  }

  .testimonial_image img {
    position: absolute;
    top: -5%;
    /* left: ; */
  }

  .testimonial_content {
    width: 95%;
    padding-top: 30px;
    text-align: center;
  }

  .testimonial_heading h3 {
    font-size: 24px;
  }

  .testimonial_content p {
    font-size: 13px;
    padding: 8px;
  }

  .testimonial_content h5 {
    font-size: 14px;
  }

  .testimonial_user_image img {
    height: 80px !important;
    width: 80px !important;
  }

  .testimonial_img_background {
    height: 80px !important;
    width: 80px !important;
  }
}

/* Testimonial End */

/* footer section start  */
.footer_looking {
  padding-top: 50px;
  font-family: "Poppins", sans-serif;
}

.footer_main {
  background: #011e39;
  color: white;
  margin-top: 12rem;
  font-family: "Poppins", sans-serif;
}

.footer_detail {
  width: 63%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding-top: 8rem;
  padding-bottom: 3rem;
}

.footer_child {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  width: 63%;
  margin: auto;
  margin-top: -10.5rem;
}

.footer_child1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background-color: #f38d00;
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
  padding: 60px 30px;
  width: 49%;
}

.footer_child1 h3 {
  font-size: 21px;
}

.footer_child2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background-color: #011e39;
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
  padding: 60px 30px;
  width: 49%;
}

.footer_txt {
  text-align: justify;
  display: grid;
  padding-left: 10px;
}

.footer_txt h3 {
  font-size: 20px;
  line-height: 28px;
  width: 91%;
}

.footer_txt h6 {
  display: grid;
  align-items: end;
  font-weight: 300;
}

.footer_link a {
  color: #011e39;
  font-weight: 700;
  font-size: 16px;
  padding-top: 30px;
  white-space: nowrap;
}

.footer_link2 a {
  color: #f38d00;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: baseline;
  gap: 3px;
  white-space: nowrap;
}

.footer_link2 img {
  width: 18px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.footer_link img {
  width: fit-content;
  height: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.footer_link img:hover,
.footer_link2 img:hover {
  transform: translateX(5px);
}

.footer_logo img {
  width: 220px;
}

.footer_write p {
  text-align: justify;
  margin-top: 15px;
  font-weight: 300;
}

.footer_link_write {
  display: grid;
  margin-top: 15px;
  gap: 23px;
}

.footer_facebook img {
  transition: transform 0.3s ease;
  width: 28px;
  height: 28px;
}

.footer_facebook img:hover {
  transform: scale(1.2);
}

.footer_foot {
  width: 63%;
  margin: auto;
}

.footer_line {
  gap: 70px;
  line-height: 32px;
  display: grid;
}

.footer_menu {
  width: 41%;
  padding-right: 16px;
}

.footer_menu3 {
  line-height: 35px;
}

.footer_menu4 {
  line-height: 35px;
}

.footer_menu5 {
  line-height: 20.8px;
  size: 16px;
  height: 42px;
  width: 185px;
}

.footer_links {
  margin-top: 25px;
  display: flex;
  gap: 15px;
}

.footer_menu2 h3 b {
  font-weight: 600;
}

.footer_menu_anchers {
  display: grid;
  gap: 3px;
  padding-top: 4px;
}

.footer_menu_anchers a {
  text-decoration: none;
  list-style: none;
  color: #ffffff;
  font-weight: 300;
}

.footer_menu4 span {
  font-weight: 300;
  color: #ffffff;
}

.footer_menu_anchers a:hover {
  color: #f38d00;
}

.footer_menu4 p h3 b {
  font-weight: 600;
}

.footer_menu_anchers_services {
  gap: 13px;
}

.footer_condition p {
  font-size: 16px;
  font-weight: 200;
  color: #ffffffeb;
}

.profile_row {
  overflow: hidden;
}

@media (max-width: 1650px) and (min-width: 280px) {
  .footer_detail {
    width: 85%;
  }

  .footer_foot {
    width: 85%;
    margin: auto;
    text-align: center;
  }
}

@media (max-width: 1650px) and (min-width: 1200px) {
  .footer_child {
    width: 87%;
  }

  .footer_foot {
    width: 85%;
    margin: auto;
    text-align: left;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .footer_child {
    display: flex;
    gap: 1rem;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    margin-top: -13.5rem;
    align-items: center;
  }

  .footer_txt h6 {
    font-size: 18px;
  }

  .footer_txt h3 {
    font-size: 18px;
  }

  .footer_link2 a,
  .footer_link a {
    font-size: 14px;
  }

  .footer_image a img {
    height: 32px;
  }

  .footer_detail {
    display: grid;
    grid: auto-flow / 2fr 3fr 2fr 2fr;
  }

  .footer_line {
    height: fit-content;
  }

  .footer_foot {
    width: 85%;
    margin: auto;
    text-align: left;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .footer_child {
    display: flex;
    gap: 1rem;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    margin-top: -13.5rem;
    align-items: center;
  }

  .footer_child2,
  .footer_child1 {
    width: 65%;
  }

  .footer_txt h6 {
    font-size: 18px;
  }

  .footer_txt h3 {
    font-size: 18px;
  }

  .footer_link2 a,
  .footer_link a {
    font-size: 14px;
  }

  .footer_image a img {
    height: 32px;
  }

  .footer_detail {
    display: grid;
    grid-gap: 18px;
    grid-template-columns: 2fr 3fr 2fr 2fr;
  }

  .footer_line {
    height: fit-content;
  }

  .footer_foot {
    width: 85%;
    margin: auto;
    text-align: left;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .footer_child {
    display: flex;
    gap: 1rem;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    margin-top: -11.5rem;
    align-items: center;
  }

  .footer_child2,
  .footer_child1 {
    width: 69%;
    padding: 35px 44px;
  }

  .footer_txt h6 {
    font-size: 16px;
  }

  .footer_txt h3 {
    font-size: 9px;
  }

  .footer_link2 a,
  .footer_link a {
    font-size: 12px;
  }

  .footer_image a img,
  .footer_image img {
    height: 28px;
  }

  .footer_detail {
    display: grid;
    grid: auto-flow / 0fr 0fr 0fr;
    gap: 3rem;
  }

  .footer_menu,
  .footer_line,
  .footer_menu2,
  .footer_menu4 {
    grid-column: span 1;
    height: fit-content;
  }

  .footer_foot {
    width: 85%;
    margin: auto;
    text-align: center;
  }
}

@media (max-width: 576px) and (min-width: 180px) {
  .footer_main {
    text-align: center;
  }

  .footer_child {
    display: flex;
    gap: 1rem;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    margin-top: -11.5rem;
    align-items: center;
  }

  .footer_child2,
  .footer_child1 {
    width: 85%;
    padding: 35px 35px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer_links {
    justify-content: center;
  }

  .footer_write p {
    text-align: center;
    font-size: 14px;
  }

  .footer_txt h6 {
    font-size: 16px;
  }

  .footer_txt h3 {
    font-size: 10px;
  }

  .footer_link2 a,
  .footer_link a {
    font-size: 14px;
    padding-left: 10px;
  }

  .footer_image a img,
  .footer_image img {
    height: 38px;
  }

  .footer_menu {
    width: 75%;
    margin: auto;
  }

  .footer_detail {
    display: flex;
    gap: 3rem;
    flex-direction: column;
  }

  .footer_menu5 {
    text-align: center;
    width: auto;
  }

  .footer_foot {
    width: 85%;
    margin: auto;
    text-align: center;
  }
}

/* footer section end  */