:root {
  /* Colors */
  --primary-color: #f38d00;
  --text-primary: #3c3852;
  --text-secondary: #6e6b80;
  --white: #fff;
  --black: #111;
  --border-color: #ccc;
  --error-color: #ff4444;
  --background-light: #f1f1f1;

  /* Spacing */
  --spacing-sm: 0.8rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;

  /* Border radius */
  --border-radius-sm: 8px;
  --border-radius-md: 12px;
  --border-radius-lg: 20px;
  --border-radius-pill: 50px;
}

.assessment_questons_container {
  padding: 1rem;
}

.assesment_category_conatiner {
  display: flex;
  align-items: center;
  padding-left: 1rem;
}

#add_assessment_category_input {
  margin-top: 30px;
  border-radius: var(--border-radius-lg);
  padding-left: 15px;
  height: 40px;
  width: 280px;
  outline: none;
  border: 1px solid var(--text-secondary) !important;
}

.assesment_info_label {
  width: 100%;
  padding: 0.8rem 1.5rem;
}
.assessment_add_category_btn {
  text-align: center;
  padding: 8px 30px;
  margin-top: 34px;
  margin-left: 10px;
  background-color: var(--primary-color);
  color: var(--white);
  font-weight: 600;
  border-radius: var(--border-radius-pill);
  cursor: pointer !important;
}

.add_queation {
  padding: 10px 15px;
  width: 131px;
  margin-left: 10px;
  background-color: #f38d00;
  color: #fff;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer !important;
}

.assessment_category_container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: start;
}

.assessment_category_card {
  width: auto;
  font-family: "Arial";
  min-width: 180px;
  padding: var(--spacing-md);
  cursor: pointer;
  border-radius: var(--border-radius-md);
  background: var(--white);
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 3%);
  position: relative;
}

.assessment_category_card > * + * {
  margin-top: 1.1em;
}

.card__content {
  color: var(--text-primary);
  font-size: 0.86rem;
}

.card__title {
  padding: 0;
  font-size: 1.3rem;
  font-weight: bold;
}

.card__date {
  color: var(--text-secondary);
  font-size: 0.8rem;
  padding-bottom: 20px;
}

.card__arrow {
  position: absolute;
  background: var(--primary-color);
  padding: 0.4rem;
  border-top-left-radius: var(--border-radius-md);
  border-bottom-right-radius: var(--border-radius-md);
  bottom: 0;
  right: 0;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card__arrow svg {
  transition: 0.2s;
}

.assessment_category_card:hover .card__title {
  color: #f38d00;
  text-decoration: underline;
}

.card__arrow:hover,
.card__delete:hover {
  background: #111;
}

.assessment_category_card:hover .card__arrow svg {
  transform: translateX(3px);
}

.card__delete {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #f38d00;
  cursor: pointer;
  border: none;
  height: 25px;
  width: 25px;
  transition: transform 0.2s;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card__delete:hover {
  transform: scale(1.1); /* Slightly increase size on hover */
}

.assessment_question {
  font-weight: normal;
  font-size: 1em;
  color: #333;
  font-family: "Poppins", sans-serif;
}

.assessment_answer_list {
  list-style-type: none;
  padding-left: 0;
}

.assessment_answer {
  padding: 10px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px 0;
  font-size: 14px;
}

.assessment_correct_answer {
  margin-top: 10px;
  font-weight: bold;
  font-size: small;
  color: #e74c3c;
}

.assessment_question_title_con {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 1rem;
}

.assessment_question_title_con button {
  border: none;
  background-color: #f38d00;
  border-radius: 20px;
  padding: 5px 18px;
  color: #fff;
  font-weight: 600;
}

.assessment_modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.assessment_modal_content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 50%;
  width: 100%;
}

.assessment_modal_content input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 18px;
}

.submit_add_qustion_btn {
  border: none;
  background-color: #f38d00;
  border-radius: 20px;
  padding: 10px 18px;
  color: #fff;
  font-weight: 600;
  float: right;
}

.no_question_post_yet {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: #fff;
}
.assessment_question_input {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.assessment_question_input input {
  flex: 1 1 calc(50% - 10px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 18px;
}

.insufficient-questions {
  position: relative;
  border: 1px solid var(--error-color) !important;
}

.insufficient-questions::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid transparent;
  transition: all 0.3s ease;
}

.insufficient-questions:hover::before {
  border-color: var(--error-color);
  box-shadow: 0 0 10px rgba(255, 68, 68, 0.3);
  border-radius: var(--border-radius-sm);
  border: 0.8px solid var(--error-color) !important;
}
