
.student_campus_parrent_save_data {
  /* padding: 40px; */
  /* padding-left: 300px; */
  /* background: radial-gradient(
    46.51% 46.51% at 0% 50%,
    #f2f2f2 0%,
    #f4f6fc 100%
  ); */
  padding-top: 20px;
  width: 100%;
  height: 100%;
  display: grid;
  gap: 25px;
  /* padding-top: 120px;
  padding-right: 25px; */
}
.student_campus_all_jobs {
  border: 1px solid #ccc;
  padding: 10px;
  /* margin-bottom: 15px !important; */
  align-items: center;
  box-shadow: 0px 2px 4px 0px #00000026;
  /* width: 100%; */
  /* height: 170px !important; */
  /* top: 162px; */
  padding: 16px 28px 16px 28px;
  gap: 12px;
  border-radius: 16px;
  opacity: "0px";
  background: white;
}

.student_campus_all_jobs .student_campus_container_save .student_campus_job_logo {
  margin-right: 15px;
  color: red;
  width: 40px;
  height: 40px;
  border: 2px solid gray;
  border-radius: 5px;
  padding: 5px;
  opacity: 0.8px;
}

.student_campus_Frontend_developer {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #111111;
}
.student_campus_star_img {
  width: 12px;
  height: 12px;
  border-radius: 0.5px;
  opacity: 0px;
}

.student_campus_company_name {
  display: flex;
  gap: 10px;
}

        /* <div className="pagination-controls"> */




.student_campus_company_name .student_campus_job_companyname {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #2c2c2c;
}

.student_campus_company_name .student_campus_company_rating {
  font-size: 12px;
  opacity: 0px;
  text-align: left;
}

.student_campus_job_dollar {
  /* width: 20px;
  height: 20px; */
  border: 1px solid gray;
  border-radius: 50px;
  padding: 3px;
}

.student_campus_job_exppro{
  width: 12px;
  height: 14px;
  top: 2px;
  
}
.student_campus_Contain_div {
  display: flex;
  gap: 18px;
  padding-top: 10px;
}
.student_campus_Contain_div .student_campus_company_experience {
  display:flex;
  /* width: 154px; */
  padding-right: 10px;
  /* height: 16px; */
  border-right: 1px solid gray;
  padding-top: 4px;
  /* padding-bottom: 20px; */
}

.student_campus_Contain_div .student_campus_company_experience .student_campus_job_containt {
  /* width: 200px; */
  font-size: 12px;
  font-weight: 400;
  line-height: 14.88px;
  padding-left: 5px;
  /* text-align: left; */
  color: #2c2c2c;
}

.student_campus_Contain_div .student_campus_company_dollar {
  width: 120px;
  height: 18px;
  gap: 0px;
  opacity: 0px;
  border-right: 1px solid gray;
  padding-right: 10px;
  padding-bottom: 20px;
}

/* .student_campus_Contain_div.student_campus_company_dollar .student_campus_job_containsalery {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
} */



 .student_campus_job_containsalery {
  /* width: 300px; */
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.student_campus_Contain_div .student_campus_company_map{
  width: 166px;
  height: 18px;
  gap: 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
}

.student_campus_Contain_div .student_campus_company_map .student_campus_job_containtlocation{
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.student_campus_job_details{
  display: flex;
  width: 1, 036px;

  /* height: Hug (16px) px; */
  gap: 0px;
  justify-content: space-between;
  /* opacity: 0px; */
  padding-top: 10px;
}

.student_campus_job_details .student_campus_job_duration {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.32px;
  text-align: left;
}

.student_campus_container_save {
  display: flex;
  width: Fill (1, 036px) px;
  height: Hug (74px) px;
  gap: 0px;
  justify-content: space-between;
  /* opacity: 0px; */
}

.student_campus_job_map {
  /* width: 12px;
  height: 18px; */
  top: 4px;
  left: 204px;
  gap: 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
}

.student_campus_save_data {
  display: flex;
  gap: 12px;
}

.student_campus_save_img {
  width: 10px;
  height: 14px;
  gap: 0px;
  border-radius: 0.5px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
}

.student_campus_job_details {
  display: flex;
  width: Fill (1, 036px) px;
  height: Hug (16px) px;
  gap: 0px;
  justify-content: space-between;
  /* opacity: 0px; */
  padding-top: 10px;
}

.student_campus_detail_job{
  height: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.32px;
  color: #f38d00;
}
.student_campus_unsave_job {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.32px;
  text-align: left;
}

.student_campus_paragraf_saved {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.32px;
  text-align: left;
  color: #3c3c3c;
  padding-top: 13px;

  width: 100%;
}

.student_campus_job_link {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.32px;
  color: #f38d00;
}

.student_campus_all_jobs .student_campus_container_save .student_campus_job_logo {
  margin-right: 15px;
  color: red;
  width: 40px;
  height: 40px;
  border: 2px solid gray;
  border-radius: 5px;
  padding: 5px;
  opacity: 0.8px;
}