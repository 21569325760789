/* -------------------------------------------- testimonial form start --------------------------------------*/
.clientTestimonial_main_container {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 1rem;
}

.arrow_icon {
  height: 15px;
  cursor: pointer;
}

.clientTestimonial_header .sub_heading {
  color: #5a5c5f;
  padding: 30px 22px;
  font-size: small;
}

.clientTestimonial_upload_section_input_fields {
  display: flex;
  flex-wrap: wrap;
  gap: 4%;
  padding: 1rem;
  border-radius: 15px;
}

.clientTestimonial_upload_section {
  padding: 1rem;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  gap: 2%;
  align-items: center;
}

.clientTestimonial_form {
  display: flex;
  flex-direction: column;
}

.clientTestimonial_input {
  width: 48%;
  border-radius: 8px;
}

.clientTestimonial_input input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
}

.clientTestimonial_textarea {
  width: 100%;
  margin-top: 1rem;
}

.clientTestimonial_textarea textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  resize: vertical;
}

.clientTestimonial_btn {
  display: flex;
  padding: 0.5rem 0.8rem;
  gap: 1rem;
}

.clientTestimonial_submit_btn {
  background-color: #f38d00;
  color: #fff;
  padding: 5px 16px;
  font-weight: 600;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.clientTestimonial_submit_btn:hover {
  background-color: #fff;
  color: #f38d00;
  border: 2px solid #f38d00;
}

.clientTestimonial_cancel_btn,
.view_all_testimonials_btn {
  border: 2px #f38d00 solid;
  background-color: #fff;
  color: #f38d00;
  padding: 5px 16px;
  font-weight: 600;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.clientTestimonial_cancel_btn:hover,
.view_all_testimonials_btn:hover {
  background-color: #f38d00;
  color: #fff;
}

.image_upload_ratings_container,
.video_upload_container {
  border-radius: 8px;
  height: 240px;
  width: 48%;
}

.video_upload_box {
  border: 2px solid #f38d00;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 220px;
  width: inherit;
  border-radius: 10px;
}

.img_upload_icon {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.video_preview {
  border-radius: 10px;
  width: inherit;
  height: inherit;
}

.image_upload_ratings_container {
  display: flex;
  flex-direction: column;
}

.image_upload {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
}

.video_upload_label {
  height: 100%;
  width: 100%;
}

.ratings {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: start;
}

.content_upload_img_label_heading {
  font-size: 10px;
}

.content_upload_img_label_heading_btn {
  font-size: 10px;
  background-color: #f38d00;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  padding: 8px 18px;
  margin-bottom: 3px;
  border-radius: 15px;
}

.content_upload_img_label {
  cursor: pointer;
  height: 70px;
  background: #fffaf3;
  display: flex;
  border-radius: 17px;
  padding: 1.5rem;
  border: 2px solid #f38d00;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.content_upload_img_label p {
  font-size: 15px;
}

#content_upload_img {
  display: none;
}

.add_rating {
  text-align: left;
  font-size: 17px;
  font-family: "Poppins,sans-serif";
}

/* -------------------------------------------- testimonial form end --------------------------------------*/

/* -------------------------------------------- testimonial card preview start --------------------------------------*/

.client_testimonial_card_containers {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 15px;
  padding: 20px;
  gap: 20px;
}

.testimonial_card {
  flex: 0 0 calc(50% - 10px);
  max-width: calc(50% - 10px);
  padding: 20px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.testimonial_header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.testimonial_avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.testimonial_info {
  flex-grow: 1;
  margin-left: 12px;
}

.testimonial_name {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.testimonial_position {
  font-size: 14px;
  color: #666;
  margin: 4px 0 0;
}

.testimonial_rating {
  font-size: 16px;
  color: #ffc107;
  display: flex;
  gap: 2px;
}

.testimonial_content {
  font-size: 14px;
  color: #444;
  width: 100%;
  line-height: 1.6;
}

/* -------------------------------------------- testimonial card preview enp --------------------------------------*/
