.student_campus_save_button {
    margin-top: 0 !important;
    padding: 0 !important;
}

.job_list_job_active {
    font-size: 10px;
    border: none;
    background: green;
    color: #fafffa;
    padding: 1px 7px 1px 7px;
    border-radius: 9px;
    margin-bottom: 5px;
}

.job_list_job_closed{
    background: #f50000bf;
}

.job_apply_disabled{
    background: #ad6707 !important;
    cursor: not-allowed !important;
}