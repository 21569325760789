._main_dashboard {
  display: flex;
  background-color: #f4f6fc;
}

._side_navigation {
  background-color: #011e39;
  color: white;
  padding: 10px 0px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: width 0.3s ease;
  overflow: scroll;
}

._side_navigation {
  width: 276px;
  transition: all 0.5s ease;
}

._main_content_inner_screen {
  font-family: "Poppins", sans-serif;
  background-color: #f4f6fc;
  min-height: 190px;
  max-height: auto;
  padding-left: 280px;
}

._side_navigation.closed {
  width: 0;
  overflow: hidden;
}

._main_content_inner_screen_conatiner {
  width: 90%;
  margin: auto;
  min-height: 100vh;
  padding-top: 6rem;
  background-color: #f4f6fc;
}

._menu_container {
  padding-top: 1.5rem;
}

._side_navigation::-webkit-scrollbar {
  width: 2px;
}

._side_navigation::-webkit-scrollbar-track {
  background: transparent;
}

._side_navigation::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid transparent;
}

._side_navigation::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

._company_logo h2 {
  margin: 0;
}

._company_logo p {
  margin: 5px 0 20px;
  font-size: 0.8em;
}

._menu_list {
  list-style-type: none;
  padding: 0;
  padding-top: 10px;
}

._menu_item {
  padding: 13px 0;
  padding-left: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
}

._menu_item:hover {
  background-color: #ffffff14;
}

._menu_item a {
  text-decoration: none;
  list-style: none;
  color: #ffffff;
  font-weight: 300;
  font-size: 16px;
}

._active_item {
  background-color: #ffffff14;
  backdrop-filter: blur(1px);
}

/* ._logout_button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
} */

._content_area {
  flex-grow: 1;
  padding: 0px;
  background: radial-gradient(46.51% 46.51% at 0% 50%,
      #f2f2f2 0%,
      #f4f6fc 100%);
}

._top_header {
  padding: 10px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  right: 0;
  z-index: 10;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

._top_header b {
  font-size: 20px;
  font-weight: 500;
  color: #011e39;
}

._top_header_inner {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  margin: auto;
}

._user_info {
  display: flex;
  align-items: center;
  gap: 20px;
}

.hr-stat-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 82%;
  margin-left: 9%;
}


.stat-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  margin-left: 0%;
}

.stat-card {
  background-color: white;
  border-radius: 18px;
  box-shadow: 0 2px 4px rgba(12, 12, 12, 0.1);
  width: 20%;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  padding-top: 0;
  padding-bottom: 0;
}

.hr-stat-card {
  background-color: white;
  border-radius: 18px;
  box-shadow: 0 2px 4px rgba(12, 12, 12, 0.1);
  width: 18%;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  padding-top: 0;
  padding-bottom: 0;
}
.admin-stat-card {
  background-color: white;
  border-radius: 18px;
  box-shadow: 0 2px 4px rgba(12, 12, 12, 0.1);
  width: 18%;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  padding-top: 0;
  padding-bottom: 0;
}

.stat-icon {
  font-size: 24px;
  color: #ff914d;
  margin-right: 15px;
  background: #fff0dc;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 18px 0px 0px 18px;
}

.stat-info {
  margin-top: -19px;
}

.stat-info h3 {
  font-size: 12px;
  color: #111111;
  margin: 0;
}

.stat-info p {
  font-size: 20px;
  color: #111111;
  font-weight: 600;
  margin: 0;
  height: 13px;
}

._main_first_content {
  width: 66%;
}

._chart_section,
._recent_jobs {
  height: fit-content;
  background-color: white;
  border-radius: 17px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

._chart_section {
  width: 100%;
}

._recent_jobs_head {
  width: 31%;
  padding-bottom: 2rem;
}

._chart_placeholder {
  height: 200px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

._chart_legend {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

._legend_item {
  display: flex;
  align-items: center;
}

._color_box {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 4px;
}

._yellow_box {
  background-color: #ffd700;
}

._red_box {
  background-color: #ff6b6b;
}

._green_box {
  background-color: #4caf50;
}

._job_list,
._application_list {
  margin-top: 20px;
}

._job_item,
._application_item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

._company_icon,
._company_logo {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-bottom: 1.5rem;
  padding-top: 1rem;
  padding-left: 2rem;
  width: -moz-fit-content;
  width: 100%;
  border-bottom: 1px solid #ffffff78;
}

.view_more {
  margin-left: auto;
  text-decoration: none;
  color: #ffa500;
}

._applications_history {
  margin-top: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

._filter_buttons {
  margin-bottom: 20px;
}

._filter_btn {
  margin-right: 10px;
  padding: 5px 15px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

._shortlisted_btn {
  background-color: #4caf50;
  color: white;
}

._inreview_btn {
  background-color: #ffd700;
}

._rejected_btn {
  background-color: #ff6b6b;
  color: white;
}

._options_button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

._menu_container b {
  font-size: 12px;
  font-weight: 300;
  padding-left: 2rem;
}

._recentadded_section,
._popularjobs_section {
  border-radius: 10px;
  margin-bottom: 20px;
}

._recentadded_header,
._popularjobs_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

._recentadded_header b {
  font-size: 15px;
  color: #000000;
  font-weight: 600;
}

._recentadded_seeAllLink,
._popularjobs_seeAllLink {
  color: #f38d00;
  text-decoration: none;
  font-size: 15px;
}

._recentadded_jobCard {
  padding: 15px;
  box-shadow: 2px 2px 6px 2px #e0e0e0;
  border-radius: 8px;
  margin-bottom: 18px;
}

._recentadded_companyLogo {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  color: #333;
}

._recentadded_jobInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 9px;
}

._recentadded_jobTitle {
  margin: 0;
  font-size: 14px;
  color: #111111;
}

._recentadded_companyName,
._recentadded_jobLocation,
._recentadded_jobDate {
  margin: 0;
  font-size: 12px;
  color: #2c2c2c;
}

._recentadded_viewMoreBtn {
  background-color: transparent;
  color: #f38d00eb;
  border: none;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  text-decoration: underline;
}

.campus_dashboard_icon {
  width: 40px;
  height: 40px;
  border: 2px solid #acbac7c7;
  border-radius: 8px;
  padding: 5px;
  background: none;
}

._applicationStatisticsContainer {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  /* background-color: #1cc88a; */
  /* max-width: 800px; */
}

._pieChartContainer {
  width: 50%;
  margin-right: 30px;
  border-right: 1px solid #c0c0c0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
}

._pieChartContainer canvas {
  margin: auto !important;
  height: 240px !important;
  width: 240px !important;
}

._legendContainer {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Legend title */
._legendTitle {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 500;
  color: #000000;
  text-align: left;
}

/* Legend list */
._legendList {
  list-style: none;
  padding-left: 0;
  margin: 0;
  padding-top: 1rem;
}

/* Legend item */
._legendItem {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

/* Legend color boxes */
._legendColor {
  width: 18px;
  border-radius: 2px;
  height: 17px;
  display: inline-block;
  margin-right: 10px;
}

._inReviewColor {
  background-color: #9A6BFF;
}

._rejectedColor {
  background-color: #FF652D;
}

._shortlistedColor {
  background-color: #FBBD31;
}

._interviewdColor {
  background-color: #0082ED;
}

._offeredColor {
  background-color: #00854A;
}

._chart_section_b {
  border-bottom: 1px solid #c0c0c0;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

._chart_section_b b {
  font-size: 16px;
  font-weight: 600;
  padding-left: 2rem;
}

._campus_dashboard_application_history {
  width: 100%;
  margin: 0;
  padding: 0;
}

._campus_dashboard_div {
  padding: 20px 0px 0px 10px;
}

.collag_campus_dashboard_div{
  padding: 20px 0px 0px 10px;
  width: 49%;
}

.celander_container {
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  border: #272727c4 1px solid;
  border-radius: 8px;
}

.celander_container p{
  font-size: 14px;
}

.celander_container img {
  padding-right: 15px;
}