:root {
  --primary-color: #f38d00;
  --primary-hover: #d37b00;
  --secondary-color: #011e39;
  --success-color: #00854a;
  --white: #fff;
  --black: #000;
  --grey-100: #f5f5f5;
  --grey-200: #e0e0e0;
  --grey-400: #666666;
  --error-color: #ff0000;
  --loader-blue: #3498db;
  --loader-grey: #f3f3f3;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --overlay-color: rgba(0, 0, 0, 0.5);
}

.circulate_job_detail {
  min-height: 70px;
  width: 100%;
  background-color: #fff;
  margin: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
}

.campuse_std_job_applications-container {
  float: right;
  padding: 16px;
}

.campuse_std_job_applications-button {
  padding: 8px;
  border: none;
  border-radius: 50px;
  padding-left: 16px;
  padding-right: 16px;
  background: var(--primary-color);
  color: var(--white);
  font-weight: 600;
  cursor: pointer;
}

.campuse_std_job_applications-button:hover {
  background: var(--primary-hover);
}

.campuse_std_job_applications-icon {
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
}

.circulate_job {
  height: 70px;
  background: #fff;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
}

.circulate_job_details_header_ul {
  background-color: #011e39;
  color: #f3efef;
  border-radius: 10px 10px 0px 0px;
}

.circulate_job ul,
.circulate_job_detail ul {
  list-style: none;
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.circulate_job_detail ul li {
  flex: 1;
  min-width: 150px;
  text-align: start;
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.circulate_job_type {
  border: 1px solid var(--success-color);
  color: var(--success-color);
  text-align: center;
  border-radius: 20px;
  font-size: 12px;
  width: 80px;
  padding: 0.2rem;
  white-space: nowrap;
}

.job-details-table {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.table-header {
  display: flex;
  background-color: #f5f5f5;
  border-bottom: 2px solid #e0e0e0;
}

.table-row {
  display: flex;
}

.header-cell {
  flex: 1;
  padding: 12px 16px;
  font-weight: 600;
  color: var(--white);
  text-align: left;
  background-color: var(--secondary-color);
}

.header-cell:last-child {
  border-right: none;
}

.table-cell {
  flex: 1;
  padding: 12px 16px;
}

.table-cell:last-child {
  border-right: none;
}

.status {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
}

.circulate_job_type {
  margin: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.circulate_job_list_container {
  padding: 0 0.5rem;
  width: 100%;
  height: 670px;
}

.job_circulate_search_container {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 270px;
  border-radius: 25px;
  display: flex;
  height: 45px;
  justify-content: center;
  align-items: center;
}

.job_circulate_search {
  border: none;
  outline: none;
  width: 230px;
}

.job_circulate_search::placeholder {
  padding-left: 10px;
}

.job_circulate_checkbox {
  width: 22px;
  height: 22px;
  padding-top: 15px;
  border-radius: 2px;
  cursor: pointer;
}

.circulate_job_container {
  padding: 20px;
}

.circulate_job_select_all {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.circulate_job_checkbox {
  width: 20px;
  height: 20px;
  margin-right: 2em;
}

.circulate_job_label {
  font-size: 18px;
  font-weight: 500;
  color: var(--primary-color);
}

.circulate_job_list_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.circulate_job_card {
  display: flex;
  align-items: center;
}

.circulate_job_card_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 10px;
  width: 100%;
}

.circulate_job_image_container {
  border-radius: 2px solid grey;
  padding: 2px;
}

.circulate_job_image {
  width: 60px;
  height: 60px;
  cursor: pointer;
  padding: 5px;
  border-radius: 12px;
  object-fit: contain;
  border: 2px solid #acbac7c7;
}

.circulate_job_details {
  flex-grow: 1;
  font-size: 14px;
  padding-left: 1.5rem;
}

.circulate_job_college_name {
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.circulate_job_stats {
  font-size: 14px;
  display: flex;
}

.circulate_job_stats p {
  display: flex;
  flex-direction: column;
}

.circulate_job_info_link a {
  color: var(--primary-color);
  font-size: 14px;
  font-weight: normal;
}

.circulate_job_info_link a:hover {
  text-decoration: underline;
}

.circulate_job_btn {
  background: var(--primary-color);
  color: var(--white);
  font-size: 16px;
  padding: 8px 12px;
  border: none;
  border-radius: 18px;
  font-weight: 700;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.circulate_job_btn:hover,
.circulate_job_btn:focus {
  background: var(--primary-hover);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  outline: none;
}

.circulate_job_detail {
  display: grid;
  gap: 0;
}

.invite_details {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  min-height: 48px;
  height: auto;
}

.circulate_job_clg_invite_details {
  min-height: 35px;
  height: auto;
  display: flex;
  align-items: center;
  padding: 0.2rem 30px;
  font-size: 14px;
  gap: 30px;
  font-family: "Poppins", sans-serif;
  width: 100%;
  background-color: #fff;
  margin: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 0.8rem !important;
  border-bottom-right-radius: 0.8rem !important;
  flex-wrap: wrap;
}

.circulate_job_clg_invite_details p {
  color: var(--grey-400);
  font-weight: 500;
  margin: 0.5rem 0;
  white-space: nowrap;
  padding: 8px 12px;
  border: 1px solid var(--grey-400);
  border-radius: 24px;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.circulate_job_clg_invite_details p:hover,
.circulate_job_clg_invite_details p:focus {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  outline: none;
}

.circulate_job_detail_table_header {
  background-color: var(--secondary-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  background-color: #011e39;
  color: #fff;
}

.circulate_job_detail_table_header ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 0;
  padding: 0.8rem;
}

.table_header ul li {
  flex: 1;
  min-width: 150px;
  text-align: start;
  padding: 0.5rem;
  font-weight: 600;
  color: var(--grey-400);
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 768px) {
  .circulate_job_detail ul {
    flex-direction: column;
    align-items: flex-start;
  }

  .circulate_job_detail ul li {
    width: 100%;
    text-align: left;
  }

  .circulate_job_clg_invite_details {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    padding: 1rem 25px;
  }

  .table_header {
    display: none;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.custom-swal-confirm-button {
  background-color: #ff8800;
}

.circulate_job_detail_list {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.circulate_job_detail .invite_details ul li p {
  text-align: start;
  font-weight: 500;
}

.label-text {
  color: #666666;
  font-weight: 400;
  margin-bottom: 5px;
}

.status.closed {
  color: var(--error-color);
  opacity: 0.7;
}

.circulate_job_info_link_button {
  background: var(--primary-color);
  color: var(--white);
  font-size: 16px;
  padding: 8px 12px;
  border: none;
  border-radius: 18px;
  font-weight: 700;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.circulate-job-guide-text {
  color: #666;
  font-size: 14px;
  font-style: italic;
  margin-bottom: 10px;
}