.all_applicants_toggle_btn {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  cursor: pointer;
  background-color: #fff;
  color: black;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 30px;
}

.all_applicants_toggle_btn.active {
  background-color: #f38d00;
  color: white;
}

.view_application_container_pagination {
  display: flex;
  justify-content: center;
  position: relative;
}

.view_application_container_pagination_btn {
  position: absolute;
  display: flex;
  gap: 10px;
  right: 0;
  top: 0;
  padding: 0.5rem;
}

button.excel {
  border: 2px solid #f39200;
  background-color: white;
  color: #f39200;
  border-radius: 50px;
  padding: 5px 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

button.email {
  background-color: #f39200;
  border: none;
  color: white;
  border-radius: 50px;
  padding: 5px 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

button.excel:hover,
button.email:hover {
  opacity: 0.8;
}

.view_application_main_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.view_applicationlisting_table {
  border-collapse: collapse;
  margin: 20px 0;
  width: 100%;
  font-size: 14px;
  font-family: "Arial", sans-serif;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.view_applicationlisting_table table {
  width: 100%;
  border-spacing: 0;
  background-color: #fff;
}

.view_applicationlisting_table thead th {
  background-color: #011e39;
  font-weight: bold;
  padding: 22px 18px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  color: #ffff;
}

.view_applicationlisting_table th:first-child {
  border-radius: 10px 0 0 0;
  padding: 20px 20px;
}

.view_applicationlisting_table th:last-child {
  border-radius: 0 10px 0 0;
}

.view_applicationlisting_table td {
  padding: 15px 15px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  border-right: none;
}

.view_applicationlisting_table tr:last-child td {
  border-bottom: none;
}

.view_application_btn {
  color: #fff;
  padding: 0.5rem 0.8rem;
  background-color: #f38d00;
  border-radius: 24px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.view_application_btn:hover {
  background-color: #e67e00;
  transform: scale(1.05);
}

.view_application_delete_btn {
  color: #f38d00;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.view_application_delete_btn:hover {
  color: #d07100;
  transform: scale(1.05);
}

.filter_container_job_listing {
  margin-right: 20px;
}

.select-selected {
  background-color: white;
  padding: 0.6rem 1rem;
  border-radius: 30px;

  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.select-selected:hover {
  box-shadow: rgba(60, 64, 67, 0.5) 0px 2px 4px 0px,
    rgba(60, 64, 67, 0.25) 0px 2px 6px 1px;
  transform: scale(1.02);
}

.select-items {
  position: absolute;
  margin-top: 12px;
  background-color: white;
  border-radius: 10px;
  display: block; /* Show options */
  z-index: 99; /* Show on top */
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.option {
  padding: 0.5rem 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.option:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Hover effect */
}
.filter-icon {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  vertical-align: middle;
  display: inline-block;
}

.filterdropdown {
  margin-left: 5px;
}

.view_application_search_container {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 270px;
  border-radius: 25px;
  display: flex;
  height: 45px;
  justify-content: center;
  align-items: center;
}

.view_application_search {
  border: none;
  outline: none;
  width: 230px;
}

.view_application_search::placeholder {
  padding-left: 10px;
}

.campuse_std_job_applications-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.custom-select {
  padding: 5px 10px; /* Adjust padding if needed */
  font-size: 16px; /* Adjust font size */
}
