.candidate-page {
  margin-top: 30px;
}

.candidate-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 27px 0 43px;
}

.candidate-header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.candidate-search-input {
  position: relative;
}

.candidate-search-input-field {
  padding: 7px 7px 7px 20px;
  width: 17rem;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  outline: none;
  height: 2.6rem;
  border-radius: 50px;
  padding-left: 47px;
}

.candidate-search-input img {
  position: absolute;
  left: 17px;
  top: 13px;
}

.add-candidate-button {
  background-color: #f38d00;
  color: #fff;
  padding: 7px;
  width: 11rem;
  border-radius: 50px;
  border: none;
  height: 2.6rem;
  margin: 15px;
}

.candidate-container {
  margin: 0 40px;
  /* Margin on the sides of the table */
}

.candidate-table {
  width: 100%;
  margin-top: 0.5rem;
}

.candidate-table th,
.candidate-table td {
  padding: 12px;
  text-align: left;
}

.candidate-table th:first-child {
  border-top-left-radius: 15px;
}

.candidate-table th:last-child {
  border-top-right-radius: 15px;
}

.candidate-table th {
  background-color: #011e39;
  color: #f3efef;
}

.candidate-table tr {
  border-bottom: 1px solid #ddd;
}

.candidate-table td {
  background-color: #fff;
}

.pagination-controls {
  padding-top: 1rem;
  margin-top: 2rem;
}

.no-data {
  text-align: center;
  padding: 20px;
  font-style: italic;
  color: #888;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 40%;
  height: auto;
  /* Change height to auto for better responsiveness */
  padding-top: 2.1rem;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-form {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.form-column {
  flex: 1;
  margin-right: 10px;
}

.modal-form input {
  width: 97%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 3px;
}

.errorprofile {
  color: red;
  font-size: 0.7em;
}

.form-actions-add-candidate button {
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  background-color: #f38d00;
  color: #fff;
  cursor: pointer;
  width: 6.3rem;
  margin-top: 1.5rem;
}

.form-actions button:last-child {
  background-color: #1788eb;
}

#candidatephone {
  width: 48%;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .candidate-header-right {
    flex-direction: column;
    align-items: flex-start;
  }

  .candidate-search-input-field {
    width: 100%;
    /* Full width on smaller screens */
    margin-bottom: 10px;
    /* Space below the input */
  }

  .add-candidate-button {
    width: 100%;
    /* Full width on smaller screens */
  }

  .candidate-container {
    margin: 0 20px;
    /* Reduce side margin */
  }

  .modal-content {
    width: 90%;
    /* Full width for modal */
  }

  .form-row {
    flex-direction: column;
    /* Stack form fields vertically */
    margin-bottom: 15px;
    /* More space between fields */
  }

  .form-column {
    margin-right: 0;
    /* No right margin */
    margin-bottom: 10px;
    /* Space below each input */
  }
}

@media (max-width: 480px) {

  .candidate-table th,
  .candidate-table td {
    padding: 8px;
    /* Smaller padding for mobile */
    font-size: 14px;
    /* Smaller font size */
  }

  .add-candidate-button {
    padding: 6px;
    /* Smaller padding */
  }

  .modal-content {
    padding: 20px;
    /* Less padding */
  }

  .modal-form input {
    padding: 8px;
    /* Smaller input padding */
  }
}

/* profile css */

.clg-profile-creation {
  padding: 30px;
  display: flex;
  height: 80vh;
}

.clg-profile-creation-left-section {
  width: 75%;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  padding: 2rem 6rem;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: fit-content;
}

.clg-profile-creation-right-section {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding-left: 20px;
  box-sizing: border-box;
}

.clg-profile-creation-top-right,
.clg-profile-creation-bottom-right {
  flex: 1;
  background-color: #fff;
  margin-bottom: 10px;
  /* Space between top and bottom sections */
  padding: 20px;
  box-sizing: border-box;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Remove margin-bottom from the bottom-right section */
.clg-profile-creation-bottom-right {
  margin-bottom: 0;
}

.profile-section-sometext {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: self-start;
  padding-left: 31px;
}

/* Profile Section Styles */
.clg-profile-creation-profile-section {
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
}

.clg-profile-creation-profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

/* 
.clg-profile-creation-edit-profile-button {
  background-color: #f38d00; 
  color: #fff; 
  padding: 10px 15px; 
  border: none; 
  border-radius: 25px; 
  cursor: pointer; 
  transition: background-color 0.3s; 
}

.clg-profile-creation-edit-profile-button:hover {
  background-color: #e07b00; 
} */

.clg-profile-creation-inputs {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.clg-profile-creation-left-inputs,
.clg-profile-creation-right-inputs {
  width: 48%;
  display: inline-block;
  gap: 11px;
}

.clg-profile-input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 0.7px solid #9C9C9C;
  border-radius: 10px;
}

.placement-cell-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.placement-cell-inputs input {
  flex-grow: 0 !important;
  flex: 1 1 calc(50% - 10px);
  min-width: 200px;
}

/* Adjusting Profile Section */
.clg-profile-creation-profile-section {
  display: flex;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  /* Rounded corners */
  margin-bottom: 20px;
}

.profile-creation-save-continue-button {
  background-color: #f38d00;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 1.3rem;
}

.black-twiter-icon {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Header Style */
.cpcbr-header {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.cpcbr-header img {
  float: right;
}

/* Social Media Body Styling */
.cpcbr-body {
  display: flex;
  flex-direction: column;
}

.black-instagram-icon,
.black-twiter-icon,
.black-linkedin-icon {
  display: grid;
  grid-template-areas:
    "img p"
    "img a";
  grid-template-columns: 40px 80px;
  grid-template-rows: 20px 20px;
  align-items: center;
}

.social-icon {
  grid-area: img;
  object-fit: cover;
}

.black-instagram-icon p,
.black-twiter-icon p,
.black-linkedin-icon p {
  grid-area: p;
  font-size: 1rem;
  font-weight: 600;
}

.black-instagram-icon a,
.black-twiter-icon a,
.black-linkedin-icon a {
  grid-area: a;
  color: #f38d00;
  text-decoration: none;
}

.black-instagram-icon a:hover,
.black-twiter-icon a:hover,
.black-linkedin-icon a:hover {
  text-decoration: underline;
}

.clg-profile-navigation {
  border-bottom: 1px solid #d8d8d8;
  width: 95%;
  margin: auto;
  padding-top: 3rem;
  padding-bottom: 10px;
  margin-right: 3rem;
}

.clg-profile-new-navigate {
  width: 80%;
  justify-content: space-between;
}

.clg-profile-navigation button {
  background: none;
  border: none;
}

.clg-profile-navigation button:hover {
  cursor: pointer;
  color: #f38d00;
}

.clg_active_button {
  color: #f38d00;
}

.clg-profile-creation-edit-profile-button {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.clg-custom-file-upload {
  padding: 8px 15px;
  color: #fff;
  background-color: #f38d00;
  border-radius: 30px;
  cursor: pointer;
  width: 10rem;
  margin-top: 10px;
}

.clg-custom-file-upload:hover {
  background-color: #f38d00;
}

.placement-cell-student-information-section {
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  padding: 2rem 6rem;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: fit-content;
  margin-bottom: 20px;
}

.placement-cell-profile-creation-save-button {
  background-color: #f38d00;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 1.3rem;
  width: 8rem;
}

.campus_manager_input_div {
  flex-grow: 0 !important;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(50% - 10px);
  min-width: 200px;
}

.clg-remove-button-profile {
  color: #f38d00;
  background: white;
  border: 1px solid #f38d00;
}

.clg-remove-button-profile:hover{
  background-color: #f38d00;
  color: white;
}

.new_error_profile{
  position: absolute;
  top: 60px;
}