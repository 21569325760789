/* General Styles */
.setting_main_container {
  margin-top: 9rem;
}

.setting_sec {
  margin: 9rem auto;
  width: 63%;
  border-radius: 14px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 8px;
}

.setting_act-container {
  /* Responsive styles will be added */
}

.setting_act_set {
  border-bottom: 1px solid rgb(202, 198, 198);
}

.setting_act_set h5 {
  padding: 14px;
  width: 192px;
  height: 50px;
}

.setting_main_div {
  display: block;
  margin: auto;
}

.setting_Bas_Div {
  width: 100%;
  /* Change width to full for responsiveness */
  max-width: 414px;
  margin-left: 15px;
  margin-top: 30px;
}

.setting_sqbox {
  margin-top: 10px;
  height: auto;
  /* background-color: red; */
}

.setting_sqbox-1 {
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  /* background-color: rgb(226, 226, 226); */
  background-color: #F2F2F2;
  margin: 18px;
  padding: 17px;
  border-radius: 20px;
}

.setting_info {
  margin-bottom: 20px;
}

.setting_textarea {
  margin-bottom: 20px;
  width: 63%;
  /* Full width for responsiveness */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  background-color: #f9f9f9;
  color: #333;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s ease;
}

.setting_btn {
  display: flex;
  padding: 0px;
}

.setting_email_register {
  display: grid !important;
  place-items: center !important;
}

.setting_custom-btn {
  width: 102px;
  height: 40px;
  color: #fff;
  border-radius: 29px;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
}

.setting_btn-1 {
  background: #f38d00;
  border: none;
  margin-bottom: 5px;
}



.setting_btn-2 {
  color: #f38d00;
  border: 1px solid #f38d00;
  width: 90px;
  margin-left: 20px;
}

.setting_otp_btn {
  width: 124px;
}

.setting_new_pass {
  display: flex;
  flex-wrap: wrap;
  width: 63%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.setting_new_pass_inner_div {
  width: 49%;
}

.setting_pass {
  width: 100%;
  max-width: 340px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  background-color: #f9f9f9;
  color: #333;
  box-sizing: border-box;
  outline: none;
  /* transition: border-color 0.3s ease; */
  margin-bottom: 10px;
}

.setting_Notification {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.setting_switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.setting_switch input {
  display: none;
}

.setting_slider {
  background-color: #ccc;
  border-radius: 34px;
  cursor: pointer;
  transition: 0.4s;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.setting_slider:before {
  background-color: #fff;
  position: absolute;
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  transition: 0.4s;
  border-radius: 50%;
}

.setting_toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.setting_toggle__input {
  display: none;
}

.setting_toggle__label {
  display: block;
  width: 100%;
  height: 100%;
  background-color: orange;
  /* Changed to orange as per your preference */
  border-radius: 99px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
}

/* The circle inside the toggle */
.setting_toggle__label::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: left 0.3s ease;
}

/* Change the position of the circle when checked */
.setting_toggle__input:checked+.setting_toggle__label::after {
  left: 32px;
}

/* Optional: Change the background color when checked */
.setting_toggle__input:checked+.setting_toggle__label {
  background-color: orange;
}


/* Responsive Styles */
@media (max-width: 768px) {
  .setting_sec {
    width: 90%;
    margin: 5rem auto;
  }

  .setting_Bas_Div {
    width: 90%;
    /* margin-left: 0; */
    margin: auto;
    padding-top: 1rem;
  }

  .setting_textarea {
    width: 100%;
    /* Full width for mobile */
  }

  .setting_btn-2 {
    margin-left: 0;
    margin-top: 10px;
  }

  .setting_new_pass {
    flex-direction: column;
    width: 100%;
  }

  .setting_pass {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .setting_sec {
    width: 92%;
    margin: 6rem auto;
    padding: 5px;
  }

  /* .setting_custom-btn {
    width: 100%;
  } */

  .setting_Notification {
    flex-direction: column;
    gap: 10px;
  }

  /* .setting_otp_btn {
    width: 100%;
  } */

  .setting_btn {
    display: flex;
    padding: 0px;
    align-items: baseline;
    gap: 12px;
  }

  .setting_new_pass_inner_div{
    width: 100%;
  }
}