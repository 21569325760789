.dashboard_request_list {
  margin-bottom: 16px;
}

.dashboard_request_list_card {
  box-sizing: border-box;
  padding: 25px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard_request_list_card h4 {
  font-family: "Poppins" !important;
  font-size: 20px;
  font-weight: 500;
  line-height: 21px;
}

.dashboard_request_list_card p {
  font-family: "Poppins" !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.dashboard_request_actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 22px;
}

.dashborad_request_logo_title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.dashboard_request_company_logo {
  height: 55px;
  width: 61px;
  padding: 6px;
  background: #ffffff;
  border: rgb(194, 194, 194) 2px solid;
  border-radius: 11px;
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown_content_actions {
  position: absolute;
  margin-top: 7px;
  background-color: white;
  min-width: 134px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  right: -21px;
}

.dropdown_content_actions a {
  color: black;
  padding: 7px 16px;
  text-decoration: none;
  display: block;
}

.dropdown_content_actions a:hover {
  background-color: #f1f1f1;
}

.show_active {
  display: block;
}

.show_inactive {
  display: none;
}

.modal_admin_request .modal-content {
  background: #fff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: auto;
  padding-top: 2.1rem;
  align-items: self-start;
}

.modal_admin_request .admin_dashboard_modal_form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.modal_admin_request .admin_dashboard_modal_field {
  flex-basis: 48.5%;
}

.modal_admin_request .modal-footer {
  border-top: unset;
  padding: 0 14px 14px 14px;
  justify-content: flex-start;
}

.modal_admin_request .form-control {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.dashboard_request_actions_button_modal {
  display: flex;
  justify-content: center;
  gap: 13px;
}

.dashborad_request_approve_button {
  background-color: #f38d00;
  color: #fff;
  border: none;
  padding: 6px 18px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 700;
  transition: background-color 0.3s ease;
  width: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard_request_reject_button {
  background-color: #fff;
  color: #f38d00;
  border: 2px #f38d00 solid;
  padding: 6px 18px;
  border-radius: 20px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add_college_managment {
  align-items: center;
  background-color: #f38d00;
  border-radius: 2rem;
  color: #f4f6fc;
  display: flex;
  padding: 0.5rem 1.5rem;
  background-color: #f38d00;
  border: 1px solid #f38d00;
  color: #fff;
}

.search_input_container {
  display: flex;
  gap: 16px;
}

.campuse_student_request_search_bar {
  background: white;
  border-radius: 27px;
  padding: 10px 24px;
  display: flex;
  gap: 13px;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  align-items: center;
  outline: none;
}

.campuse_student_request_search_bar input {
  border: none;
}

.error_message_form {
  font-size: x-small;
  color: red;
  float: left;
  font-size: 10px;
}

.dropdown-list::-webkit-scrollbar {
  width: 5px;
}

.dropdown-list::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.dropdown-list::-webkit-scrollbar-thumb {
  background: #f38e00d0;
  border-radius: 5px;
}

.dropdown-list::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.dropdown-list {
  list-style-type: none;
  padding: 0;
  margin-top: 1px;
  background-color: white;
  border: 1px solid #ddd;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  width: 46%;
}

.dropdown-list li {
  cursor: pointer;
  list-style: none;
  padding: 10px;
  font-size: 12px;
}

.dropdown-list li:hover {
  background-color: #f1f1f1;
}

.popup_form_container {
  position: relative;
}

.college_popup_loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  border-radius: 14px;
  background: gray;
  height: 100%;
  opacity: 0.3;
  width: 100%;
}

/* -----------college details page ------- */
.college_detail_page_container {
  height: auto;
  width: 100%;
  margin-top: 2rem;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 22px;
}

.detail_dot_container {
  text-align: end;
  padding-right: 8px;
}

.detail_active_dot {
  position: absolute;
  height: 10px;
  width: 10px;
  background: green;
  border-radius: 50%;
}

.detail_deactivate_dot {
  position: absolute;
  height: 10px;
  width: 10px;
  background: rgb(211, 3, 3);
  border-radius: 50%;
}

.college_detail_page_inner_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashborad_detail_page_logo_title_container {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 36%;
  border-right: 1px solid #c0c0c0;
}

.dashboard_college_detail_logo {
  height: 75px;
  width: 93px;
  padding: 1px;
  background: #ffffff;
  border: rgb(194, 194, 194) 1px solid;
  border-radius: 11px;
}

.dashborad_detail_page_logo_title_container h4 {
  font-size: 22px;
}

.dashborad_detail_page_college_detail_container {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashborad_detail_page_college_detail_container h5 {
  margin: 0;
}

.detail_right_border {
  border-right: 1px solid #c0c0c0;
  height: 40px;
}

.dropdown_content_actions:after {
  content: "";
  position: absolute;
  top: 0;
  left: 86%;
  margin-left: -15px;
  margin-top: -8px;
  width: 0;
  z-index: 1;
  height: 0;
  border-bottom: solid 9px #ffffff;
  border-left: solid 9px transparent;
  border-right: solid 9px transparent;
}

.details_form_view_and_edit_container {
  margin-top: 45px;
}

.details_form_edit_inner_container {
  height: auto;
  margin-top: 1.5rem;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 32px;
}

.admin_dashboard_detail_form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 22px;
}

.admin_dashboard_detail_form .form_detail_field {
  flex-basis: 45.5%;
}

.dashboard_request_ad_course {
  background-color: #fff;
  color: #f38d00;
  border: 2px #f38d00 solid;
  padding: 6px 18px;
  border-radius: 20px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin_dashboard_detail_form .form-control {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #d9d9d9;
  border-radius: 11px;
}

.detail_page_courses_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.detail_page_course_inner_container {
  flex-basis: 49%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 38px;
}

.detail_page_course_inner_container p {
  font-size: 16px;
}

/* --------- student------------ */
.student_page_selected_student,
.student_page_selected_student select {
  background-color: white;
  padding: 0.1rem 0.8rem;
  border: none;
  outline: none;
  border-radius: 30px;
  border: none;
  appearance: none;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.request_select_menu_dropdown {
  width: 178px;
  position: relative;
}

.select_menu_dropdown {
  width: 170px;
  position: relative;
}

.select_btn_dropdown {
  display: flex;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 11px 24px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 50px;
  align-items: center;
  cursor: pointer;
  gap: 15px;
}

.select_dropdown_options_list {
  position: absolute;
  overflow-y: auto;
  padding: 10px 34px;
  width: inherit;
  margin-top: 3px;
  border-radius: 18px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 0.35s forwards;
  z-index: 10;
}

.select_dropdown_options_list .select_dropdown_option {
  display: flex;
  /* height: 55px; */
  cursor: pointer;
  padding: 5px 0px;
  border-radius: 8px;
  align-items: center;
  background: #fff;
  transition: background 0.3s;
}

.select_active .select_btn_dropdown i {
  transform: rotate(-180deg);
}

@keyframes fadeInUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.student_dashboard_container {
  width: 100%;
  height: auto;
  padding: 1.5rem 0;
}

.studente_container th,
td {
  padding: 18px !important;
  font-size: 14px;
}
.studente_container {
  margin: 0;
}
