.view_candidate_application_container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 2rem;
  margin: 2rem auto;
  min-height: 700px;
  max-width: 1200px;
}

.view_candidate_application_process_container {
  background-color: #fff;
  width: 70%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding-bottom: 1rem;
  min-height: 700px;
  display: flex;
  flex-direction: column;
}

.view_candidate_application_profile_sub_details {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 30%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  gap: 0.5rem;
  padding: 1rem;
  min-height: 700px;
  flex: 1;
}

.view_candidate_application_profile_first {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  height: 20%;
  align-items: center;
  border-radius: 20px;
}

.view_candidate_application_profile_first .name_and_designation {
  display: grid;
  place-content: center;
}

.view_candidate_application_profile_second {
  background-color: #fff5e8;
  height: 20%;
  border-radius: 20px;
}

.view_candidate_application_profile_second .job_type {
  display: flex;
  justify-content: space-between;
  padding: 13px 12px 0;
  font-size: 12px;
}

.job_category_title {
  padding: 0 0 10px 18px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.job_category_title .category {
  font-size: 15px;
}

.job_category_title .title {
  font-size: 12px;
  padding-bottom: 10px;
}

.view_candidate_application_profile_second #applied_jobs {
  font-size: 14px;
  font-weight: 500;
}

.view_candidate_application_profile_third {
  background-color: #fff5e8;
  height: 60%;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 1rem;
  gap: 1rem;
}

.social-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  align-items: center;
  border-radius: 10px;
}

.icon-social {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.social-details-container a {
  font-size: 12px;
}

/* application tabs */

.application_tab {
  display: flex;
  list-style: none;
  justify-content: space-around;
  align-items: center;
  padding: 0.8rem 1rem 0rem 1rem;
}

.application_tab p {
  cursor: pointer;
  font-weight: 600;
}

/* application profile  */
/* Application Profile Styles */

.candidate_application-profile {
  color: #333;
}

.candidate_personal-info-section,
.candidate_professional_info_section {
  padding: 0 1.5rem;
}

.candidate_personal-info {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}

.candidate_personal-info div {
  flex: 0 1 calc(50% - 0.75rem);
  min-width: 200px;
}

.personal_info {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 8px;
  display: inline-block;
}

.candidate_about_me p {
  margin-bottom: 1rem;
  line-height: 1.5;
  font-size: 0.9rem;
  color: #666;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 6;
}

.candidate_professional_details {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}

.detail-item {
  flex: 0 1 calc(50% - 1rem);
  min-width: 200px;
}

.detail-item p {
  margin-bottom: 0.5rem;
}

.detail-item p:first-of-type {
  font-weight: 500;
  color: #333;
  font-size: 13.5px;
}

.detail-item p:last-of-type {
  color: #666;
}

.application_tab p {
  cursor: pointer;
}

.application_tab span.active {
  border-bottom: 2px solid #f38d00;
  color: #f38d00;
  border-radius: 3px;
  padding: 0 5px;
}

/* resume  */

.hr_application_resume_container * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

.download-button {
  background-color: #f38d00;
  font-weight: 600;
  width: fit-content;
  font-size: 14px;
  float: right;
}

.hr_application_resume_body {
  padding: 0rem 1.5rem;
}

.hr_application_resume_wrapper {
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hr_application_resume_header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: space-between;
}

.hr_application_resume_profile {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.hr_application_resume_profile_image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.hr_application_resume_profile_info {
  display: flex;
  flex-direction: column;
}

.hr_application_resume_profile_name {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.hr_application_resume_profile_title {
  font-size: 1.2rem;
  color: #666;
  font-weight: normal;
}

.hr_application_resume_download_button {
  background-color: transparent;
  color: #ff9900;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.hr_application_resume_main_content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
}

.hr_application_resume_section {
  margin-bottom: 2rem;
}

.hr_application_resume_section_title {
  text-transform: uppercase;
  color: #666;
  font-size: 0.9rem;
  letter-spacing: 1px;
  margin-bottom: 1rem;
}

.hr_application_resume_experience_item {
  margin-bottom: 1.5rem;
}

.hr_application_resume_experience_role {
  font-size: 1.1rem;
  margin-bottom: 0.25rem;
  color: #333;
}

.hr_application_resume_experience_company {
  font-size: 1rem;
  color: #666;
  font-weight: normal;
  margin-bottom: 0.25rem;
}

.hr_application_resume_experience_date {
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 0.5rem;
}

.hr_application_resume_experience_description {
  font-size: 0.95rem;
  color: #555;
  line-height: 1.5;
}

.hr_application_resume_skills_list {
  list-style: none;
}

.hr_application_resume_skills_item {
  color: #555;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

@media (max-width: 768px) {
  .hr_application_resume_main_content {
    grid-template-columns: 1fr;
  }

  .hr_application_resume_header {
    flex-direction: column;
    text-align: center;
  }

  .hr_application_resume_profile {
    flex-direction: column;
    text-align: center;
  }
}

.hiring_process_btn_status button:hover {
  background-color: #f38d00;
  color: #fff;
}

.hiring_process_interview_dropdown {
  position: relative;
}

.hiring_process_interview {
  border: 1.2px solid #f38d00 !important;
  color: #fff !important;
  background-color: #f38d00 !important;
}

.hiring_process_dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 15px;
  z-index: 1000;
  margin-top: 5px;
}

.hiring_process_dropdown_option {
  padding: 10px 23px;
  cursor: pointer;
}

.hiring_process_dropdown_option:hover {
  background-color: #f0f0f0;
}

.interview_schedule_form {
  padding: 20px;
  padding: 3rem;
  display: flex;
  margin: auto;
  flex-direction: column;
  /* Keeps the overall layout vertical */
  justify-content: center;
  align-items: center;
  gap: 30px;
  background-color: #fff;
  box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px,
    rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.04) 0px 6px 6px -3px,
    rgba(14, 63, 126, 0.04) 0px 12px 12px -6px,
    rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
  border-radius: 8px;
}

.interview_schedule_form h2 {
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
  font-family: "Poppins", serif;
  color: #333;
}

.interview_schedule_form div {
  display: flex;
  /* Enables flexbox for label and input */
  justify-content: space-between;
  /* Spaces out the label and input */
  width: 100%;
  /* Full width of the container */
  font-family: "Poppins", serif;
}

.interview_schedule_form label {
  font-weight: bold;
  font-family: "Poppins", serif;
  color: #555;
  margin-bottom: 5px;
  flex: 1;
  /* Allow label to take equal space */
}

.interview_schedule_form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 15px;
  font-size: 16px;
  transition: border-color 0.3s;
  flex: 1;
  /* Allow input to take equal space */
  margin-left: 10px;
  /* Space between label and input */
}

.interview_schedule_form input:focus {
  border-color: #f38d00;
  outline: none;
}

.interview_schedule_form span {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.interview_schedule_btn {
  font-family: "Poppins", serif;
  padding: 10px 16px;
  border: none;
  border-radius: 20px;
  background-color: #f38d00;
  color: white;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: flex-start;
  /* Aligns buttons to the start */
}

/* hiring process */
.hiring_process_container {
  /* height: 100%; */

  padding: 10px 30px 30px;
  width: 100%;
}

.hiring_process_heading {
  width: fit-content;
}

.hiring_process_btn_status {
  display: flex;
  gap: 0.8rem;
  margin-top: 1.2rem;
}

.hiring_process_btn_status button,
.offer_letter_upload_btn {
  border-radius: 30px;
  border: 1.2px solid #f38d00;
  padding: 6px 10px;
  color: #f38d00;
  background-color: #fff;
  font-weight: 500;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
}

.hiring_process_btn_status button.active {
  background-color: #f38d00;
  color: #fff;
  font-weight: bold;
}

.hiring_process_btn_status button:disabled {
  cursor: not-allowed;
  background-color: #e7e5e5;
}

.hr_schedule_interview_form {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.interview_schedule_details_lateral_container {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  align-items: center;
  padding: 0.5rem;
  border-radius: 15px;
  border: 2px solid #ccc;
}

.interview_schedule_details_lateral_candidate_details {
  display: flex;
  gap: 15px;
  align-items: center;
}

.interview_schedule_lateral_link,
.interview_schedule_lateral_date {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}

.copy-link-button {
  background-color: #e7e5e5;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.copy-link-button:hover {
  background-color: #fff;
}

.interview_schedule_lateral_link_copy {
  padding: 5px 8px;
  border-radius: 8px;
  border: 2px solid #f38d00;
  color: white;
}

.interview_schedule_lateral_link_copy a {
  text-decoration: none;
}

.tooltip {
  position: absolute;
  background-color: #000;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  bottom: 100%;
  /* Position above the button/link */
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 5px;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.interview_schedule_lateral_link_copy {
  position: relative;
  display: inline-block;
}

.copy-link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.copy-link-button img {
  width: 30px;
  height: 30px;
}

/* resume  */

.pdf-viewer-container {
  width: 100%;
  height: auto;
  text-align: center;
}

.pagination-controls {
  margin-top: 20px;
  text-align: center;
}

.pagination-controls button {
  padding: 10px;
  margin: 0 5px;
}

.pagination-controls span {
  font-size: 14px;
  font-weight: bold;
  margin: 0 10px;
}

.download-button {
  padding: 10px 20px;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
  display: inline-block;
}

.offer_letter_upload_heading {
  padding-bottom: 0.8rem;
}

.offer_letter_upload_container {
  background-color: #f5f5f5;
  width: 95%;
  border-radius: 25px;
  margin: auto;
  padding: 1rem 1.8rem;
}

.offer_letter_upload_button {
  background-color: #f38d00;
  border-radius: 30px;
  padding: 3px 10px;
  color: #fff;
  font-weight: 600;
  border: 1.5px #ff9900 solid;
}

.offer-letter-label {
  color: #fff;
  background-color: #888;
  padding: 5px 8px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 8px;
}

.offer-letter-input {
  display: none;
}

.status-message {
  text-align: center;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  margin: 20px 0;
}

.status-message span[role="img"] {
  font-size: 24px;
  margin-right: 10px;
}

.status-message h6 {
  margin: 10px 0;
  color: #666;
}

.pdf-preview-container {
  background-color: #f5f5f5;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.pdf-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.pdf-error {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
}

/* Resume Component Styles */
.hr_application_resume_body {
  height: calc(100vh - 200px);
  overflow: hidden;
}

.hr_application_resume_container {
  height: 100%;
  overflow: auto;
  padding: 20px;
  max-width: 100%;
}

/* Custom Scrollbar Styles */
.hr_application_resume_container::-webkit-scrollbar {
  width: 8px;
}

.hr_application_resume_container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.hr_application_resume_container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.hr_application_resume_container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* PDF Preview Container */
.pdf-preview-container {
  min-height: 70vh;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 2rem;
  overflow: auto;
}

.resume-action-button {
  padding: 10px 20px;
  color: #fff;
  background-color: #f38d00;
  border: none;
  border-radius: 5px;
}

.view-button {
  background-color: #1976d2;
  width: fit-content;
}

.view-button:hover {
  background-color: #1565c0;
}

.no-resume-message {
  text-align: center;
  padding: 2rem;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin: 1rem;
}

/* Loading and Error States */
.pdf-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

/* Message Text Styles */
.resume-message {
  margin-bottom: 2rem;
  color: #666;
  flex-shrink: 0;
}

/* Icon Styles */
.resume-icon {
  margin-bottom: 1rem;
  flex-shrink: 0;
}

.resume-actions {
  display: flex;
  gap: 1rem;
  height: auto;
  justify-content: center;
  flex-shrink: 0;
}

.schedule_interview_container {
  margin-top: 20px;
  text-align: center;
}

.schedule_interview_button {
  background-color: #1976d2;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.schedule_interview_drawer {
  padding: 24px;
  width: 400px;
}

.schedule_interview_drawer h3 {
  margin-bottom: 20px;
}

.form_group {
  margin-bottom: 15px;
}

.form_group label {
  display: block;
  margin-bottom: 5px;
}

.form_group input,
.form_group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}


.offer_letter_upload {
  display: flex;
  justify-content: start;
  align-items: center;
}

.custom-swal-popup {
  z-index: 9999999 !important;
  top: 10% !important;
}

.custom-confirm-button {
  background-color: orange !important;
  color: white !important;
  border: none !important;
  padding: 10px 20px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  border-radius: 5px !important;
}

.custom-confirm-button:hover {
  background-color: darkorange !important;
}

.css-1424xw8-MuiModal-root-MuiDialog-root{
  z-index: 999 !important;
}