.student_campus_interview_data {
  width: 100%;
  height: 100%;
}

.student_campus_interview_parent {
  padding-top: 20px;
  padding-left: 35px;
  padding-right: 35px;
}

.student_campus_job_interview {
  width: 100%;
  height: fit-content;
  top: 108px;
  left: 312px;
  gap: 0px;
  border-radius: 16px;
  /* opacity: 0px; */
  background-color: #ffffff;
  box-shadow: 0px 2px 4px 0px #00000026;
}

.student_campus_scrollBar_container {
  height: 293px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #F38D00 transparent;
}

.student_campus_scrollBar_container::-webkit-scrollbar {
  width: 4px !important;
}

.student_campus_scrollBar_container::-webkit-scrollbar-track {
  background: #F4F4F4;
  border-radius: 15px;
}

.student_campus_scrollBar_container::-webkit-scrollbar-thumb {
  background: #F38D00;
  border-radius: 15px;
  border: none;
}

.student_campus_scrollBar_container::-webkit-scrollbar-thumb:hover {
  background: #D17600;
}

.student_campus_scrollBar_container::-webkit-scrollbar-corner {
  background: transparent;
}

.student_campus_interview_border {
  border-bottom: 0.8px solid #c0c0c0;
  padding-top: 13px;
  padding-left: 30px;
}

.student_campus_interview_content {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.76px;
  text-align: left;
  color: #111111;
  display: flex;
  gap: 14px;
}

.student_campus_interview_span {
  font-size: 14px;
  font-weight: 300;
  line-height: 19.04px;
  text-align: left;
}

.student_campus_company_parent1 {
  width: 100%;
  top: 187px;
  left: 336px;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-right: 150px;
  border-bottom: 0.6px solid #c0c0c0;
}

.student_campus_no-border {
  border-bottom: none;
}

.student_campus_company_parent {
  width: 23%;
  top: 187px;
  left: 336px;
  gap: 10px;
  /* opacity: 0px; */
  display: flex;
}

.student_campus_company_padding {
  padding-left: 30px;
}

.student_campus_job_interviewcompany {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  text-align: left;
  color: #111111;
  white-space: nowrap;
}

.student_campus_job_interviewlocation {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #3c3c3c;
  margin-top: 5px;
}

.student_campus_job_interviewdate {
  width: fit-content;
  height: 21px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #111111;
  margin: 0;
  white-space: nowrap;
}

.student_campus_job_interviewday {
  width: fit-content;
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #3c3c3c;
  padding-top: 5px;
  white-space: nowrap;
}

.student_campus_job_interviewtime {
  height: 21px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #111111;
  margin: 0;
}

.student_campus_job_interviewampm {
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #3c3c3c;
  text-align: center;
  padding-top: 5px;
}

.student_campus_date_day {
  width: fit-content;
  height: 41px;
  top: 187px;
  left: 837.82px;
  gap: 2px;
}

.student_campus_time_am {
  width: fit-content;
  height: 41px;
  top: 187px;
  left: 1225.82px;
  gap: 2px;
}

.no-interviews-message {
  padding-top: 10rem;
  padding-left: 40%;
}

.student_campus_company_parent1:hover {
  background: #f38d0024;
  cursor: pointer;
  border-radius: 10px;
}


.interviewDetails-header {
  display: flex;
  align-items: center;
}

.interviewDetails-back-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 1rem;
}

.interviewDetails-company {
  font-size: 1.25rem;
  font-weight: bold;
}

.interviewDetails-rounds-container {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.interviewDetails-round-button {
  background-color: transparent;
  border: none;
  color: black;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s ease;
}

.interviewDetails-round-button.active {
  background-color: #4B5563;
  border-radius: 0.5rem 0.5rem 0 0;
}

.interviewDetails-table-container {
  margin-top: 1rem;
}

.interviewDetails-table {
  width: 100%;
  border-collapse: collapse;
}

.interviewDetails-table-header {
  background-color: #011E39;
  color: white;
}

.interviewDetails-table-cell {
  padding: 0.5rem 1rem;
  text-align: left;
  border-bottom: 1px solid #E5E7EB;
}

.interviewDetails-table-row:nth-child(even) {
  background-color: #F3F4F6;
}

.interviewDetails-table-row:hover {
  background-color: #E5E7EB;
}

.interviewDetails-action-button {
  background-color: #F59E0B;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  transition: background-color 0.3s ease;
}

.interviewDetails-action-button:hover {
  background-color: #D97706;
}

.student-round-active {
  color: #F38D00;
  border-bottom: 2px solid #f38d008f;
}

.student_interview_details_join_now {
  padding-top: 14px;
}

.student_interview_details_join_now button {
  background-color: #D97706;
  color: white;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.student_interview_details_join_now button:hover {
  transform: scale(1.2);
  background-color: #B45309; 
}

.student_disabled_tabs{
  cursor: not-allowed;
  color: #0000008a;
}