.campuses_main {
  padding: 172px 0;
  font-family: "Poppins", sans-serif;
}

.campuses_semi {
  width: 63%;
  margin: auto;
}

.campus_first_container {
  display: grid;
  place-items: center;
  text-align: center;
  width: 70%;
  margin: auto;
}

.campus_first_cont_first_input {
  display: flex;
  width: 56%;
}

.campus_first_cont_first h1 {
  font-size: 48px;
  font-weight: 600;
  color: #111111;
}

.campus_first_cont_first_input input {
  padding: 9px 5px 9px 25px;
  outline: none;
  border-radius: 10px 0px 0px 10px;
  border: 1px solid #3c3c3c78;
  width: 100%;
  white-space: nowrap;
  font-size: 15px;
}

.campus_first_cont_first_input button {
  background: #F38D00;
  width: 85px;
  border: none;
  border-radius: 0px 10px 10px 0px;
}

.campus_first_cont_sec {
  padding-top: 15px;
  padding-bottom: 30px;
}

.campus_first_cont_sec b {
  font-weight: 400;
  font-size: 16px;
}

.campus_second_container {
  box-shadow: 1px 1px 6px 0px #00000040;
  padding: 25px 23px;
  border-radius: 10px;
  display: grid;
  gap: 1rem;
  height: 235px;
  width: auto;
}

.campus_second_company_logo {
  padding: 10px;
  border: 1px solid #11111147;
  border-radius: 15px;
}

.campus_second_company_name b {
  font-weight: 500;
  font-size: 18px;
}

.custom-width-80 {
  width: 90%;
}

.campus_second_collages_details p {
  font-size: 13px;
}

.campus_second_collages_details b {
  font-size: 15px;
  font-weight: 600;
  color: #111111e3;
}
.campus_second_more_info a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  color: #F38D00;
}

.campus_second_company_logo img {
  width: 40px;
  height: 40px;
  top: 0;
}

.campus-location {
  font-size: 15px;
}

.campus_second_more_info a:hover {
  color: #0567c2;
}

/* .custom-pagination .MuiPaginationItem-root {
    font-size: 18px;
} */

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #fff;
  background-color: #F38D00 !important;
  padding: 16px !important;
  border-radius: 50% !important;
  font-size: 17px !important;
  height: 40px !important;
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root {
  font-size: 17px !important;
}

.css-wjh20t-MuiPagination-ul {
  gap: 12px !important;
}

.css-1v2lvtn-MuiPaginationItem-root {
  font-size: 21px !important;
}

.css-1omp2b7.Mui-selected {
  color: rgb(255, 255, 255);
  background-color: rgb(25, 118, 210);
  background-color: #F38D00 !important;
  padding: 16px !important;
  border-radius: 50% !important;
  font-size: 17px !important;
  height: 40px !important;
}

.css-1omp2b7 {
  font-size: 17px !important;
}

@media (max-width: 1650px) and (min-width: 1400px) {
  .campuses_semi {
    width: 80%;
  }

  .campus_first_container {
    width: 60%;
  }
}

@media (max-width: 1400px) and (min-width: 1200px) {
  .campuses_semi {
    width: 80%;
  }

  .campus_first_container {
    width: 65%;
  }

  .campus_second_company_logo {
    padding: 8px;
    border-radius: 10px;
  }

  .campus_second_company_name b {
    font-size: 16px;
  }

  .campus_second_collages_details p {
    font-size: 10px;
  }

  .campus_second_collages_details B {
    font-size: 13.5px;
  }

  .campus-location {
    font-size: 14px;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .campuses_semi {
    width: 80%;
  }

  .campus_first_container {
    width: 80%;
  }

  .campus_first_cont_first_input input::placeholder {
    font-size: 14px;
  }

  .campus_second_company_logo {
    padding: 8px;
    border-radius: 10px;
  }

  .campus_second_company_name b {
    font-size: 16px;
  }

  .campus_second_collages_details p {
    font-size: 10px;
  }

  .campus_second_collages_details B {
    font-size: 13.5px;
  }

  .campus-location {
    font-size: 13px;
  }

  .campus_second_more_info a {
    font-size: 12px;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .campuses_semi {
    width: 80%;
  }

  .campus_first_container {
    width: 80%;
  }

  .campus_first_cont_first_input input::placeholder {
    font-size: 14px;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .campuses_main {
    padding: 120px 0;
  }

  .campuses_semi {
    width: 80%;
  }

  .campus_first_cont_first_input input::placeholder {
    font-size: 14px;
  }

  .campus_first_container {
    width: 100%;
  }

  .campus_second_container {
    height: 250px;
    gap: 0.8rem;
  }

  .campus_second_company_logo {
    padding: 6px;
    border-radius: 8px;
  }

  .campus_second_company_name b {
    font-size: 14px;
  }

  .campus_second_collages_details p {
    font-size: 10px;
  }

  .campus_second_collages_details B {
    font-size: 13.5px;
  }

  .campus-location {
    font-size: 13px;
  }

  .campus_second_more_info a {
    font-size: 12px;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .campuses_main {
    padding: 70px 0;
  }

  .campuses_semi {
    width: 80%;
  }

  .campus_first_container {
    width: 100%;
  }

  .campus_second_container {
    height: 270px;
    gap: 0.8rem !important;
  }

  .campus_first_cont_first_input input::placeholder {
    font-size: 14px;
  }

  .campus_first_cont_first_input {
    width: 70%;
  }
}

@media (max-width: 576px) and (min-width: 180px) {
  .campuses_main {
    padding: 70px 0;
  }

  .campuses_semi {
    width: 80%;
  }

  .campus_first_container {
    width: 100%;
  }

  .campus_second_container {
    height: 320px;
    gap: 1rem;
  }

  .campus_first_cont_first_input {
    width: 90%;
  }

  .campus_first_cont_first_input input::placeholder {
    font-size: 12px;
  }

  .campus_first_cont_first h1 {
    font-size: 28px;
  }

  .campus_second_company_logo img {
    width: 25px;
    height: 25px;
  }

  .campus_second_company_name b {
    font-size: 12px;
  }

  .campus_first_cont_sec b {
    font-size: 13.5px;
  }

  .campus_second_collages_details p {
    font-size: 12px;
  }

  .campus_second_collages_details B {
    font-size: 12.5px;
  }

  .campus-location {
    font-size: 13px;
  }

  .campus_second_more_info a {
    font-size: 12px;
  }

  .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    padding: 12px !important;
    font-size: 12px !important;
    height: 28px !important;
  }

  .css-wjh20t-MuiPagination-ul {
    gap: 0px !important;
  }

  .campus-next-btn-pagination,
  .campus-prev-btn-pagination {
    height: 16px;
  }
}
