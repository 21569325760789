.student_chart_section {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

._recentadded_container {
  overflow-y: scroll;
  height: 100vh;
  margin: 0 auto;
  padding: 20px 20px 10px 20px;
}

._recentadded_container::-webkit-scrollbar {
  width: 2px;
}

._recentadded_container::-webkit-scrollbar-thumb {
  background-color: orange;
  border-radius: 10px;
}

._recentadded_container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

._recentadded_container {
  scrollbar-width: thin;
  scrollbar-color: orange #f1f1f1;
}

._main_content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;
  width: 100%;
  margin: auto;
}

.myApplication_top_header b {
  font-size: 16px;
  font-weight: 600;
  color: #111111;
}

.student__filter_shortlisted {
  padding: 7px 13px;
  border-radius: 20px;
  font-size: 12px;
}

.student_shortlisted_active {
  padding: 7px 13px;
  border-radius: 20px;
  font-size: 12px;
}

.student__filter_review {
  padding: 7px 13px;
  border-radius: 20px;
  font-size: 12px;
}

.student__filter_review_active {
  padding: 7px 13px;
  border-radius: 20px;
  font-size: 12px;
}

.student__filter_rejected {
  padding: 7px 13px;
  border-radius: 20px;
  font-size: 12px;
}

.student__filter_rejected_active {
  padding: 7px 13px;
  border-radius: 20px;
  font-size: 12px;
}

.student_application_item {
  padding: 15px 0px;
}

.student_application_details h3 {
  font-size: 14px;
  font-weight: 500;
}

.student_application_details p {
  margin: 2px 0 0;
  font-weight: 400;
  font-size: 13px;
}

.student_application_details h3 a{
  text-decoration: none;
  color: inherit;
}

.student_application_date p:first-child {
  font-size: 14px;
}

.student_application_date p {
  font-size: 11px;
  font-weight: 400;
}

.student_more_options {
  font-size: 27px;
}

.student_application_company_logo {
  width: 42px;
  height: 40px;
}

.student_more_options_dropdown {
  top: 23px;
  left: -120px;
}

.new_student_more_options_dropdown{
  left: -7rem !important;
}
.student_popularjobs_header {
  padding-right: 1rem;
}

.student_popularjobs_header b {
  font-weight: 600;
  font-size: 14px;
}

.student_myApplication_list {
  height: 286px;
  overflow-y: scroll;
}

.student_myApplication_list::-webkit-scrollbar {
  width: 3px;
}

.student_myApplication_list::-webkit-scrollbar-thumb {
  background-color: #FFC982;
  border-radius: 5px;
}

.student_myApplication_list::-webkit-scrollbar-track {
  background-color: transparent;
}


.student_recent_container {
  overflow-y: unset;
  height: inherit;
  scrollbar-width: unset;
  scrollbar-color: unset;
  padding-right: 0;
}

.student_popularJobs_list {
  overflow-y: scroll;
  height: 76vh;
  padding-right: 1rem;
  padding-top: 10px;
  padding-left: 5px;
}

.student_popularJobs_list::-webkit-scrollbar {
  width: 3px;
}

.student_popularJobs_list::-webkit-scrollbar-thumb {
  background-color: #FFC982;
  border-radius: 5px;
}

.student_popularJobs_list::-webkit-scrollbar-track {
  background-color: transparent;
}

.student_recentAdded_jobCard {
  padding: 15px 15px 5px 15px;
}

.student_applicationStatisticsContainer {
  height: 325px;
}

.no-application-history {
  padding-top: 7rem;
  padding-left: 40%;
}