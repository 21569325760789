.clg-setting-page {
  margin: 50px;
  background-color: #fff;
  height: 80vh;
  border-radius: 15px;
}

.clg-setting-page-header {
  border-bottom: 1px solid;
  padding: 20px;
}

.setting-password-section {
  padding: 20px;
  margin: 20px;
  border-radius: 15px;
  background-color: #f2f2f2;
}

.setting-section-text {
  margin: 20px;
}

.current-password-field input {
  width: 65%;
  padding: 10px;
  border-radius: 15px;
  border: none;
  margin-top: 20px;
  margin-bottom: 15px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.password-field {
  /* margin-bottom: 15px; 
   margin-top: 15px;  */
  width: 65%;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.password-field label {
  margin-bottom: 5px;
}

.password-field input {
  width: 65%;
  padding: 10px;
  border-radius: 15px;
  border: none;
  margin-bottom: 15px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.clg-setting-notification {
  padding: 20px;
  margin: 20px;
  border-radius: 15px;
  background-color: #f2f2f2;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.password-form-section button {
  background-color: #f38d00;
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 6px;
  width: 10%;
}

.clg-setting-notification-s {
  float: right;
  width: auto;
}

.error-message-setting{
  color: red;
  font-size: 11px;
  margin-top: -11px;
  padding-bottom: 10px;
}

/* .campus-current-password-field input{
  margin-bottom: 15px;
} */

.custom-swal-button {
  background-color: #f38d00 !important; /* Custom background color */
  color: #fff !important; /* Text color */
  border: none !important;
  border-radius: 4px !important;
  padding: 10px 20px !important;
  font-size: 16px !important;
  cursor: pointer !important;
}