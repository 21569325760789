.student_campus_profile_container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.5rem;
}

.student_campus_profile_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.student_campus_profile_title {
    font-size: 1.5rem;
    margin: 0;
}

.student_campus_profile_tabs {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0 0 1.5rem;
    border-bottom: 2px solid #dee2e6;
}

.student_campus_profile_tab_item {
    margin-bottom: -2px;
}

.student_campus_profile_tab_link {
    border: none;
    color: #111111;
    padding: 12px 20px;
    transition: all 0.3s ease;
    font-weight: 500;
    font-size: 15px;
    background: none;
    cursor: pointer;
}

.student_campus_profile_tab_link:hover {
    color: #F38D00;
}

.student_campus_profile_tab_link.active {
    color: #F38D00;
    border-bottom: 2px solid #F38D00;
}

.student_campus_profile_tab_link:focus {
    outline: none;
    box-shadow: none;
}

.student_campus_profile_card {
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 70%;
}

.student_campus_profile_card_body {
    padding: 1.5rem;
}

.student_campus_profile_card_body>div {
    animation: studentProfileFadeIn 0.3s ease-in;
}

.student_campus_profile_social {
    margin-top: 0;
    background-color: #fff;
    height: fit-content;
    border-radius: 1.3rem;
    box-shadow: 1px 1px 1px 1px #e7e7e7;
    padding: 20px 20px;
}

.student_campus_profile_social_title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

.student_campus_profile_social_icon {
    margin-left: 0.5rem;
    color: #ffc107;
}

.student_campus_profile_social_list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.student_campus_profile_social_item {
    padding: 12px 0;
    transition: all 0.3s ease;
}

.student_campus_profile_social_item:hover {
    background-color: #f8f9fa;
}

.student_campus_profile_social_link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.student_campus_profile_social_platform_icon {
    font-size: 1.2rem;
}

.student_campus_profile_social_platform_icon.bi-instagram {
    color: #E1306C;
}

.student_campus_profile_social_platform_icon.bi-twitter {
    color: #1DA1F2;
}

.student_campus_profile_social_platform_icon.bi-linkedin {
    color: #0077B5;
}

.student_campus_profile_social_url {
    color: #ffc107;
}

.student_campus_profile_social_item:hover .student_campus_profile_social_url {
    color: #e0a800;
}


.student_campus_canvas_container canvas {
    height: 190px !important;
    width: 190px !important;
    position: absolute;
    left: 5.2rem;
    top: 18px;
}

.student_campus_canvas_percentage_value {
    position: absolute;
    left: 9.5rem;
    top: 6rem;
    font-size: 25px;
    font-weight: 600;
}

.student_epf_profile_pic {
    width: 130px !important;
    height: 130px !important;
}

.student_epf_format_info {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.student_epf_header {
    width: 80%;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 1rem !important;
}

.student_epf_details_button {
    top: -20px !important;
}

.student_my_profile_summary {
    width: 96%;
    margin: auto;
}

.student_my_profile_summary p {
    padding-bottom: 4px;
}

.student_my_profile_summary p b {
    font-weight: 400;
}

.student_my_profile_summary textarea {
    font-size: 14px;
}

.student_submitted_data_contianer b {
    font-weight: 500;
    font-size: 16px;
}

.student_company_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.new_student_campus_profile_media {
    height: fit-content !important;
}

.student_epf_input_fields_new {
    padding: 6px 7px !important;
}

.campus_student_sites_icon {
    width: 100% !important;
    margin-bottom: 20px !important;
}

.student_epf_submit_bttn {
    width: fit-content;
    background: rgba(243, 141, 0, 1);
    border: none;
    border-radius: 16px;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 600;
    font-size: 16px;
    margin-top: 15px;
    color: white;
}

.dropdown-with-spacing {
    padding-right: 2rem;
    background: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJjdXJyZW50Q29sb3IiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik00LjA2NiA1LjU5NmEuNzQuNzQgMCAwIDEgMS4wNjQgMGwzLjkwNiA0LjIxNmEuNzQuNzQgMCAwIDEtLjUzLjEyN0w0LjU0NSA1LjcybDMuMDYtMy4zMjZMMTEuNDIgNC41OGEuNzQuNzQgMCAxIDEgMS4wNiAwbC0zLjkwNiA0LjIxNmEuNzQuNzQgMCAwIDEtMS4wNiAwTDQuMDY2IDUuNTk2eiIgZmlsbD0iIzQ0NCIvPjwvc3ZnPg==") no-repeat right center;
    background-size: 12px;
}

.progress-bar {
    height: 5px;
    margin-top: 10px;
    border-radius: 3px;
}

.bg_green_500 {
    background-color: #f44336;
}

.bg_red_500 {
    background-color: #4caf50;
}

@keyframes studentProfileFadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .student_campus_profile_tab_link {
        padding: 8px 12px;
        font-size: 14px;
    }

    .student_campus_profile_title {
        font-size: 1.25rem;
    }

    .student_campus_profile_progress {
        height: 6px;
    }
}