.hr_settings_container {
  padding: 20px;
  font-family: "Poppins", sans-serif;
  background-color: #fff;
  border-radius: 10px;
}

.hr_settings_headings h6 {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 10px;
}

.hr_settings_container hr {
  border: none;
  border-top: 1px solid #e0e0e0; /* Light gray separator line */
  margin: 15px 0;
}

/* Section headings */
.hr_settings_main_sections_heading {
  margin-bottom: 20px;
}

.hr_settings_basic_info_container h6,
.hr_settings_basic_form_heading h6,
.notifications_heading h6 {
  font-size: 18px;
  font-weight: 400;
  color: #333;
}

.hr_settings_basic_info_container p,
.hr_settings_basic_form_heading p,
.notifications_heading p {
  font-size: 14px;
  color: #353232;
  margin-bottom: 15px;
}

/* Form styling */
.hr_settings_basic_form_container {
  background-color: #f1f3f6;
  padding: 20px;
  border-radius: 10px;
}

.hr_settings_basic_form_details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* flex-wrap: wrap; */
}

.hr_settings_basic_form_details input {
  width: 100%;
  max-width: 48%; /* Two columns on larger screens */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
}

.hr_settings_basic_form_details input:focus {
  border-color: #f97316; /* Highlight on focus */
  outline: none;
}

.hr_settings_form_submit_btn {
  padding: 0.8rem 0rem;
}

.hr_settings_form_submit_btn button {
  background-color: #f97316;
  padding: 6px 18px;
  border: none;
  border-radius: 16px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hr_settings_form_submit_btn button:hover {
  background-color: #f38d00;
}
/* notifications */

.hr_notifications {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f1f3f6;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.hr_notification_one {
  display: flex;
  flex-direction: column;
}

.hr_notification_one button {
  width: 40px;
  height: 20px;
  background-color: #dbadad;
  border: none;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hr_notification_one button::after {
  content: "";
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease;
}

.hr_notification_one button.active {
  background-color: #f38d00;
}

.hr_notification_one button.active::after {
  transform: translateX(20px);
}

.checkbox-wrapper .tgl {
  display: none;
}

.checkbox-wrapper .tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  background: #9c7575;
  border-radius: 2em;
  padding: 0px;
  transition: all 0.4s ease;
}

.checkbox-wrapper .tgl + .tgl-btn:after {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  background: #fff;
  border-radius: 50%;
  transition: all 0.2s ease;
  left: 0;
}

.checkbox-wrapper .tgl:checked + .tgl-btn {
  background: #dc690f; /* Toggle on color */
}

.checkbox-wrapper .tgl:checked + .tgl-btn:after {
  left: 50%; /* Move circle to the right when checked */
}

.hr_settings_basic_form_details input.error {
  border-color: red;
  text-align: left;
}

.input_group {
  display: flex;
  flex-direction: column;
}

.error_message {
  color: #dc3545;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}

input.error {
  border-color: #dc3545;
}
