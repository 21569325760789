.register_user_container {
  background: rgb(242, 242, 242);
  background: radial-gradient(
    circle,
    rgba(242, 242, 242, 1) 0%,
    rgba(244, 246, 252, 1) 85%,
    rgba(242, 242, 242, 1) 100%
  );
  width: 100%;
  min-height: 100vh;
}

.register_main_parent {
  background: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
  width: 63%;
  margin: auto;
}

.register_user_main {
  width: fit-content;
  margin: auto;
  display: flex;
  align-items: center;
  height: 800px;
  width: 1160px;
}

.register_background_first {
  background: url("../../utils/landingpage/images/authBG.svg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  width: 45%;
}

.register_background_first_inner {
  margin: auto;
  text-align: center;
  display: grid;
  gap: 1.8rem;
}

.register_content_second {
  width: 55%;
  background-color: #fff;
  height: inherit;
  border-top-right-radius: 2.5rem;
  border-bottom-right-radius: 2.5rem;
}

.register_content_second_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10%;
  justify-content: center;
  height: 100%;
  font-family: "Poppins", sans-serif;
}

.register_background_heading h1 {
  color: #fff;
}

.register_background_heading p {
  color: #fff;
  padding: 0rem 5rem;
}

.register_heading {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  color: #f38d00;
  padding-top: 1rem;
}

.register_header_line {
  height: 2px;
  width: 30%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(243, 141, 0, 1) 35%,
    rgba(255, 255, 255, 1) 100%
  );
}

.register_account_options {
  width: 100%;
  text-align: center;
}

.register_account_options p {
  padding: 10px 0px 25px;
  font-size: 14px;
  font-weight: 400;
}

.register_account_options_child {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 0px 0px 20px 0px;
  width: 96%;
}

.register_account_options_child button {
  border: 0.1rem solid #f38d00;
  border-radius: 1.5rem;
  padding: 0.5rem 1.5rem;
  display: flex;
  color: #f38d00;
  align-items: center;
  gap: 10px;
  background-color: #fff;
}

.register_account_options_child button.active {
  background-color: #f38d00;
  color: #fff;
  border: 1px solid #f38d00;
}

.register_send_btn_container {
  padding: 1rem 0 0 0;
}

.register_send_btn {
  width: 96%;
  border-radius: 24px;
  border: none;
  color: white;
  background-color: #f38d00;
  padding: 8px 16px;
}

.candidate_input_group_container,
.login_candidate_input_group_container {
  display: flex;
  flex-wrap: wrap;
}

.candidate_input_group {
  flex: 1 1 50%;
  padding-top: 20px;
  padding-right: 12px;
}

.candidate_phone_number {
  display: flex;
  width: 98%;
  align-items: center;
  padding-top: 0.5rem;
  flex-direction: column;
}

.candidate_phone_number .react-tel-input {
  border-radius: 30px;
  border: 1px solid #9d9d9d73;
  width: 96%;
  padding: 0.3rem;
  font-size: smaller;
  color: #6c6c6c;
  padding-left: 1.5rem;
}

.candidate_phone_number .react-tel-input .form-control {
  border: none !important;
  width: 90% !important;
  border-radius: 0px !important;
  font-size: 14px;
  color: #6c6c6c;
}

.candidate_msg {
  padding: 1rem 1.2rem 0rem 0.9rem;
}

.candidate_input_group input,
.candidate_input_group select,
.candidate_phone_number input {
  border-radius: 30px;
  border: 1px solid #9d9d9d73;
  width: 95%;
  padding: 0.9rem;
  font-size: 13px;
  color: #6c6c6c;
  padding-left: 1.5rem;
}

.candidate_input_group select {
  outline: none;
  background-color: white;
}

.candidate_phone_number input {
  width: 100%;
}

.candidate_msg textarea {
  border-radius: 20px;
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #9d9d9d73;
  font-size: smaller;
  padding-left: 1.2rem;
}

.candidate_msg textarea:focus,
.candidate_phone_number input:focus,
.candidate_input_group input:focus {
  outline: none;
  border: 1px solid #48464673;
}

.register-mobile_logo {
  display: none;
}

/* validation css */

.register_error_msg {
  font-size: x-small;
  color: red;
  float: left;
  padding-top: 0.1rem;
  padding-left: 1.4rem;
  margin-bottom: -1rem;
}

/* =========== */

/* login css start here  */
.Login_partitan_line {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Login_partitan_line span {
  padding: 10px;
  font-size: 13px;
}

.Login_partitan_line_one,
.Login_partitan_line_tow {
  width: 100%;
  height: 1px;
  background-color: #8c8c8c8c;
}

.google_register_send_btn {
  width: 100%;
  border-radius: 24px;
  border: 1px #f38d00 solid;
  cursor: pointer;
  color: #272727;
  background-color: #fff;
  padding: 10px 18px;
  display: flex;
  justify-content: center;
}

.google_register_send_btn img {
  padding-right: 1rem;
}

.forgot_password_container {
  width: 100%;
  padding-top: 14px;
}

.remember_password {
  padding-left: 9px;
  font-size: 12px;
  font-weight: 400;
}

.login_submit_btn {
  width: 100%;
  border-radius: 28px;
  border: none;
  font-weight: 300;
  font-size: 17px;
  cursor: pointer;
  color: white;
  background-color: #f38d00;
  padding: 10px 20px;
}

.forgot_Password {
  color: #f38d00;
  cursor: pointer;
  font-size: 13px;
}

.login_candidate_input_group {
  flex: 1 1 100%;
  padding-top: 20px;
}

.login_candidate_input_group input {
  border-radius: 30px;
  border: 1px solid #9d9d9d6e;
  width: 100%;
  padding: 0.7rem;
  font-size: 12px;
  color: #6c6c6c;
  padding-left: 1.5rem;
}

.login_candidate_input_group_new input {
  padding: 0.9rem 1.1rem;
}

.login_candidate_input_group input:focus {
  outline: none;
  border: 1px solid #9d9d9d;
}

.login-mobile_logo {
  height: 50px !important;
  display: none;
}

.login_heading {
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  color: #f38d00;
  padding-top: 1rem;
}

.register_form_container {
  width: 83%;
}

.register_form_container_new {
  width: 100% !important;
}

.user-profile-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
}

.user_notif_cont {
  position: absolute;
  background: #f38d00;
  height: 8px;
  width: 8px;
  top: 3px;
  right: 0;
  border-radius: 50%;
}

.user-profile {
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 4px 12px 4px 4px;
  cursor: pointer;
}

.avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin-right: 8px;
  box-shadow: 0px 0px 3px 2px #8080803b;
  object-fit: cover;
}

.name {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-right: 4px;
}

.dropdown-icon {
  font-size: 8px;
  margin-top: 3px;
  padding-left: 2px;
  color: #0c0c0cd6;
  transition: transform 0.3s ease;
}

.dropdown-icon.open {
  transform: rotate(60deg);
  margin-top: 2px;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  margin-top: 8px;
}

.dropdown-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-content li {
  padding: 12px 16px;
  text-align: left;
  color: #333;
  font-size: 14px;
  cursor: pointer;
}

.dropdown-content li:hover {
  background-color: #f0f0f0;
}

.aftersignup_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: radial-gradient(
    46.51% 46.51% at 0% 50%,
    #f2f2f2 0%,
    #f4f6fc 100%
  );
  font-family: "Poppins", sans-serif;
}

.aftersignup_card {
  background-color: #ffffff;
  border-radius: 50px;
  padding: 50px;
  width: 60%;
  height: 55%;
  box-shadow: -12px 12px 67px -17px #00000029;
  text-align: center;
  display: grid;
  place-items: center;
  text-align: center;
}

.aftersignup_title {
  color: #f38d00;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 0;
}

.aftersignup_message {
  color: #222222;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 20px;
}

.aftersignup_button {
  background-color: #f38d00;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 45%;
}

.aftersignup_button:hover {
  background-color: #e58300;
}

.aftersignup_div_parent {
  width: 90%;
  display: grid;
  place-items: center;
  gap: 30px;
}

.forgot-password-screen {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.forgot-password-background {
  width: 100%;
  height: 100%;
  background-color: #1e3a5f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-content {
  color: white;
  text-align: center;
}

.forgot-password-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d9d9d91f;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgot-password-popup {
  background-color: white;
  padding: 30px;
  border-radius: 20px;
  width: 570px;
  height: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: grid;
  place-items: center;
}

.forgot-password-title {
  color: #f38d00;
  font-size: 30px;
  margin-bottom: 0px;
  text-align: center;
}

.forgot-password-description {
  color: #222222c9;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 33px;
  line-height: 20px;
}

.forgot-password-input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 25px;
  font-size: 13px;
  margin-bottom: 20px;
  outline: none;
}

.forgot-password-buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}

.forgot-password-cancel,
.forgot-password-send {
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.forgot-password-cancel {
  background-color: white;
  color: #f38d00;
  border: 1px solid #f38d00;
  width: 40%;
}

.forgot-password-send {
  background-color: #f38d00;
  color: white;
  border: none;
  width: 55%;
}

.rest-password-sent {
  padding-top: 3rem;
  margin-bottom: 0;
}

/* media query */

@media (max-width: 1650px) and (min-width: 1449px) {
  .register_main_parent {
    width: 73%;
  }
}

@media (max-width: 1450px) and (min-width: 1199px) {
  .register_main_parent {
    width: 65% !important;
  }

  .register_user_main {
    height: 595px;
  }
  .register_content_second_container {
    padding: 0 7%;
  }
  .candidate_input_group input,
  .candidate_input_group select,
  .candidate_phone_number input {
    font-size: 12px;
    color: #222222;
    font-weight: normal;
  }

  /* Example of increased specificity */
  .candidate_input_group .MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root,
  .candidate_input_group .MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root {
    padding-right: 0px !important;
  }

  .register_background_first {
    border-bottom-left-radius: 50px;
  }

  .login_candidate_input_group {
    padding-top: 10px;
  }
  .register_send_btn_container {
    padding: 0.4rem 0 0 0 !important;
  }
  .register_account_options p {
    padding: 10px 0px 8px;
  }

  .login_candidate_input_group_new input {
    padding: 0.7rem 1.1rem;
  }
  .login_submit_btn {
    padding: 7px 20px;
  }
  .login_heading {
    padding-top: 0;
  }

  .register_background_heading h1 {
    font-size: 1.5rem;
    padding: 0px 1rem;
  }

  .register_background_heading p {
    padding: 0 0.4rem;
  }

  .register_account_options_child button {
    font-size: 13px;
    padding: 0.5rem 1rem;
  }
  .candidate_input_group {
    padding-top: 11px;
    padding-right: 1px;
  }
}

@media (max-width: 1200px) and (min-width: 991px) {
  .register_main_parent {
    width: 85% !important;
  }

  .candidate_input_group {
    padding-top: 11px;
    padding-right: 1px;
  }

  .register_user_main {
    height: 590px;
  }

  .register_background_first {
    border-bottom-left-radius: 50px;
  }

  .login_candidate_input_group {
    padding-top: 5px;
  }
  .register_send_btn_container {
    padding: 0.4rem 0 0 0 !important;
  }
  .register_account_options p {
    padding: 10px 0px 9px;
  }
  .login_heading {
    padding-top: 0;
  }

  .register_background_heading h1 {
    font-size: 1.5rem;
    padding: 0px 1rem;
  }

  .register_background_heading p {
    padding: 0 0.4rem;
  }

  .register_account_options_child button {
    padding: 0.5rem 1rem;
  }
  .register_content_second_container {
    padding: 0 5%;
  }
  .register_heading {
    padding-top: 0.3rem;
  }
}

@media (max-width: 992px) and (min-width: 767px) {
  .register_main_parent {
    width: 90% !important;
  }

  .register_background_heading h1 {
    font-size: 2rem;
    padding: 0px 1rem;
  }

  .register_content_second {
    width: 66%;
    padding: 3rem 2rem;
    background-color: #fff;
    height: auto;
    border-radius: 1.5rem;
  }

  .register_content_second_container {
    padding: 0% !important;
  }

  .register_account_options_child button {
    padding: 0.5rem 1.3rem;
  }

  .register_background_first {
    display: none;
  }

  .register_user_main {
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .register_content_second_container {
    padding: 0px;
  }

  .register-mobile_logo,
  .login-mobile_logo {
    display: block;
  }
}

@media (max-width: 768px) and (min-width: 575px) {
  .register_main_parent {
    width: 100% !important;
  }

  .register_background_heading h1 {
    font-size: 2rem;
    padding: 0px 1rem;
  }

  .register_account_options_child {
    gap: 5px;
  }

  .register_account_options_child button {
    padding: 0.5rem 1rem;
  }

  .register_background_first {
    display: none;
  }

  .register_user_main {
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    width: auto !important;
  }

  .register_content_second_container {
    padding: 0px;
  }

  .register_content_second {
    width: 80%;
    padding: 0 3rem;
    background-color: #fff;
    height: inherit;
    border-radius: 1.5rem;
  }

  .register-mobile_logo,
  .login-mobile_logo {
    display: block;
  }
}

@media (max-width: 576px) and (min-width: 150px) {
  .register_main_parent {
    width: 100% !important;
  }

  .register_user_main {
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    width: auto !important;
    height: auto;
  }

  .register_content_second {
    width: 90%;
    padding: 3rem 2rem !important;
    height: auto !important;
    border-radius: 1.5rem;
  }

  .register_background_heading h1 {
    font-size: 2rem;
    padding: 0px 1rem;
  }

  .register_heading {
    font-size: 18px;
    padding-top: 1rem;
  }

  .register_header_line {
    width: 60%;
  }

  .register_account_options_child {
    gap: 13px;
    flex-direction: column;
    width: 95%;
    margin: auto;
  }

  .register_account_options_child button {
    justify-content: center;
    padding: 0.4rem 0.8rem;
    border-radius: 0.8rem;
  }

  .register_account_options_child button img {
    height: 18px;
  }

  .register_background_first {
    display: none;
  }

  .register_content_second_container {
    padding: 0px;
  }

  .register-mobile_logo,
  .login-mobile_logo {
    display: block;
  }

  .register-mobile_logo img {
    height: 26px;
  }

  .candidate_input_group {
    flex: 1 1 100%;
    padding-top: 14px;
  }

  .candidate_input_group input,
  .candidate_phone_number input {
    padding: 0.5rem;
    padding-left: 1.5rem;
  }

  .candidate_phone_number .react-tel-input {
    padding: 0rem;
    padding-left: 1rem;
  }

  .remember_password,
  .forgot_Password {
    font-size: 12px !important;
  }
}

/* Custom styles for the PhoneInput component */
.react-tel-input .flag-dropdown {
  border: none !important;
}

.spinner {
  border: 4px solid #ffffff;
  border-left-color: #f38d00;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin-top: 2px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* search college for candidate */
.college-list-dropdown {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 250px;
  overflow-y: auto;
  width: 220px;
  z-index: 1000;
}

.college-list-dropdown li {
  padding: 8px;
  text-align: left;
  cursor: pointer;
}

.college-list-dropdown li:hover {
  background-color: #f0f0f0;
}
