
.student_campus_setting_page {
    width: 100%;
    height: 100%;
  }
  
  .student_campus_login_page {
    width: 100%;
    height: fit-content;
    top: 108px;
    left: 312px;
    border-radius: 16px;
    /* opacity: 0px; */
    background-color: #ffffff;
    padding-bottom: 1rem;
    box-shadow: 0px 2px 4px 0px #00000026;
  }
  
  .student_campus_login_detail {
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 10px;
    border-bottom: 0.8px solid #312b2b;
  }
  
  .student_campus_login_heading {
    width: 130px !important;
    height: 22px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 21.76px !important;
    text-align: left !important;
    color: #111111 !important;
  }
  .student_campus_basic_information {
    width: Fixed 437px;
    height: 39px;
    padding-top: 20px;
    padding-left: 20px;
    gap: 4px;
    opacity: 0px;
  }
  
  .student_campus_basic_heading {
    width: 437px;
    height: 19px;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.04px;
    text-align: left;
    color: #1c1c1c;
  }
  
  .student_campus_paragraf_infomation {
    width: 437px;
    height: 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.32px;
    text-align: left;
    color: #2c2c2c;
  }
  
  .student_campus_password_div {
    width: 97.3%;
    height: fit-content;
    padding-bottom: 1rem;
    margin-left: 20px;
    margin-top: 40px;
    border-radius: 16px;
    opacity: 0px;
    background: #f2f2f2;
  }
  
  .student_campus_password_headingpara {
    width: Fixed 437px;
    height: 39px;
    padding-top: 20px;
    padding-left: 20px;
    gap: 4px;
    opacity: 0px;
  }
  
  .student_campus_password_heading {
    width: 437px;
    height: 19px;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.04px;
    text-align: left;
  }
  
  .student_campus_password_paragraf {
    width: 437px;
    height: 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.32px;
    text-align: left;
    color: #2c2c2c;
  }
  
  .student_campus_input_parantdiv {
    width: 656px;
    padding-top: 50px;
    padding-left: 20px;
    gap: 16px;
    opacity: 0px;
  }
  .student_campus_input_passord {
    width: 656px;
    height: 44px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px #00000026;
    border: none;
    outline: none;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.32px;
    color: #3c3c3c;
    padding-left: 20px;
  }
  
  .student_campus_input_divgroup {
    display: flex;
    gap: 16px;
    padding-top: 20px;
    width: 700px !important;
  }
  
  .student_campus_input_newpassord {
    width: 320px !important;
    height: 44px;
    border-radius: 10px;
    opacity: 0px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px #00000026;
    border: none;
    outline: none;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.32px;
    color: #3c3c3c;
    padding-left: 20px;
  }
  
  .student_campus_submmit_buttondiv {
    padding-left: 20px;
    padding-top: 20px;
    gap: 10px;
  
    opacity: 0px;
  }
  
  .student_campus_submmmit_button {
    width: 104px;
    height: 34px;
    padding-left: 25px;
    border-radius: 30px;
    background: #f38d00;
    border: none;
  }
  .student_campus_button_text {
    width: 44px;
    height: 18px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
  }
  
  .student_campus_notification_anytime {
    width: 97.3%;
    height: 79px;
    margin-left: 20px;
    margin-top: 20px;
    gap: 0px;
    border-radius: 16px;
    opacity: 0px;
    background: #f2f2f2;
  }
  
  .student_campus_notification_information {
    width: 97.3%;
    height: 39px;
    gap: 40px;
    padding-left: 20px;
    padding-top: 18px;
    display: flex;
    justify-content: space-between;
  }
  
  .student_campus_notification_headding {
    width: 374px;
    height: 19px;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.04px;
    text-align: left;
    color: #1c1c1c;
  }
  
  .student_campus_notification_paragraf {
    width: 374px;
    height: 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.32px;
    text-align: left;
    color: #2c2c2c;
  }
  
  .student_campus_notification_img {
    width: 41.25px;
    height: 24px;
    top: 7.5px;
    left: 958px;
    border-radius: 30px;
    background: #f38d00;
    margin-top: 10px;
  }

  .student_setting_box{
    margin-top: 1rem !important;
  }

  .student_error{
    width: fit-content !important;
    padding: 0 !important;
    font-size: 12px !important;
    margin-top: -16px !important;
    padding-bottom: 10px !important;
  }

  .student_setting_Input{
    width: 55%;
  }

  .student_new_error{
    margin-top: -7px !important;
  }