:root {
  /* Colors */
  --primary-color: #ffa500;
  --primary-hover: #ff8c00;
  --primary-light: #ffd480;

  /* Status Colors */
  --status-review-bg: #ffe4b5;
  --status-review-text: #8b4513;
  --status-interviewed-bg: #e6e6fa;
  --status-interviewed-text: #1890ff;
  --status-shortlisted-bg: #98fb98;
  --status-shortlisted-text: #006400;
  --status-discarded-bg: #ffb6c1;
  --status-discarded-text: #8b0000;
  --status-offered-bg: #87ceeb;
  --status-offered-text: #1890ff;

  /* Common values */
  --border-radius-sm: 4px;
  --border-radius-lg: 20px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
}

.capuse_interview_tabs-container {
  padding: 20px;
}

.capuse_interview_tabs {
  display: flex;
  gap: 20px;
  padding: 0 20px;
  width: 100%;
  border-bottom: 1px solid #eee;
  margin-bottom: 24px;
  justify-content: space-between;
}

.capuse_interview_tab-button {
  padding: 12px 24px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #666;
  position: relative;
  transition: all 0.3s ease;
}

.capuse_interview_tab-button.capuse_interview_active {
  color: var(--primary-color);
}

.capuse_interview_tab-button.capuse_interview_active::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
}

.capuse_interview_candidate-table {
  width: 100%;
  border-collapse: collapse;
}

.capuse_interview_candidate-table th,
.capuse_interview_candidate-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.capuse_interview_see-application {
  background-color: #ffa500;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.capuse_interview_delete-button {
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 5px;
}

.schedule_interview_campus_date_time_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.schedule_interview_campus_date_time_input input {
  height: 36px;
  border-radius: 20px;
  padding: 15px;
  border: 1.5px var(--primary-color) solid;
}

.schedule_interview {
  border-radius: 20px;
  border: 1.5px var(--primary-color) solid;
  background-color: var(--primary-color);
  padding: 8px 16px;
  color: #fff;
  font-weight: 600;
  float: right;
}

/* --------------------- */
.campus_interview_schedule_change_status_drop_down {
  width: 100%;
  max-width: 300px;
  padding: 7px 10px;
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  border: 2px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  appearance: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.campus_interview_schedule_change_status_drop_down:hover {
  border-color: #ffa500;
  background-color: #fff;
}

.campus_interview_schedule_change_status_drop_down:focus {
  outline: none;
  border-color: #ffa500;
}

.campus_interview_schedule_change_status_drop_down option {
  padding: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.campus_interview_schedule_change_status_drop_down option img {
  margin-right: 8px;
  height: 16px;
  width: 16px;
}

.campus_interview_schedule_change_status_drop_down::after {
  content: "▼";
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 12px;
  color: #333;
}

.campus_interview_schedule_change_status_drop_down span {
  display: inline-block;
  margin-right: 5px;
}

/* Add these new styles */
.status-badge {
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  width: auto;
  color: #fff;
  text-transform: capitalize;
}

.status-badge.in_review {
  background: var(--status-review-bg);
  color: var(--status-review-text);
}

.status-badge.interviewed {
  background: var(--status-interviewed-bg);
  color: var(--status-interviewed-text);
}

.status-badge.Shortlisted {
  background: var(--status-shortlisted-bg);
  color: var(--status-shortlisted-text);
}

.status-badge.discarded {
  background: var(--status-discarded-bg);
  color: var(--status-discarded-text);
}

.status-badge.offered {
  background: var(--status-offered-bg);
  color: var(--status-offered-text);
}

.status-dropdown {
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
  cursor: pointer;
  min-width: 120px;
}

.status-dropdown:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.status-dropdown option {
  padding: 4px;
}

/* Add loading indicator styles */
.status-cell-loading {
  position: relative;
  opacity: 0.7;
}

.status-cell-loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border: 2px solid #ffa500;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  right: -24px;
  top: 50%;
  transform: translateY(-50%);
}

.status-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.15em;
}

@keyframes spin {
  to {
    transform: translateY(-50%) rotate(360deg);
  }
}

.status-cell {
  display: flex;
  align-items: center;
  gap: 12px;
}

.status-actions {
  display: flex;
  gap: 8px;
}

.status-action-button {
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.status-action-button.Shortlisted {
  background-color: #fff7e6;
  color: #fa8c16;
}

.status-action-button.Shortlisted:hover {
  background-color: #fa8c16;
  color: white;
}

.status-action-button.interviewed {
  background-color: #e6f7ff;
  color: #1890ff;
}

.status-action-button.interviewed:hover {
  background-color: #1890ff;
  color: white;
}

.status-action-button.offered {
  background-color: #f9f0ff;
  color: #722ed1;
}

.status-action-button.offered:hover {
  background-color: #1890ff;
  color: white;
}

.status-action-button.discarded {
  background-color: #fff1f0;
  color: #f5222d;
}

.status-action-button.discarded:hover {
  background-color: #f5222d;
  color: white;
}

/* Update existing status badge styles */
.status-badge {
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.status-badge.in_review {
  background: var(--status-review-bg);
  color: var(--status-review-text);
}

.status-badge.interviewed {
  background: var(--status-interviewed-bg);
  color: var(--status-interviewed-text);
}

.status-badge.Shortlisted {
  background: var(--status-shortlisted-bg);
  color: var(--status-shortlisted-text);
}

.status-badge.discarded {
  background: var(--status-discarded-bg);
  color: var(--status-discarded-text);
}

.status-badge.offered {
  background: var(--status-offered-bg);
  color: var(--status-offered-text);
}

/* Loading state styles */
.status-cell.loading {
  opacity: 0.7;
  pointer-events: none;
}

.status-cell.loading::after {
  content: "";
  width: 16px;
  height: 16px;
  border: 2px solid #ffa500;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  margin-left: 8px;
}

.status-badge {
  display: inline-flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 14px;
}

.status-actions div {
  transition: background-color 0.2s;
}

.status-actions div:hover {
  background-color: #f5f5f5;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.status-cell {
  position: relative;
  display: inline-block;
}

.status-badge {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
}

.status-badge .dropdown-arrow {
  margin-left: 8px;
  font-size: 10px;
  transition: transform 0.2s;
}

.status-badge:hover {
  opacity: 0.9;
}

.status-dropdown {
  position: absolute;
  bottom: 100%;
  left: 0;
  transform: none;
  z-index: 9999;
  min-width: 120px;
  margin-bottom: 5px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #eee;
}

.status-option {
  padding: 8px 16px;
  cursor: pointer;
  transition: background 0.2s;
  white-space: nowrap;
}

.status-option:hover {
  background-color: #f5f5f5;
}

/* Update arrow position */
.status-dropdown:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 20px;
  transform: none;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
}

/* Status colors */
.status-badge.in_review {
  background: var(--status-review-bg);
  color: var(--status-review-text);
}
.status-badge.interviewed {
  background: var(--status-interviewed-bg);
  color: var(--status-interviewed-text);
}
.status-badge.Shortlisted {
  background: var(--status-shortlisted-bg);
  color: var(--status-shortlisted-text);
}
.status-badge.discarded {
  background: var(--status-discarded-bg);
  color: var(--status-discarded-text);
}
.status-badge.offered {
  background: var(--status-offered-bg);
  color: var(--status-offered-text);
}

status-option:hover {
  background-color: #f5f5f5;
}

.status-badge {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.dropdown-arrow {
  font-size: 10px;
}

.hiring_process_btn_status button.active:disabled {
  background-color: orange;
  opacity: 1;
  cursor: not-allowed;
}

.action-btn {
  padding: 8px 16px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  font-weight: 600;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.action-btn:hover {
  background-color: var(--primary-hover);
  transform: translateY(-1px);
}

.action-btn:disabled {
  background-color: var(--primary-light);
  cursor: not-allowed;
  transform: none;
}

.btn-loader {
  width: 20px;
  height: 20px;
  display: inline-block;
}
