@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #007bff;
  --border-color: #e0e0e0;
  --text-color: #333;
  --text-secondary: #6c757d;
}

body {
  font-family: "Poppins", sans-serif;
}

.custom-swal-confirm-button,
.css-1wzaoor.Mui-selected {
  background-color: #f38d00;
  color: #fff;
  border: none;
}

.custom-swal-confirm-button:hover {
  background-color: #e07a00;
}
.offline-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  padding: 1rem;
  z-index: 1000;
}

.css-1bug3cd-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #f38d00 !important;
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #f38d00;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

