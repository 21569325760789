.about_main {
  font-family: "Poppins", sans-serif;
}

.about_header {
  display: flex;
  justify-content: center;
  color: aliceblue;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 400px;
  background-color: #011e39;
  margin-top: 5rem;
}

.about_header button {
  padding: 10px 30px;
  background-color: #f38d00;
  color: #ffffff;
  border: 1px solid transparent;
  border-radius: 10px;
  font-weight: 600;
  font-size: 17px;
}

.about_header h4 {
  padding: 0 0 7px 0;
  font-weight: 400;
  font-size: 21px;
}

.about_header h2 {
  padding: 0 0 10px 0;
  font-weight: 600;
  font-size: 41px;
}

.about_header h6 {
  padding: 0 0 30px 0;
  font-weight: 300;
  font-size: 15px;
  color: #ffffff;
  letter-spacing: 0.5px;
}

.about_header button:hover {
  background-color: #f38d00d3;
}

/* Media queries for responsive design */
@media (max-width: 1200px) {
  .about_header h2 {
    font-size: 25px;
  }

  .about_header h4 {
    font-size: 18px;
  }

  .about_header h6 {
    font-size: 11px;
  }
}

@media (max-width: 992px) {
  .about_header h2 {
    font-size: 20px;
  }

  .about_header h4 {
    font-size: 16px;
  }

  .about_header h6 {
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .about_header {
    height: auto;
    padding: 20px 0;
  }

  .about_header h2 {
    font-size: 18px;
  }

  .about_header h4 {
    font-size: 14px;
  }

  .about_header h6 {
    font-size: 10px;
  }

  .about_header button {
    padding: 8px 20px;
  }
}

@media (max-width: 576px) {
  .about_header h2 {
    font-size: 15px;
  }

  .about_header h4 {
    font-size: 12px;
  }

  .about_header h6 {
    font-size: 8px;
    width: 80%;
    margin: auto;
  }

  .about_header button {
    padding: 6px 15px;
  }

  .about_header button {
    padding: 7px 20px;
    background-color: #f38d00;
    color: #ffffff;
    border: 1px solid transparent;
    border-radius: 10px;
    font-weight: 600;
    font-size: 12px;
    width: 50%;
  }
}

/* first section end */

/* second section of about us */
.about_section {
  width: 63%;
  margin: auto;
  padding: 170px 0 0 0;
  font-family: "Poppins", sans-serif;
}

.about_second_section_content h6 {
  color: #f38d00;

  font-size: 18px;
  padding-top: 30px;
  font-weight: 500;
}

.about_second_section_content h3 {
  font-weight: 600;
  font-size: 46px;
  padding-top: 10px;
}

.about_second_section_content p {
  font-weight: 400;
  font-size: 16px;
  padding: 40px 0 0 0;
}

.about_second_section_box {
  padding-top: 50px;
  width: 100%;
}

.about_second_section_box .box {
  height: 145px;
  border: 1.8px solid #f38d00;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  margin: 10px 0 0 0;
  box-shadow: none;
}

.about_second_section_box .box h3 {
  font-size: 28px;
  text-align: center;
  font-weight: 500;
}

.about_second_section_box .box h4 {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.about_section_line {
  height: 2px !important;
  width: 100%;
  opacity: 0.2;
  background-color: black;
  margin-top: 30px;
}

.about_image_container {
  display: grid;
  place-content: center;
}

.how_it_works {
  padding: 100px 0;
  text-align: center;
}

.how_it_works h2 {
  font-size: 47px;
  font-weight: 600;
  padding-bottom: 8px;
}

.how_it_works p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 40px;
}

.how_it_works .how_box {
  background-color: #ffffff;
  border: 1px solid #f38d00;
  border-radius: 15px;
  padding: 25px 25px 25px 25px;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
  width: 235px;
  height: 235px;
}

.how_it_works .how_box:hover {
  cursor: pointer;
  transform: translateY(-5px);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.how_it_works .how_box .icon {
  color: #f38d00;
  padding-bottom: 18px;
}

.how_it_works .how_box h4 {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 5px;
}

.how_it_works .how_box p {
  font-size: 13px;
  font-weight: 500;
  color: #00000099;
}

/* Large screens (desktops) */

@media (max-width: 1670px) and (min-width: 1500px) {
  .about_second_section_box .box {
    height: 120px !important;
  }

  .about_second_section_box .box h3 {
    font-size: 20px;
  }

  .about_second_section_box .box h4 {
    font-size: 14px;
  }
}

@media (max-width: 1500px) and (min-width: 1201px) {
  .about_section {
    width: 80%;
    padding: 50px 0;
  }

  .about_second_section_content h3 {
    font-size: 38px;
    padding-top: 2px;
  }

  .about_second_section_box .box {
    height: 140px;
  }

  .about_second_section_box .box h3 {
    font-size: 24px;
  }

  .about_second_section_box .box h4 {
    font-size: 14px;
  }

  .how_it_works {
    padding: 50px 0;
    text-align: center;
  }

  .how_it_works h2 {
    font-size: 36px;
  }

  .about_second_section_content h6 {
    padding-bottom: 12px;
  }
}

@media (max-width: 1200px) and (min-width: 999px) {
  .about_section {
    width: 80%;
    margin: auto;
    padding: 50px 0;
  }

  .about_second_section_content h3 {
    font-size: 28px;
    padding-top: 2px;
  }

  .about_second_section_content p {
    font-size: 14px;
    padding-top: 15px;
  }

  .about_second_section_content h6 {
    padding-bottom: 6px;
  }

  .about_second_section_box .box {
    height: 110px;
  }

  .about_second_section_box .box h3 {
    font-size: 20px;
  }

  .about_second_section_box .box h4 {
    font-size: 12px;
  }

  .how_it_works {
    padding: 50px 0;
    text-align: center;
  }

  .how_it_works h2 {
    font-size: 36px;
  }
}

/* Medium screens (tablets) */
@media (max-width: 998px) and (min-width: 768px) {
  .about_section {
    width: 80%;
  }

  .about_container {
    margin-top: -100px;
  }

  .about_second_section_content h3 {
    font-size: 24px;
  }

  .about_second_section_content h6,
  .about_second_section_content h3 {
    text-align: center;
  }

  .about_second_section_content p {
    padding-bottom: 20px;
    text-align: center;
  }

  .about_second_section_box {
    padding-top: 10px;
  }

  .how_it_works p {
    font-size: 16px;
  }

  .about_image_container .about_image {
    display: none;
  }
}

/* Small screens (landscape phones) */
@media (max-width: 767px) and (min-width: 576px) {
  .about_section {
    width: 80%;
    margin-top: -120px;
  }

  .about_second_section_content h3 {
    font-size: 24px;
  }

  .about_second_section_content h6 {
    text-align: center;
  }

  .about_second_section_content h3 {
    text-align: center;
  }

  .about_second_section_content p {
    padding-bottom: 20px;
    text-align: center;
  }

  .about_second_section_box .box {
    height: 100px;
  }

  .about_second_section_box .box h3 {
    font-size: 16px;
    text-align: center;
  }

  .about_second_section_box .box h4 {
    font-size: 13px;
  }

  .about_image {
    display: none;
  }
}

/* Extra small screens (portrait phones) */
@media (max-width: 575px) and (min-width: 150px) {
  .about_section {
    width: 85%;
  }

  .how_it_works p {
    text-align: center;
  }

  .about_second_section_content h6 {
    text-align: center;
    margin-top: -100px;
  }

  .about_second_section_content h3 {
    font-size: 20px;
    padding-top: 12px;
    text-align: center;
  }

  .about_second_section_content p {
    text-align: center;
  }

  .about_second_section_box div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about_second_section_box .box {
    height: 110px;
    width: 180px !important;
  }

  .about_second_section_box .box h3 {
    font-size: 17px;
  }

  .about_second_section_box .box h4 {
    font-size: 13px;
  }

  .about_image {
    display: none;
  }
}

/*  end this section */