.hrProfile_inner_screen {
  margin-top: 5rem;
  margin: auto;
  font-family: "Poppins", sans-serif;
}

.hrProfile_leftinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.hrProfile_logo {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: start;
}

.logo {
  width: 33%;
  height: 124px;
}

.logo img {
  width: 123px;
  height: 123px;
  border-radius: 65%;
  border: none;
}

.logo_content {
  text-align: left;
  width: auto;
}

.logo_content_btn {
  font-size: 12px;
}

.logo_content_text {
  text-align: center;
}

.logo_content_text span {
  font-size: larger;
  font-weight: 500;
}

.logo_content_text p {
  font-size: smaller;
  padding: 5px 0 0 0;
  font-weight: 400;
}

.logo_Button {
  display: flex;
  width: 200px;
  height: 32px;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.Selection_btn label {
  background: #f38d00;
  color: #fff;
  padding: 12px 12px;
  text-align: center;
  font-weight: 600;
  border-radius: 30px;
  cursor: pointer;
}

.Selection_btn input[type="file"] {
  display: none;
}

.remove_btn button {
  border: none;
  color: #f38d00;
  padding: 12px 12px;
  text-align: center;
  cursor: pointer;
  border-radius: 34px;
}

.sites_topView {
  padding: 5px;
  margin: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
}

.sites_icon {
  display: flex;
  width: 320px;
  height: 50px;
  text-align: left;
  margin-bottom: 10px;
}

.sites_icon_second {
  margin-bottom: 0;
  height: auto;
  margin-bottom: 4px;
}

.sites_icon a {
  color: orange;
  text-decoration: none;
}

.images img {
  width: 20px;
  margin-right: 20px;
}

.input_width {
  width: 323px;
}

/*  profile creation input */
.hr_Profile_creation_container {
  margin-top: 1rem;
}

.hr_profile_creation_details_first_section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.hr_profile_creation_details_first_section .details_row {
  display: flex;
  flex-direction: column;
  flex-basis: calc(50% - 10px);
  margin: 5px 0;
}

.hr_profile_creation_details_first_section .details_row label,
.details_company_description label,
.details_gst label {
  color: #f38d00;
}

.details_gst label {
  margin-top: 15px;
}

.details_gst p,
.details_company_description label {
  margin-top: 15px;
}

.hr_profile_creation_details_first_section .details_row p,
.details_company_description p,
.details_gst p {
  color: #969696;
}

.details_company_description textarea {
  margin-top: 1.5rem;
  width: 100%;
}

.hr_profile_creation_details_first_section .details_row input,
.details_gst input {
  height: 45px;
  border-radius: 12px;
  padding-left: 10px;
  border: 1px solid #969696;
}

.additional_details_header {
  font-size: 1.2rem;
  color: #969696;
  padding-top: 15px;
}

.details_row button {
  background-color: #f38d00;
  border-radius: 20px;
  padding: 6px 34px;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  float: left;
  margin: 20px 20px 20px 0;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.details_row button:hover {
  background-color: #d97900;
  transform: scale(1.05);
}

.hrProfile_error {
  color: red;
  font-size: 12px !important;
  margin-top: 5px;
}

.zoom_crediantial_button_div {
  padding-top: 1rem;
  padding-left: 2.6rem;
  display: flex;
  gap: 10px;
}

.zoom_crediantial_button_save {
  background: rgb(243, 141, 0);
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 700;
  border: none;
  outline: none;
  padding: 5px 15px;
  border-radius: 20px;
}

.zoom_crediantial_button_cancel {
  background: transparent;
  color: rgb(243, 141, 0);
  font-size: 16px;
  font-weight: 700;
  border: 1px solid rgb(243, 141, 0);
  outline: none;
  padding: 5px 15px;
  border-radius: 20px;
}

.zoom_meeting_inputs {
  padding: 5px 8px;
  border-radius: 10px;
  outline: none;
  border: 1px solid #000000b8;
}