:root {
    --primary-color: #f97316;
    --primary-light: #fff7ed;
    --primary-dark: #ea580c;
    --gray-light: #f8fafc;
    --gray-medium: #e2e8f0;
    --success-color: #22c55e;
    --white: #ffffff;
}

.quiz-container {
    height: 100%;
    background-color: #FFFFFF;
    padding: 20px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

.content-wrapper {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    max-height: 90vh;
    overflow-y: auto;
}

.quiz-layout {
    display: flex;
    gap: 30px;
}

.quiz-main {
    flex: 1;
    min-width: 0;
}

.quiz-sidebar {
    width: 320px;
}

/* Header Styles */
.header {
    margin-bottom: 24px;
}

.back-button {
    border: none;
    background: none;
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0;
    cursor: pointer;
}

.arrow-left {
    font-size: 20px;
}

/* Main Content Styles */
.main-content {
    margin-bottom: 30px;
}

.quiz-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;
}

.quiz-subtitle {
    color: #666;
    margin-bottom: 30px;
}

/* Question Card Styles */
.question-card {
    background: none;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;

}

.question-text {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}

/* Options Styles */
.options-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.option-button {
    width: 100%;
    padding: 16px;
    text-align: left;
    background: var(--white);
    border: 1px solid var(--gray-medium);
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
}

.option-button:hover {
    border-color: var(--primary-color);
    background-color: var(--primary-light);
}

.option-button.selected {
    border-color: var(--primary-color);
    background-color: var(--primary-light);
}

.option-id {
    font-weight: 600;
    margin-right: 12px;
}

/* Action Buttons */
.action-buttons {
    display: flex;
    gap: 16px;
    margin-top: 24px;
}

.btn-primary {
    background-color: #F38D00;
    color: var(--white);
    border: none;
    padding: 12px 24px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.btn-primary:hover {
    background-color: var(--primary-dark);
}

.btn-secondary {
    background-color: var(--white);
    color: #F38D00;
    border: 1px solid var(--primary-color);
    padding: 12px 40px;
    border-radius: 51px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.btn-secondary:hover {
    background-color: var(--primary-light);
}

.timer-card {
    background: var(--white);
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
    text-align: center;
    box-shadow: 0 2px 4px 5px rgba(0, 0, 0, 0.05);
}

.timer-circle-container {
    width: 120px;
    height: 120px;
    margin: 0 auto 16px;
    position: relative;
}

.timer-display {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.time {
    font-size: 24px;
    font-weight: bold;
}

.timer-svg {
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
}

.timer-circle-bg {
    fill: none;
    stroke: var(--gray-medium);
    stroke-width: 3;
}

.timer-circle {
    fill: none;
    stroke: var(--success-color);
    stroke-width: 3;
    stroke-linecap: round;
    transition: stroke-dasharray 1s linear;
}

.timer-text {
    color: #666;
    margin: 0;
}

/* Questions List Styles */
.questions-list-card {
    background: var(--white);
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 2px 4px 5px rgb(0 0 0 / 4%);
}

.list-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
}

.questions-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.question-list-item {
    width: 100%;
    padding: 12px;
    background: var(--gray-light);
    border: none;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
}

.question-list-item.active {
    background-color: var(--primary-light);
}

.question-list-item.completed {
    background-color: #ecfdf5;
}

.check-icon {
    color: var(--success-color);
    font-size: 18px;
}

.btn-primary.disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Responsive Styles */
@media (max-width: 991px) {
    .quiz-layout {
        flex-direction: column;
    }

    .quiz-sidebar {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .content-wrapper {
        padding: 10px;
    }

    .action-buttons {
        flex-direction: column;
    }

    .btn-primary,
    .btn-secondary {
        width: 100%;
    }
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.question-card {
    animation: fadeIn 0.3s ease-in-out;
}

/* Accessibility */
button:focus {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
}

/* Print styles */
@media print {
    .quiz-container {
        background: white;
    }

    .action-buttons,
    .timer-card {
        display: none;
    }
}