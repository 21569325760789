.hr_jobListing_add_job_container {
  height: 80px;
  width: 100%;
  margin-top: 2rem;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.hr_job_listing_table {
  border-collapse: collapse;
  margin: 20px 0;
  width: 100%;
  font-size: 14px;
  font-family: "Arial", sans-serif;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.hr_job_listing_table table {
  width: 100%;
  border-spacing: 0;
  background-color: #fff;
}

.hr_job_listing_table thead th {
  background-color: #011e39;
  font-weight: bold;
  padding: 12px 15px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  color: #ffff;
}

.hr_job_listing_table th:first-child {
  border-radius: 10px 0 0 0;
  padding: 20px 20px;
}

.hr_job_listing_table th:last-child {
  border-radius: 0 10px 0 0;
}

.hr_job_listing_table td {
  padding: 15px 15px;
  text-align: center;
  border-bottom: 1px solid #ddd; /* Add border to rows only */
  border-right: none; /* Remove right border to eliminate column lines */
}

.hr_job_listing_table tr:last-child td {
  border-bottom: none; /* Remove border from the last row */
}

.hr_job_listing_table .status {
  padding: 6px 15px;
  border-radius: 14px;
  font-size: 10px;
  font-weight: 600;
  font-family: "Poppins" sans-serif;
}

.hr_job_listing_table .live {
  border: 1px solid #00854a;
  color: #00854a;
}

.hr_job_listing_table .closed {
  color: #dc3545;
  border: 1px solid #dc3545;
}

.hr_job_listing_table .draft {
  color: #ffc107;
  border: 1px solid #ffc107;
}

.no_job_post_yet {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: #fff;
}

.filter_container_job_listing {
  margin-right: 20px;
}

.custom-select {
  position: relative;
  display: inline-block;
}

.select-selected {
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.select-selected:hover {
  box-shadow: rgba(60, 64, 67, 0.5) 0px 2px 4px 0px,
    rgba(60, 64, 67, 0.25) 0px 2px 6px 1px;
  transform: scale(1.02);
}

.select-items {
  position: absolute;
  margin-top: 12px;
  background-color: white;
  border-radius: 10px;
  display: block; /* Show options */
  z-index: 99; /* Show on top */
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.option {
  padding: 0.5rem 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.option:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Hover effect */
}
.filter-icon {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  vertical-align: middle;
  display: inline-block;
}

.filterdropdown {
  margin-left: 5px;
}

.job_search_input_admin {
  padding: 8px 12px;
  border-radius: 24px;
  border: none;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.offer_letter_btn {
  color: #fff;
  padding: 0.5rem 0.8rem;
  background-color: #f38d00;
  border-radius: 24px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
