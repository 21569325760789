.post_jobs_step_form {
  width: 90%;
  margin: auto;
  font-family: "Poppins", sans-serif !important;
}

.post_job_steps_container {
  padding: 1rem 0rem;
}

/* Container for steps */
.progress-bar-container {
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}

/* Individual step */
.step {
  width: 25%;
  /* 100% / 4 steps */
  text-align: center;
  position: relative;
  font-size: 14px;
  padding: 0.8rem;
  display: FLEX;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.step.active .step-label,
.step.completed .step-label {
  color: orange;
}

.step.active .step-content,
.step.completed .step-content {
  color: #272727;
}

.step-label {
  display: block;
  color: #6c6c6c;
  text-align: left;
  padding-left: 15px;
}

/* Progress line background */
.progress-line {
  position: relative;
  background-color: #e0e0e0;
  height: 4px;
  width: 100%;
  margin-top: -10px;
  border-radius: 2px;
}

/* Actual progress indicator */
.progress {
  background-color: orange;
  height: 100%;
  width: 50%;
  /* For example, 50% progress (Step 2 is active) */
  transition: width 0.3s ease;
  border-radius: 2px;
}

/* post job css and  */
.post_job_form_container {
  height: auto;
  padding: 1rem 0;
}

/* job information container css */

.post_job_info_container {
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 1.2rem 0 0 0;
  overflow-y: auto;
}

.post_job_info_container::-webkit-scrollbar {
  width: 8px;
}

.post_job_info_container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.post_job_info_container::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

.post_job_info_section {
  display: flex;
  height: auto;
  border-bottom: 1.5px solid #e0e0e0;
}

.post_job_info_label {
  width: 35%;
  padding: 0.8rem 1.5rem;
}

.post_job_info_label h6,
.post_job_info_section_step_second h6,
.post_job_info_container_third h6 {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.css-19166iz-MuiAutocomplete-listbox .MuiAutocomplete-option {
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
}

.post_job_info_label label,
.post_job_info_section_step_second label,
.post_job_info_container_third label {
  font-size: 12px;
  color: #333333;
  font-family: "Poppins", sans-serif;
}

.post_job_info_field {
  width: 65%;
}

.post_job_info_field {
  display: flex;
  align-items: center;
}

#jobTitle,
#vacancy,
#maxSalary,
#minSalary,
#jobCategory,
#degreeType,
#interviewRound {
  width: 200px;
  border-radius: 8px;
  border: 1px solid #c0c0c0;
  height: 36px;
  padding: 0 15px;
  font-size: 14px;
}

#maxSalary,
#minSalary,
#maxExperience,
#minExperience {
  width: 200px;
  border-radius: 8px;
  border: 1px solid #c0c0c0;
  height: 36px;
  padding: 0 15px;
  font-size: 14px;
}

#jobTitle.invalid,
#vacancy.invalid,
#maxExperience.invalid,
#minExperience.invalid,
#maxSalary.invalid,
#minSalary.invalid {
  border: 1px solid red !important;
}

#jobTitle,
#vacancy {
  width: 200px;
  border-radius: 8px;
  border: 1px solid #c0c0c0;
  height: 36px;
  padding: 0 15px;
}

.post_job_info_field_emp_type_checkbox div {
  display: flex;
  padding-top: 5px;
}

.post_job_info_field_emp_type_checkbox label {
  margin: 0px 15px;
  font-size: 13px;
  color: #6c6c6c;
}

.modal-overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  font-size: 12px;
  width: auto;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-buttons button,
.modal-buttons label {
  margin: 10px;
  padding: 8px 16px;
  border: none;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.2cap;
  border-radius: 20px;
  font-weight: 700;
  color: #f5f5f5;
  background-color: #ff8800;
}

.post_job_info_container_step_second,
.post_job_info_container_third {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.post_job_info_section_step_second,
.post_job_info_section_third {
  padding: 1rem;
}

.post_job_info_field_step_second {
  padding: 0.5rem 0 0 0;
}

.post_job_info_field_step_second input {
  border-radius: 8px;
  border: 1px solid #c0c0c0;
  height: 60px;
  width: 70%;
  padding-left: 10px;
}

.assessment_btn {
  padding: 8px 16px;
  background-color: #ff8800;
  border: none;
  border-radius: 22px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.assessment_btn:hover {
  background-color: #e67e22;
}

.assessment_btn:active {
  background-color: #cc6b00;
  transform: scale(0.95);
}

.assessment_btn_container {
  padding: 0 0 0 1.2rem;
  margin-bottom: 0.9rem;
  display: flex;
  gap: 15px;
}

.post_job_info_container_fourth {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.post_job_info_section_fourth {
  background-color: #fff;
  padding: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.assessment_btn_container {
  position: relative;
}

.assessment_select {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 22px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  appearance: none;
  width: 200px;
}

.assessment_select::after {
  content: "▼";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #ff8800;
  pointer-events: none;
}

.assessment_select:hover {
  border-color: #ff8800;
  box-shadow: 0 0 5px rgba(255, 136, 0, 0.5);
}

.assessment_select:focus {
  outline: none;
  border-color: #ff8800;
  box-shadow: 0 0 5px rgba(255, 136, 0, 0.5);
}

.spinner {
  border: 4px solid #ffffff;
  border-left-color: #f38d00;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin-top: 2px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.checkbox-wrapper-7 .tgl {
  display: none;
}

.checkbox-wrapper-7 .tgl,
.checkbox-wrapper-7 .tgl:after,
.checkbox-wrapper-7 .tgl:before,
.checkbox-wrapper-7 .tgl *,
.checkbox-wrapper-7 .tgl *:after,
.checkbox-wrapper-7 .tgl *:before,
.checkbox-wrapper-7 .tgl+.tgl-btn {
  box-sizing: border-box;
}

.checkbox-wrapper-7 .tgl::-moz-selection,
.checkbox-wrapper-7 .tgl:after::-moz-selection,
.checkbox-wrapper-7 .tgl:before::-moz-selection,
.checkbox-wrapper-7 .tgl *::-moz-selection,
.checkbox-wrapper-7 .tgl *:after::-moz-selection,
.checkbox-wrapper-7 .tgl *:before::-moz-selection,
.checkbox-wrapper-7 .tgl+.tgl-btn::-moz-selection,
.checkbox-wrapper-7 .tgl::selection,
.checkbox-wrapper-7 .tgl:after::selection,
.checkbox-wrapper-7 .tgl:before::selection,
.checkbox-wrapper-7 .tgl *::selection,
.checkbox-wrapper-7 .tgl *:after::selection,
.checkbox-wrapper-7 .tgl *:before::selection,
.checkbox-wrapper-7 .tgl+.tgl-btn::selection {
  background: none;
}

.checkbox-wrapper-7 .tgl+.tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-wrapper-7 .tgl+.tgl-btn:after,
.checkbox-wrapper-7 .tgl+.tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}

.checkbox-wrapper-7 .tgl+.tgl-btn:after {
  left: 0;
}

.checkbox-wrapper-7 .tgl+.tgl-btn:before {
  display: none;
}

.checkbox-wrapper-7 .tgl:checked+.tgl-btn:after {
  left: 50%;
}

.checkbox-wrapper-7 .tgl-ios+.tgl-btn {
  background: #fbfbfb;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
  border: 1px solid #e8eae9;
}

.checkbox-wrapper-7 .tgl-ios+.tgl-btn:after {
  border-radius: 2em;
  background: #fbfbfb;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    padding 0.3s ease, margin 0.3s ease;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
}

.checkbox-wrapper-7 .tgl-ios+.tgl-btn:hover:after {
  will-change: padding;
}

.checkbox-wrapper-7 .tgl-ios+.tgl-btn:active {
  box-shadow: inset 0 0 0 2em #e8eae9;
}

.checkbox-wrapper-7 .tgl-ios+.tgl-btn:active:after {
  padding-right: 0.8em;
}

.checkbox-wrapper-7 .tgl-ios:checked+.tgl-btn {
  background: #86d993;
}

.checkbox-wrapper-7 .tgl-ios:checked+.tgl-btn:active {
  box-shadow: none;
}

.checkbox-wrapper-7 .tgl-ios:checked+.tgl-btn:active:after {
  margin-left: -0.8em;
}

.back_to_page_arrow {
  padding: 0.8rem 3.4rem 0rem 3.4rem;
  justify-content: space-between;
}

/* ------select letral job campuse job */
/* From Uiverse.io by Ghost-aft */
.radio-input input {
  display: none;
}

.radio-input {
  --container_width: 300px;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 20px;
  background-color: #fff;
  color: #6c6c6c;
  width: var(--container_width);
  overflow: hidden;
  border: 1px solid rgba(53, 52, 52, 0.226);
}

.radio-input label {
  width: 100%;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-weight: 600;
  letter-spacing: -1px;
  font-size: 14px;
}

.radio-input label:hover {
  background-color: #ff8800;
  color: #fff;
}

.selection {
  display: none;
  position: absolute;
  height: 100%;
  width: calc(var(--container_width) / 2);
  z-index: 0;
  left: 0;
  top: 0;
  transition: 0.15s ease;
}

.radio-input label:has(input:checked) {
  color: #fff;
}

.radio-input label:has(input:checked)~.selection {
  background-color: #ff8800;
  display: inline-block;
}

.radio-input label:nth-child(1):has(input:checked)~.selection {
  transform: translateX(calc(var(--container_width) * 0 / 2));
}

.radio-input label:nth-child(2):has(input:checked)~.selection {
  transform: translateX(calc(var(--container_width) * 1 / 2));
}

.job_audience_pop {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.job_audience_pop {
  font-family: "Poppins", sans-serif;
  color: #6c6c6c;
  font-size: 16px;
  padding-bottom: 1rem;
}

.post_job_info_section_fourth.due_date {
  display: flex;
  padding: 1.5rem;
}

.error-post-job {
  height: 5px;
  font-size: 11px;
  white-space: nowrap;
  padding: 0;
  text-align: start;
  color: red;
  font-weight: 400;
  margin: 0 0 0 0.5rem;
  padding-bottom: 12px;
}

.skill-dropdown-container {
  z-index: 9999;
  position: relative;
}

.MuiAutocomplete-popper {
  z-index: 9999 !important;
}