.admin_settings_container {
  padding: 20px;
  font-family: "Poppins", sans-serif;
  background-color: #fff;
  border-radius: 10px;
}

.admin_settings_headings h6 {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 10px;
}

.admin_settings_container hr {
  border: none;
  border-top: 1px solid #e0e0e0; /* Light gray separator line */
  margin: 15px 0;
}

/* Section headings */
.admin_settings_main_sections_heading {
  margin-bottom: 20px;
}

.admin_settings_basic_info_container h6,
.admin_settings_basic_form_heading h6,
.notifications_heading h6 {
  font-size: 18px;
  font-weight: 400;
  color: #333;
}

.admin_settings_basic_info_container p,
.admin_settings_basic_form_heading p,
.notifications_heading p {
  font-size: 14px;
  color: #353232;
  margin-bottom: 15px;
}

/* Form styling */
.admin_settings_basic_form_container {
  background-color: #f1f3f6;
  padding: 20px;
  border-radius: 10px;
  margin: 0.9rem 0;
}

.admin_settings_basic_form_details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.admin_settings_basic_form_details .input-details {
  width: 100%;
  max-width: 48%;
  border-radius: 8px;
  font-size: 14px;
}

.admin_settings_basic_form_details .input-details input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
}

.input-details input:focus {
  border-color: #f97316; /* Highlight on focus */
  outline: none;
}

.admin_settings_form_submit_btn {
  padding: 0.8rem 0rem;
}

.admin_settings_form_submit_btn button {
  background-color: #f97316;
  padding: 6px 18px;
  border: none;
  border-radius: 16px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin_settings_form_submit_btn button:hover {
  background-color: #f38d00;
}

/* Notifications */
.admin_notifications {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f1f3f6;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.admin_notification_one {
  display: flex;
  flex-direction: column;
}

.admin_notification_one button {
  width: 40px;
  height: 20px;
  background-color: #dbadad;
  border: none;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin_notification_one button::after {
  content: "";
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease;
}

.admin_notification_one button.active {
  background-color: #f38d00;
}

.admin_notification_one button.active::after {
  transform: translateX(20px);
}

.input-details input.error {
  border-color: red;
}

.error-admin {
  color: red;
}

.email {
  font-weight: 600;
}
