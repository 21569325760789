.epf-container {
  width: 63%;
  margin: 9rem auto;
  padding: 1rem;
  font-family: "Poppins", sans-serif;
}

.epf-header {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  gap: 2rem;
}

.epf-image-wrapper {
  position: relative;
  display: flex;
}

.epf-profile-pic {
  width: 152px;
  height: 152px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 1px 1px 4px -1px #958686;
}

.epf_loading_image_container {
  border: 1px solid #000000;
  display: grid;
  place-items: center;
  padding: 18px;
  width: 152px;
  height: 152px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 1px 1px 4px -1px #958686;
}

.epf-upload-trigger {
  position: absolute;
  right: -10px;
  bottom: 0;
  width: 45px;
  height: 45px;
  background-color: rgba(243, 141, 0, 1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.epf-camera-icon {
  width: 1rem;
  height: 1rem;
  color: white;
}

.epf-resume-block {
  flex-grow: 1;
  margin-top: -23px;
}

.epf-resume-prompt {
  font-size: 15px;
  color: rgba(17, 17, 17, 1);
  font-weight: 500;
}

.epf-format-info {
  background-color: rgba(255, 250, 243, 1);
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(243, 141, 0, 1);
  padding-top: 32px;
  padding-left: 20px;
  padding-bottom: 32px;
  cursor: pointer;
}

.epf-format-icon {
  color: #f97316;
  margin-right: 13px;
}

.epf-format-text {
  font-size: 14px;
  font-weight: 400;
}

.epf-add-resume-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f97316;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  cursor: pointer;
}

.epf-completion-card {
  background-color: rgba(255, 250, 243, 1);
  border-radius: 18px;
  display: flex;
  gap: 4rem;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(243, 141, 0, 1);
  padding-top: 13px;
  padding-left: 20px;
  padding-right: 17px;
  padding-bottom: 13px;
}

.epf-completion-label {
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.epf-chart-wrapper {
  position: relative;
  width: 190px;
  height: 190px;
  margin-left: -2rem;
  margin-top: 2rem;
  position: relative;
  width: 190px;
  height: 190px;
  margin-left: -2rem;
  margin-top: 2rem;
}

.new_epf_completion_card_letral_main .epf-chart-wrapper {
  position: relative;
  width: 190px;
  height: 190px;
  margin-left: -2rem;
  margin-top: 2rem;
  position: relative;
  width: 100px;
  height: 80px;
  margin-left: -2rem;
  margin-top: 0;
}

.new-epf-chart-wrapper {
  position: relative;
  width: 190px;
  height: 190px;
  margin-left: 0rem;
  margin-top: 0rem;
  margin-bottom: 2rem;
}

.epf-chart-container {
  width: 100%;
  height: 100%;
}

.epf-completion-value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 500;
  color: rgba(17, 17, 17, 1);
}

.epf-form-container {
  background-color: white;
  border-radius: 20px;
  box-shadow: 1px 0px 7px 0px #adaaaa;
  padding: 2rem 3rem;
}

.epf-form-heading {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: -10px;
}

.epf_form_name_heading {
  width: fit-content;
  padding-bottom: 13px;
}

.epf_form_name_heading hr {
  width: 70%;
  border: 1px solid rgba(108, 108, 108, 1);
}

.epf-form-layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 7.5rem;
  grid-row-gap: 1.2rem;
}

.epf-input-field {
  border: 1px solid rgb(227 227 227);
  border-radius: 10px;
  padding: 12px 15px;
  width: 100%;
}

.epf-input-field:focus {
  outline: none;
  box-shadow: 0 0 0 2px #f97316;
}

.epf-submit-btn {
  width: fit-content;
  background: rgba(243, 141, 0, 1);
  border: none;
  border-radius: 25px;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 600;
  font-size: 16px;
  margin-top: 15px;
  color: white;
}

.input-error {
  border-color: #ef4444;
}

.epf-submit-btn:hover {
  background-color: rgb(207, 122, 3);
}

.error-text {
  color: #ef4444;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

.hidden {
  display: none;
}

.add_resume_text {
  color: rgba(243, 141, 0, 1);
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
}

.complete_profile_text {
  display: grid;
  font-weight: 500;
  font-size: 15px;
}

.submitted-data-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  padding: 20px;
}

.submitted-data-column {
  flex: 1;
  min-width: 250px;
}

.submitted-data-container p {
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 1.5;
  color: #333;
}

.submitted-data-container b {
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}

.epf_edit_Details_button {
  position: absolute;
  top: 29px;
  right: 35px;
  cursor: pointer;
  width: fit-content;
  color: rgba(243, 141, 0, 1);
}

.epf_submit_cancel_btn {
  background: transparent;
  color: rgba(243, 141, 0, 1);
  border: 1px solid rgba(243, 141, 0, 1);
  margin-left: 20px;
}

.epf_submit_cancel_btn:hover {
  background: rgba(243, 141, 0, 1);
  color: white;
  border: 1px solid rgba(243, 141, 0, 1);
  margin-left: 20px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-group {
  flex-basis: 48%;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #555;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease-in-out;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #007bff;
}

.input-error {
  border-color: #ff4d4f;
}

.error-text {
  color: #ff4d4f;
  font-size: 0.875rem;
  margin-top: 5px;
}

.save-button {
  background-color: #ff9500;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.save-button:hover {
  background-color: #e68500;
}

.epf-input-gap {
  padding-bottom: 20px;
}

.epf-input-wrapper {
  margin-bottom: 0px;
}

.epf-suggested-skills {
  margin-top: 20px;
  background-color: rgb(249 249 249 / 86%);
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 15px;
  border: 1px solid rgb(156 156 156 / 46%);
}

.epf-suggested-skills p {
  font-weight: 500;
  padding-bottom: 20px;
}

.epf-skill-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.epf-skill-button {
  padding: 5px 12px;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid rgb(156 156 156 / 46%);
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.epf-skill-button.selected {
  background-color: #ffa500;
  color: white;
}

.new-qualification-form-container {
  width: 70%;
}

.employment-details-container {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
}

.form-section {
  margin-bottom: 30px;
}

.form-section b {
  font-weight: 500;
  color: rgba(28, 28, 28, 1);
}

.radio-group {
  display: flex;
  gap: 10px;
  padding-top: 13px;
}

.toggle-btn {
  background: transparent;
  padding: 4px 28px;
  border-radius: 20px;
  border: 1px solid rgba(243, 141, 0, 1);
  color: rgba(243, 141, 0, 1);
}

.toggle-btn.active {
  background-color: #ff9800;
  color: white;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-row {
  display: flex;
  gap: 20px;
}

.salary-input {
  display: flex;
  align-items: center;
}

.salary-input span {
  padding: 10px;
  background-color: #f1f1f1;
  border-right: 1px solid #ccc;
}

.save-btn {
  padding: 12px 20px;
  background-color: #ff9800;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.currency_dropdown {
  width: fit-content;
}

.currency_main_container {
  width: 100%;
}

.currency-select {
  padding: 13px;
  border-radius: 10px;
  outline: none;
  border: 1px solid rgb(156 156 156 / 45%);
}

.epf_qualification_edit_btn {
  cursor: pointer;
  width: fit-content;
  color: rgba(243, 141, 0, 1);
  height: fit-content;
  padding-top: 16px;
}

.student_suggestions_list {
  position: absolute;
  top: 45px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  padding: 0;
  margin: 4px 0 0 0;
  list-style-type: none;
}

.student_sites_icon {
  margin-bottom: 37px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.letral_social_media_main {
  box-shadow: 1px 0px 7px 0px #adaaaa;
  padding: 2rem 4rem;
  border-radius: 18px;
}

@media (max-width: 1650px) and (min-width: 1200px) {
  .epf-container {
    width: 85%;
  }
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .epf-container {
    width: 85%;
  }

  /* .epf-format-text {
    font-size: 12px;
} */

  /* .epf-resume-prompt {
    font-size: 11px;
}
.add_resume_text {
   font-size: 10px;
} */

  /* .epf-format-info {
    padding-top: 15px;
    padding-bottom: 15px;
} */

  .epf-completion-card {
    gap: 1rem;
  }

  .epf-form-layout {
    grid-column-gap: 1.5rem !important;
  }
}

@media (max-width: 1000px) and (min-width: 850px) {
  .epf-container {
    width: 81%;
  }

  .epf-format-text {
    font-size: 12px;
  }

  .epf-resume-prompt {
    font-size: 11px;
  }

  .add_resume_text {
    font-size: 10px;
  }

  .epf-format-info {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .epf-completion-card {
    gap: 1rem;
  }

  .epf-form-layout {
    grid-column-gap: 1.5rem !important;
  }
}

@media (max-width: 850px) and (min-width: 500px) {
  .epf-container {
    width: 90%;
  }

  .epf-submit-btn {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .new-qualification-form-container {
    width: 100%;
  }

  .epf-format-text {
    font-size: 12px;
  }

  .epf-resume-prompt {
    font-size: 11px;
  }

  .add_resume_text {
    font-size: 10px;
  }

  .epf-format-info {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .epf-completion-card {
    gap: 1rem;
  }

  .epf-form-layout {
    grid-column-gap: 1.5rem !important;
  }
}

@media (max-width: 750px) and (min-width: 200px) {
  .epf-form-layout {
    grid-template-columns: 1fr;
  }

  .epf-container {
    width: 90%;
  }

  /* 
    .epf-input-field {
        width: 70%;
    } */

  .epf-submit-btn {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .epf-header {
    display: grid;
    place-items: center;
  }

  .epf-image-wrapper {
    width: fit-content;
  }

  .epf-format-info {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .epf-resume-block {
    margin-top: 0px;
    width: 100%;
  }

  .epf-completion-card {
    justify-content: space-around;
    width: 100%;
  }

  .res_epf_container_width {
    width: 100%;
  }
}

@media (max-width: 575px) and (min-width: 510px) {
  .profilepage_editdetails {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media (max-width: 510px) and (min-width: 200px) {
  .epf-form-container {
    padding: 2rem 1.5rem;
  }

  .epf_edit_Details_button {
    position: initial;
  }

  .epf-container {
    width: 97%;
  }

  /* .radio-group {
        flex-direction: column;
    } */

  .epf-input-field {
    font-size: 12px;
  }

  .employment-details-container {
    padding: 0px;
  }

  .new-qualification-form-container {
    width: 100%;
  }

  .epf-submit-btn {
    font-size: 12px;
  }

  .toggle-btn {
    padding: 4px;
    width: 8rem;
  }

  .epf-resume-prompt {
    font-size: 11px;
  }

  .add_resume_text {
    font-size: 10px;
  }

  .res_epf_container_width {
    width: 100%;
  }

  .epf_edit_Details_button {
    position: none;
  }

  .profilepage_editdetails {
    display: flex;
    flex-direction: column-reverse;
  }

  .epf-form-heading {
    font-size: 13px;
    white-space: nowrap;
  }

  .epf-skill-button{
    font-size: 12px;
  }

  .epf_edit_Details_button b span {
    font-size: 12px;
    white-space: nowrap;
  }

  .sites_icon a {
    font-size: 15px;
  }

  .letral_social_media_main {
    padding: 2rem 2rem;
  }

  .epf_qualification_edit_btn b span {
    font-size: 13px;
  }

  .submitted-data-container b {
    font-size: 16px;
  }

  .submitted-data-container p {
    font-size: 15px;
  }

  .student_sites_icon {
    margin-bottom: 37px;
    max-width: 320px;
    overflow-x: scroll;
    display: flex;
    justify-content: space-between;
  }
}

.pdf_gen_main_container {
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.pdf_gen_sidebar {
  width: 25%;
  background-color: rgb(243 141 0 / 75%);
  color: #ffffff;
  padding: 66px 35px;
}

.pdf_gen_main_content {
  width: 75%;
  padding: 55px 30px 0px 30px;
  background-color: #ffffff;
  position: relative;
}

.pdf_gen_section_title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #34495e;
}

.pdf_gen_sidebar .pdf_gen_section_title {
  color: #ffffff;
}

.pdf_gen_contact {
  padding-bottom: 2rem;
}

.pdf_gen_contact p {
  margin-bottom: 5px;
  font-size: 14px;
}

.pdf_gen_skills_list {
  list-style-type: none;
  padding: 0;
}

.pdf_gen_skills_list li {
  margin-bottom: 5px;
  font-size: 14px;
}

.pdf_gen_name {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #2c3e50;
}

.pdf_gen_title {
  font-size: 18px;
  color: #7f8c8d;
  margin-bottom: 1px;
}

.pdf_gen_location {
  font-size: 14px;
  color: #95a5a6;
  margin-bottom: 20px;
}

.pdf_gen_summary,
.pdf_gen_experience,
.pdf_gen_education {
  margin-bottom: 30px;
}

.pdf_gen_job,
.pdf_gen_education_item {
  margin-bottom: 20px;
}

.pdf_gen_job h3,
.pdf_gen_education_item h3 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #2c3e50;
}

.pdf_gen_job h3 {
  font-size: 14px;
  font-weight: 600;
}

.pdf_gen_job_title,
.pdf_gen_job_duration,
.pdf_gen_job_location {
  font-size: 14px;
  margin-bottom: 3px;
}

.pdf_gen_job_title {
  font-weight: bold;
}

.pdf_gen_job_duration,
.pdf_gen_job_location {
  color: #7f8c8d;
}

.pdf_gen_main_content p {
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 0px;
  color: #383635d1;
}

.pdf_gen_sidebar a {
  color: #ecf0f1;
  text-decoration: none;
}

.pdf_gen_sidebar a:hover {
  text-decoration: underline;
}

#pdf-container {
  padding-bottom: 0;
}

#pdf-container>*:not(:first-child) {
  margin-top: 0mm;
}

.pdf_amoha_watermark {
  position: absolute;
  transform: rotate(316deg);
  top: 34%;
  left: 14%;
}

.pdf_amoha_watermark h1 {
  font-size: 8rem;
  color: rgb(243 141 0 / 25%);
}

@media print {
  .pdf_gen_main_container {
    page-break-inside: avoid;
  }

  .pdf_gen_section_title {
    page-break-before: avoid;
  }

  .pdf_gen_job {
    page-break-inside: avoid;
  }

  .page-break {
    page-break-before: always;
    margin-top: 50px;
    /* Adjust this as needed */
  }
}

@media (max-width: 768px) {
  .pdf_gen_main_container {
    flex-direction: column;
  }

  .pdf_gen_sidebar,
  .pdf_gen_main_content {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .form-group {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .submitted-data-column {
    flex: 100%;
    max-width: 100%;
  }
}