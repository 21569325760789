.admin-pending-request-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  margin-bottom: 30px;
}
.admin-pending-company-request-container,
.admin-pending-college-request-container {
  background: #ffffff;
  width: 49%;
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px #00000026;
}
.admin-stat-container {
  justify-content: space-between;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-bottom: 30px;
  gap: 0.5rem;
}

.admin-stat-card {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  width: auto;
  background-color: white;
}

.admin-stat-card:hover {
  transform: translateY(-5px);
  cursor: pointer;
}

.add_faq_btn {
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  background-color: #f38d00;
  color: white;

  border-radius: 24px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.add_faq_btn:hover {
  background-color: #d97706;
}

.all_applicants_toggle_btn {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  cursor: pointer;
  background-color: #fff;
  color: black;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 30px;
}

.all_applicants_toggle_btn.active {
  background-color: #f38d00;
  color: white;
}

.campus_section_container,
.lateral_section_container {
  display: flex;
  padding: 1rem 0;
  height: 500px;
  box-sizing: border-box;
  display: flex;
  gap: 1rem;
}

.campus_section_one,
.campus_section_two,
.lateral_section_two,
.lateral_section_one {
  background-color: #fff;
  height: 100%;
  width: 50%;
  padding: 1rem;
  border-radius: 24px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px #00000026;
}

/* ------------------- Lateral --- section start----------------------------------------------- */

.lateral_section_two {
  padding: 0px !important;
}

.lateral_section_one {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.lateral_counts {
  display: flex;
  justify-content: space-between;
}

.lateral_counts_card {
  height: 90px;
  width: 32%;
  border-radius: 24px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-color: #fff0dc;
}

.lateral_count_bar_chart {
  height: 340px;
  border-radius: 24px;

  background-color: #fff;
}

/* ------------------- Lateral --- section end----------------------------------------------- */

/* ------------------- Campus --- section start----------------------------------------------- */

.campus_section_one {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.campus_counts {
  display: flex;
  justify-content: space-between;
}

.campus_counts_card,
.lateral_counts_card {
  height: 90px;
  width: 32%;
  border-radius: 24px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 0px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.up_coming_drive_heading {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 5px 0px;
}

.campus_counts_card:hover,
.lateral_counts_card:hover {
  transform: scale(1.05);
  background-color: #fff0dc;
}

.campus_counts_card_icon,
.lateral_counts_card_icon {
  width: 50px;
  height: 50px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  animation: rotateIcon 2s linear infinite;
}

.campus_counts_card_value {
  /* font-size: 1.5rem; */
  /* font-weight: bold; */
  animation: countValue 2s ease-in-out infinite;
}

.campus_count_bar_chart {
  height: 340px;
  border-radius: 24px;
  background-color: #fff;
}

.campus_up_coming_drives {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 390px;
  overflow-y: auto;
}

.lateral_section_two {
  overflow-y: auto;
  padding-right: 8px;
}

.campus_up_coming_drives::-webkit-scrollbar {
  width: 4px;
}

.campus_up_coming_drives::-webkit-scrollbar-thumb {
  background-color: #f38d00;
  border-radius: 4px;
}

.campus_up_coming_drives::-webkit-scrollbar-track {
  background: #f4f6fc;
}

.lateral_section_two {
  overflow-y: auto;
  scrollbar-width: none;
}

.lateral_section_two::-webkit-scrollbar {
  display: none;
}

.table_row {
  display: flex;
  align-items: center;
}

.drive_card {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.drive_card p {
  margin: 0;
}

.college_name {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.company_name {
  font-size: 14px;
  font-weight: 500;
  color: #555;
}

.time_to_go {
  font-size: 12px;
  font-weight: 400;
  color: #888;
  margin-top: 8px;
}

/* ------------------- Campus --- section end----------------------------------------------- */

.recent_jobs_table {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  border: 1px solid #ddd;
}

.table_header {
  display: flex;
  background-color: #011e39;
  font-weight: bold;
  color: #fff;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  padding: 6px;
}

.table_cell {
  flex: 1;
  text-align: left;
  font-size: 14px;
  padding: 10px 15px;
  border-right: 1px solid #ddd;
}

.table_row {
  display: flex;
  border-bottom: 1px solid #ddd;
}

.table_row:last-child {
  border-bottom: none;
}

.table_cell:last-child {
  border-right: none;
}

/* ---------- = = = =  */

.admin_status {
  padding: 4px 8px;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.in-review {
  background-color: #a3c1ad;
}

.shortlist {
  background-color: #f6b93b;
}

.interview {
  background-color: #f7a7a1;
}

.decline {
  background-color: #ff6b6b;
}

.offered {
  background-color: #a2dff7;
}

.admin_status:hover {
  opacity: 0.8;
  color: #fff;
}

.application_filter_dropdown {
  background-color: #fff;
  padding: 4px;
  outline: none;
  border: none;
}

/* calendly  - --*/

.welcome-message {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: linear-gradient(135deg, #011e39, #073865);
  color: white;
  border-radius: 10px;
  margin: 20px auto;
  max-width: 600px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.welcome-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.welcome-icon {
  font-size: 3rem;
  animation: bounce 1s infinite;
}

.welcome-text h1 {
  font-size: 1.8rem;
  margin: 0;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}

.welcome-text p {
  font-size: 1rem;
  margin: 5px 0 0;
  opacity: 0.8;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.admin_join_meeting {
  color: #f38d00;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.admin_join_meeting:hover {
  color: #d97706;
  text-decoration: underline;
}

@media (min-width: 480px) and (max-width: 1560px) {
  .campus_section_container,
  .lateral_section_container {
    display: flex;
    padding: 1rem 0;
    height: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .campus_section_one,
  .campus_section_two,
  .lateral_section_two,
  .lateral_section_one {
    width: 100%;
  }
}
