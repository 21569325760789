.add_faq_btn {
  padding: 10px 15px;
  border: none;
  background-color: #f38d00;
  color: white;
  border-radius: 24px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.add_faq_btn:hover {
  background-color: #d97706;
}

.all_applicants_toggle_btn {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  cursor: pointer;
  background-color: #fff;
  color: black;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 30px;
}

.all_applicants_toggle_btn.active {
  background-color: #f38d00;
  color: white;
}

.css-1808mag-MuiPaper-root-MuiAccordion-root::before {
  display: none;
}

/* --------------------------------------------------------------- */
