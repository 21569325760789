/* General styles for the main content section */
.csd {
  height: 150px;
  width: calc(100% - 60px);
  margin: 30px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px #00000026;
}

.csd-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
}

.csd-buttons-container {
  display: flex;
  gap: 10px;
  border-color: #f38d00;
}

/* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */

.csd-buttons-container button {
  border-color: #f38d00;
  border-radius: 10px;
  border-width: 1px;
  padding: 3px;
  background-color: #fff;
}

.csd-create-group {
  background-color: #f38d00;
  color: white;
  border: none;
  height: 40px;
  width: 150px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.csd-create-group:hover {
  background-color: #e07b00;
}

.csd-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
}

/* Carousel container */
.carousel-container {
  border-radius: 20px;
  margin: 30px;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px #00000026;
}

/* Profile card container */
.profile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
  padding-left: 1rem;
  padding-right: 2rem;
  box-sizing: border-box;
}

#pofile-card-new-id-uniqe {
  display: grid !important;
  align-items: center !important;
}

#pofile-card-new-id-uniqe img {
  margin-left: 0 !important;
}

/* Profile image */
.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: unset !important;
  margin-left: unset !important;
  object-fit: cover;
  border: 2px solid #f38d00;
}

/* Profile info section */
.profile-info {
  text-align: center;
  margin-top: 10px;
}

.profile-name,
.profile-designation {
  margin: 0;
  font-size: 14px;
}

/* Ensure default arrows are visible */
.slick-prev,
.slick-next {
  z-index: 1;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.slick-next:before {
  content: none !important;
}
.slick-prev:before {
  content: none !important;
}

/* Custom "View All" button */
.view-all-button {
  background: #f38d00;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  cursor: pointer;
  width: 150px;
  margin-bottom: 25px;
}

.view-all-button:hover {
  background: #e07b00;
}

/* Container for "View All" button */
.view-all-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* New Group Images Section */
.group-images-section {
  margin-left: 30px;
  margin-right: 30px;
  background-color: #fff;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px #00000026;
}

.alumni-network {
  padding: 1rem;
}

.group-images-container {
  display: flex;
  flex-wrap: wrap;
  /* Ensure items wrap on smaller screens */
  gap: 2rem;
  /* Add space between items */
}

.group-image-item {
  text-align: center;
  width: 200px;
  border: 2px solid rgba(192, 192, 192, 0.2);
  border-radius: 10px;
  margin: 1rem;
  /* Adjust margin for better spacing on smaller screens */
  box-sizing: border-box;
}

.group-image-item h6 {
  margin: 0;
  font-size: 16px;
}

.group-image-link {
  display: block;
  width: 100%;
}

.group-image-link img {
  width: 100%;
  height: auto;
  /* Ensure the height adjusts according to the aspect ratio */
  object-fit: cover;
  border-radius: 10px;
}

.group-image-date {
  margin-top: 10px;
  font-size: 14px;
  color: #151010;
}

.job_closed_button{
  cursor: not-allowed !important;
  background-color: #666 !important;
}

/* Responsive Styles */

/* For tablets and small desktops */
@media (max-width: 1024px) {
  .csd {
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 20px);
    margin: 20px;
  }

  .csd-left,
  .csd-right {
    width: 100%;
  }

  .csd-right {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .profile-card {
    margin: 0 0.5rem;
  }

  .group-image-item {
    width: 150px;
    margin: 1rem 0;
  }
}

/* For mobile phones */
@media (max-width: 768px) {
  .csd {
    padding: 10px;
    height: auto;
  }

  .csd-buttons-container {
    flex-direction: column;
  }

  .csd-buttons-container button {
    margin-bottom: 10px;
    width: 100%;
  }

  .csd-create-group {
    width: 100%;
  }

  .carousel-container {
    margin: 10px;
  }

  .profile-card {
    margin: 0 0.5rem;
  }

  .profile-image {
    width: 80px;
    height: 80px;
  }

  .group-images-container {
    flex-direction: column;
    align-items: center;
  }

  .group-image-item {
    width: 100%;
    margin: 1rem 0;
  }
}

/* For very small screens (e.g., portrait mode on mobile phones) */
@media (max-width: 480px) {
  .csd {
    padding: 5px;
    height: auto;
  }

  .csd-left {
    width: 100%;
  }

  .profile-card {
    margin: 0 0.25rem;
  }

  .profile-image {
    width: 60px;
    height: 60px;
  }

  .group-images-container {
    flex-direction: column;
    align-items: center;
  }

  .group-image-item {
    width: 100%;
    margin: 0.5rem 0;
  }
}

/*  create grp start */

/* 


create group of student and alumni page

General Styles */
.create-group-page {
  margin-top: 4.5rem;
  padding: 20px;
  padding-bottom: 14.5rem;
}

.create-group-container {
  width: 97%;
  margin: 0 auto;
  background-color: #fff;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0px 2px 4px 0px #00000026;
}

.create-group-page h3 {
  margin-bottom: 20px;
}

.create-group-form {
  display: flex;
  flex-direction: column;
}

.form-sections {
  padding: 20px;
  display: flex;
  gap: 4%;
  /* Gap between left and right sections */
}

.form-left,
.form-right {
  width: 48%;
  box-sizing: border-box;
}

.form-left {
  margin-right: 2%;
  /* Space between left and right sections */
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  border-radius: 15px !important;
}

/* .form-group textarea {
  height: 50px;
} */

.form-actions {
  margin-left: 20px;
  margin-bottom: 17px;
  margin-top: -10px;
}

.submit-button {
  background-color: #f38d00;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #e07b00;
}

.csd-cancel-button {
  margin-left: 10px;
  background-color: #fff !important;
  color: #f38d00;
  padding: 10px 20px;
  border-radius: 50px;
  border: 1px solid #f38d00;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.csd-cancel-button:hover {
  background-color: #f38600 !important;
  color: white;
}

/* Responsive  */
@media (max-width: 768px) {
  .form-sections {
    flex-direction: column;
  }

  .form-left,
  .form-right {
    width: 100%;
    margin-right: 0;
  }

  .form-actions {
    flex-direction: column;
  }
}

.create-ans {
  margin: 20px;
}

.gallery-container {
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.gallery-items {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  /* Add space between items */
}

.gallery-item {
  text-align: center;
  width: 200px;
  border: 2px solid rgba(192, 192, 192, 0.2);
  border-radius: 15px;
  margin: 1rem;
  /* Adjust margin for better spacing on smaller screens */
  box-sizing: border-box;
  background-color: #fff;
}

.gallery-link {
  display: block;
  width: 100%;
}

.gallery-link img {
  width: 90%;
  margin-top: 12px;
  align-items: center;
  border-radius: 10px;
}

.gallery-date {
  margin-top: 10px;
  font-size: 14px;
  color: #151010;
}

/* Responsive */
@media (max-width: 768px) {
  .gallery-item {
    width: 100%;
    max-width: 300px;
  }
}

.group-header {
  display: flex;
  align-items: center;
  margin: 15px;
}

.back-arrow {
  display: flex;

  margin-right: 10px;
  color: black;
}

.back-arrow svg {
  stroke-width: 3;
}

/* Add additional styling for form and gallery as needed */
/* jobs */

/* h5 {
  margin-bottom: 20px;
  font-size: 1.5rem; 
} */

.job-list {
  flex-wrap: wrap;
  gap: 20px;
}

.job-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 15px;
  margin: 20px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px #00000026;
  /* Shadow from Figma */
  flex: 1 1 calc(33.333% - 40px);
  /* Responsive width for 3 columns */
}

.job-left {
  display: flex;
  align-items: center;
}

.nojobsfound {
  display: flex;
  align-items: center; /* Vertically center */
  justify-content: center; /* Horizontally center */
  margin-top: 15%;
}

.company-logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.job-info h6 {
  margin: 0;
  font-size: 16px;
}

.job-info p {
  margin: 0;
  color: #555;
}

.job-right {
  font-size: 14px;
  color: #f38d00;
  border: 1px solid #f38d00;
  padding: 10px;
  border-radius: 50px;
}

.job-right span {
  color: #f38d00;
}

.all-job-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* Allow wrapping on smaller screens */
  padding: 10px 15px 0px 20px;
}

.all-job-header h5 {
  margin-bottom: 0;
}

.all-job-header-right {
  gap: 10px;
  top: 6%;
  padding-left: 16px;
  display: flex;
  align-items: center;
}

.all-jobs-search-input-div {
  position: relative;
}

.all-jobs-search-input-div img {
  position: absolute;
  left: 18px;
  top: 14px;
}

.download-button {
  background-color: #f38d00;
  color: #fff;
  padding: 8px;
  width: 11rem;
  border-radius: 50px;
  border: none;
  margin: 15px;
}

.all-job-search-input {
  padding: 7px 7px 7px 20px;
  width: 17rem;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  outline: none;
  height: 2.6rem;
  border-radius: 50px;
  padding-left: 47px;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .job-item {
    flex: 1 1 calc(50% - 40px);
    /* 2 columns layout */
  }
}

@media (max-width: 768px) {
  .job-item {
    flex: 1 1 calc(100% - 40px);
    /* 1 column layout */
  }

  .all-job-search-input {
    width: 100%;
    /* Full width input on small screens */
  }

  .download-button {
    width: 100%;
    /* Full width button on small screens */
  }
}

/* campus_job_details */

/* Main content and page styling */

.job-detail-page {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* height: 100vh; */
  padding-top: 6rem;
}

.job-detail-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.job-detail-header h5 {
  margin-bottom: 0;
  flex: 1;
}

.job-detail-back-button {
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 15px;
}

.job-detail-back-button img {
  width: 24px;
  height: 24px;
}

.job-detail-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-bottom: 8px;
  border-radius: 20px;
  padding: 20px;
  background-color: #fff;
}

.job-left {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.company-logo {
  width: 50px;
  height: auto;
  margin-right: 15px;
}

.job-info h6 {
  margin: 0;
  font-size: 1.1em;
}

.job-info p {
  margin: 0;
  color: #555;
}

.job-right {
  font-size: 0.9em;
  color: #666;
}

.job-detail-description,
.job-detail-company {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.job-detail-description h5,
.job-detail-company h5 {
  margin-bottom: 12px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
}

.job-detail-description h6 {
  margin-top: 15px;
  font-size: 16px;
  display: flex;
  align-items: baseline;
  gap: 7px;
}

.job-detail-company h6 {
  margin-top: 15px;
  font-size: 1.1em;
  display: flex;
}

.job-detail-description p {
  color: #3e3e3e;
  font-size: 15px;
  padding-bottom: 0;
}

.job-detail-company p {
  color: #3e3e3e;
  font-size: 15px;
  padding-bottom: 1.5rem;
}

.job-detail-description ul li {
  color: #3e3e3e;
  font-size: 15px;
  padding-bottom: 3px;
}

.job-detail-description ul {
  padding-bottom: 1rem;
}

.job-detail-share-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}

.job-detail-share-button button {
  padding: 10px;
  background-color: #f38d00;
  border: none;
  border-radius: 50px;
  color: white;
  width: 10rem;
  margin-top: 20px;
  cursor: pointer;
}

.job-detail-Download-Pdf {
  display: flex;
  justify-content: flex-end;
}

.job-detail-Download-Pdf button {
  padding: 10px;
  background-color: #f38d00;
  border: none;
  border-radius: 50px;
  color: white;
  width: 10rem;
  cursor: pointer;
}

.job-detail-icons img {
  margin: 5px;
  padding-top: 10px;
}

.job-sharing-icon {
  margin-top: 15px;
  margin-bottom: 20px;
  display: flex;
  gap: 15px;
  /* Space between images */
}

/* Modal overlay and content styling */
.job-share-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.job-share-modal-content {
  background: white;
  padding: 30px;
  border-radius: 20px;
  width: 550px;
  max-width: 90%;
  height: auto;
  max-height: 80vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
}

.job_sharing_cross_button{
  width: fit-content;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.job-share-modal-content label {
  display: block;
  margin-bottom: 10px;
}

.job-share-modal-content input {
  width: 100%;
  padding: 8px;
  padding-left: 12px;
  margin-top: 15px;
  box-sizing: border-box;
  border-radius: 10px;
  border-width: 1px;
  outline: none;
}

.job-share-modal-content div button:last-child {
  margin-left: 10px;
  color: black;
  background-color: white;
  border: 1px solid #f38d00;
}
.job-share-modal-content button:hover {
  background-color: #d67a00; /* Darker orange background on hover */
  transform: scale(1.01); /* Slightly scale up the button */
}

.job-share-modal-content div button:last-child:hover {
  background-color: #e07b00;
  color: white;
}

.job-share-modal-content button {
  margin-top: 20px;
  padding: 10px;
  border: none;
  background-color: #f38d00;
  color: white;
  border-radius: 50px;
  cursor: pointer;
  width: 8rem;
}

.slick-track{
  display: flex !important;
  justify-content: space-between !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  /* .job-detail-page {
    padding: 5px;
  } */

  .job-detail-share-button,
  .job-detail-Download-Pdf {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .job-detail-share-button button,
  .job-detail-Download-Pdf button {
    width: auto;
    margin: 5px;
  }

  .job-detail-summary {
    flex-direction: column;
    align-items: flex-start;
  }

  .job-right {
    margin-top: 10px;
  }

  .job-detail-description,
  .job-detail-company {
    padding: 15px;
  }

  .job-detail-description h5,
  .job-detail-company h5 {
    font-size: 1.1em;
  }

  .job-share-modal-content {
    width: 90%;
    max-width: 500px;
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .job-detail-header h5 {
    font-size: 1em;
  }

  .company-logo {
    width: 40px;
    margin-right: 10px;
  }

  .job-info h6 {
    font-size: 1em;
  }

  .job-detail-summary {
    padding: 10px;
  }

  .job-detail-description,
  .job-detail-company {
    padding: 10px;
  }

  .job-detail-description h5,
  .job-detail-company h5 {
    font-size: 1em;
  }

  .job-share-modal-content {
    width: 95%;
    max-width: 400px;
    padding: 15px;
  }

  .job-share-modal-content button {
    width: 7rem;
  }
}

/* course offered */

.course-offered-text {
  margin-top: 50px;
  margin-left: 40px;
}

.course-offered-page {
  max-width: 90%px;
  margin-left: 35px;
  margin-right: 35px;
  margin-top: 15px;
  padding: 35px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.course-offered-page h1 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.course-offered-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.form-left,
.form-right {
  flex: 1;
}

.course-offered-form-group {
  margin-bottom: 15px;
}

.course-offered-page input[type="text"],
textarea {
  width: 90%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 15px;
  box-sizing: border-box;
}

input::placeholder,
textarea::placeholder {
  color: #888;
}

input:focus,
textarea:focus {
  outline: none;
}

.course-offered-savebutton {
  background-color: #f38d00;
  color: #fff;
  border: none;
  padding: 10px 40px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.course-offered-savebutton:hover {
  background-color: #f38d00;
}

.course-offered-add-course {
  background-color: #fff;
  color: #f38d00;
  border-color: #f38d00;
  border: 1px solid #f38d00;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  margin: 10px;
}

.courses-offerd-name {
  width: 500px;
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  margin: 20px;
}

.courses-grid {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 20px;
}
.course-card {
  width: 48%;
}

.courses-grid-left,
.courses-grid-right {
  flex: 1;
}

.courses-offered-name {
  width: 90%;
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.offered-courses {
  margin: 35px;
}

.courses-offered-name h5 {
  margin-bottom: 17px;
}

.courses-offered-name p {
  margin-top: 7px;
}

#duration-about-the-course {
  width: 90%;
  padding: 0.1px;

  border-radius: 15px;
  font-size: 16px;
  box-sizing: border-box;
}

.courses-offered-name img {
  float: right;
  cursor: pointer;
  margin-right: 10px;
}

.campus_jobDetails_flex {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.collage_download_button_data{
  border-radius: 25px !important;
}

/*  */
