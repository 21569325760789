.loading_spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.spinner {
  width: 14px;
  height: 14px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #f38d00;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.no_data {
  text-align: center;
  padding: 40px;
  color: #666;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 24px;
}

.pagination_button {
  padding: 8px 12px;
  border: 1px solid #ddd;
  background: white;
  border-radius: 4px;
  cursor: pointer;
}

.pagination_button.active {
  background-color: #f38d00;
  color: white;
  border-color: #f38d00;
}

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal_content {
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal_title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
  color: #333;
}

.student_form,
.student_details {
  display: grid;
  gap: 16px;
}

.form_group {
  display: grid;
  gap: 8px;
}

.form_label {
  font-size: 14px;
  font-weight: 500;
  color: #666;
}

.form_input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}

.form_input.disabled {
  background-color: #f5f5f5;
  color: #666;
  cursor: not-allowed;
}

.modal_actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.cancel_button,
.close_button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  background: white;
  border-radius: 4px;
  cursor: pointer;
}

.submit_button {
  padding: 8px 16px;
  background-color: #f38d00;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit_button:hover {
  background-color: #e07f00;
}

@media (max-width: 640px) {
  .students_header {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }

  .students_actions {
    width: 100%;
    flex-direction: column;
  }

  .students_search {
    width: 100%;
  }

  .modal_content {
    margin: 16px;
    max-height: calc(100vh - 32px);
  }
}

.toggle_button {
  background-color: #f38d00;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 24px;
}

.grid_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.form_group {
  margin-bottom: 1rem;
}
