.subuser_input_container_header {
  display: flex;
  justify-content: space-between;
}

.subuserlistbutton {
  float: right;
  color: #f38d00;
  padding: 6px 18px;
  border-radius: 50px;
  background-color: transparent;
  border: 1px solid #f38d00;
}

.subuser_input_container {
  margin-top: 2rem;
  padding: 20px;
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0px 4px 6px #00000026;
}

.subuser_second_input_container{
  padding: 20px 75px;
}

.subuser_both_input {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.subuser_both_input_card {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 95%;
  margin: auto;
}

.subuser_both_input_card label {
  color: #f38d00;
  padding: 15px 0;
}

.subuser_input_label_inner {
  padding-bottom: 2rem;
}

.subuser_input_label_inner label {
  padding: 3px 0;
}

.subuser_inner_container {
  width: 48%;
}

.subuser_input_left,
.subuser_input_right {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 48%;
}

.subuser_both_input input,
.subuser_both_input_card input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
}

.subuser_buttons {
  display: flex;
  padding-top: 0;
  gap: 0.8rem;
}

.subuser_buttons button,
.edit_btn {
  background-color: #f38d00;
  border: none;
  border-radius: 30px;
  font-weight: 700;
  color: #fff;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.subuser_cancel_btn {
  border: 1px solid #f38d00;
  background-color: white;
  color: #f38d00;
}

.subuser_buttons button:hover {
  background-color: #d97400;
  transform: scale(1.05);
}

.error-message {
  color: red;
  margin-top: 5px;
  font-size: 12px;
}

/* user list table  */

.sub_hr_userlist_job_listing_table {
  border-collapse: collapse;
  /* Ensures borders are collapsed into a single border */
  margin: 20px 0;
  width: 100%;
  font-size: 14px;
  background-color: #ffff;
  padding: 1rem 0;
  font-family: "Arial", sans-serif;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.sub_hr_userlist_job_listing_table table {
  width: 100%;
  border-spacing: 0;
  background-color: #fff;
}

.sub_hr_userlist_job_listing_table thead th {
  background-color: #011e39;
  font-weight: bold;
  padding: 12px 15px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  color: #ffff;
}

.sub_hr_userlist_job_listing_table th:first-child {
  border-radius: 10px 0 0 0;
  padding: 20px 20px;
}

.sub_hr_userlist_job_listing_table th:last-child {
  border-radius: 0 10px 0 0;
}

.sub_hr_userlist_job_listing_table td {
  padding: 15px 15px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  /* Add border to rows only */
  border-right: none;
  /* Remove right border to eliminate column lines */
}

.sub_hr_userlist_job_listing_table tr:last-child td {
  border-bottom: none;
  /* Remove border from the last row */
}

.active {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subuser_edit_button {
  padding-top: 0;
  padding-left: 2.4rem;
}

.view-mode {
  width: 100%;
  border: 1px solid #d3d3d303 !important;
  border-radius: 10px;
  padding: 0 !important;
}