.arrow_icon {
  height: 15px;
  cursor: pointer;
}

.admin_plan_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add_plan_btn {
  background-color: #f38d00;
  border: none;
  color: #fff;
  padding: 10px 20px;
  border-radius: 30px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.add_plan_btn:hover {
  background-color: #d97706;
  transform: scale(1.02);
  cursor: pointer;
}

.admin_plan_card_container {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
}

.card {
  flex: 1;
  max-width: 30%;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: border 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid transparent;
}

.card:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.card.active {
  border: 1px solid #f38d00;
  box-shadow: 0 0 15px #f38d00;
}

.card_header {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  width: 90%;
}

.card_price {
  font-size: 1.5rem;
  margin: 10px 0;
  width: 90%;
}

.card_features {
  list-style: none;
  padding: 0;
  width: 90%;
}

.card_features li {
  display: flex;
  align-items: center;
  margin: 5px 0;
  color: #333;
}

.card_features li::before {
  content: "✔";
  margin-right: 8px;
  color: #00aaff;
}

.card_footer {
  margin-top: 10px;
}

.save_btn {
  border: 2px solid #f38d00;
  background: transparent;
  color: #f38d00;
  padding: 8px 16px;
  border-radius: 20px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.save_btn:hover {
  background: #f38d00;
  color: #fff;
}

@media screen (min-width: 767px) and (max-width: 1024px) {
  .card {
    max-width: 48%;
  }
  .admin_plan_card_container {
    gap: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .admin_plan_card_container {
    flex-direction: column;
    align-items: center;
  }

  .card {
    max-width: 100%;
    margin-bottom: 1rem;
  }
}
