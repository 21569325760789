._main_content_inner_screen_candidatepage {
  top: 14rem !important;
}

.candidate-page {
  margin-top: 30px;
}

.candidate-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px 0 43px !important;
}

.candidate-page-header h5 button {
  border: none;
}

.candidate-header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.candidate-search-input {
  position: relative;
}

.candidate-search-input-field {
  padding: 7px 7px 7px 20px;
  width: 17rem;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  outline: none;
  height: 2.6rem;
  border-radius: 50px;
  padding-left: 47px;
}

.candidate-search-input img {
  position: absolute;
  left: 17px;
  top: 13px;
}

.add-candidate-button {
  background-color: #f38d00;
  color: #fff;
  padding: 7px;
  width: 11rem;
  border-radius: 50px;
  border: none;
  height: 2.6rem;
  margin: 15px;
}

.add-Delete-button {
  display: flex;
  align-items: center;
  gap: 10px;
}

.candidate-container {
  margin: 15px 40px;
  /* Margin on the sides of the table */
}

.candidate-table {
  width: 100%;
  margin-top: 1.5rem;
}

.candidate-table th {
  padding: 12px;
  text-align: left;
}

.candidate-table tr td {
  padding: 20px;
  text-align: left;
}

.candidate-table th:first-child {
  border-top-left-radius: 15px;
}

.candidate-table th:last-child {
  border-top-right-radius: 15px;
}

.candidate-table th {
  background-color: #011e39;
  color: #f3efef;
}

.candidate-table tr {
  border-bottom: 1px solid #ddd;
}

.candidate-table td {
  background-color: #fff;
}

.table_data {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f38d00;
}

.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #f38d00 !important;
}

.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: #f38d00 !important;
  width: 100px !important;
}

/* This targets the root of the Switch component */
.toggle_btn.MuiSwitch-root {
  /* background-color: #f38d00 !important; Override the background color */
}

/* This targets the switch base part when checked */
.toggle_btn.Mui-checked.MuiSwitch-switchBase {
  /* color: #f38d00 !important; Override the checked color */
}

/* Optionally, you can also customize the track of the Switch */
.toggle_btn.MuiSwitch-track {
  /* background-color: #f38d00 !important; Override track color */
}

.pagination-controls {
  padding-top: 1rem;
  margin-top: 2rem;
}

.no-data {
  text-align: center;
  padding: 20px;
  font-style: italic;
  color: #888;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 40%;
  height: auto;
  /* Change height to auto for better responsiveness */
  padding-top: 2.1rem;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-form {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.form-column {
  flex: 1;
  margin-right: 10px;
}

.modal-form input {
  width: 97%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 3px;
}

.error {
  color: red;
  font-size: 0.7em;
}

.form-actions-add-candidate button {
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  background-color: #f38d00;
  color: #fff;
  cursor: pointer;
  width: 6.3rem;
  margin-top: 1.5rem;
}

.form-actions button:last-child {
  background-color: #1788eb;
}

#candidatephone {
  width: 48%;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .candidate-header-right {
    flex-direction: column;
    align-items: flex-start;
  }

  .candidate-search-input-field {
    width: 100%;
    /* Full width on smaller screens */
    margin-bottom: 10px;
    /* Space below the input */
  }

  .add-candidate-button {
    width: 100%;
    /* Full width on smaller screens */
  }

  .candidate-container {
    margin: 0 20px;
    /* Reduce side margin */
  }

  .modal-content {
    width: 90%;
    /* Full width for modal */
  }

  .form-row {
    flex-direction: column;
    /* Stack form fields vertically */
    margin-bottom: 15px;
    /* More space between fields */
  }

  .form-column {
    margin-right: 0;
    /* No right margin */
    margin-bottom: 10px;
    /* Space below each input */
  }
}

@media (max-width: 480px) {
  .candidate-table th,
  .candidate-table td {
    padding: 8px;
    /* Smaller padding for mobile */
    font-size: 14px;
    /* Smaller font size */
  }

  .add-candidate-button {
    padding: 6px;
    /* Smaller padding */
  }

  .modal-content {
    padding: 20px;
    /* Less padding */
  }

  .modal-form input {
    padding: 8px;
    /* Smaller input padding */
  }
}

.filter-container {
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  gap: 8px;
}

.filter-icon {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.filter-dropdown {
  border-radius: 4px;
  font-size: 14px;
  border: none;
  outline: none;
}

.Status {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.shortlisted {
  background-color: #b0e57c; /* Soft Green */
  border-radius: 100px;
  padding: 8px 16px;
  width: 10px;
  height: 10px;
}

.in_review {
  background-color: #ffcc80; /* Pastel Orange */
  border-radius: 100px;
  width: 10px;
  padding: 8px 16px;
  height: 10px;
}

.declined {
  background-color: #ffb6c1; /* Light Pink */
  border-radius: 100px;
  width: 10px;
  padding: 8px 16px;
  height: 10px;
}

.interviewed {
  background-color: #ffdab9; /* Light Peach */
  border-radius: 100px;
  width: 10px;
  height: 10px;
  padding: 8px 16px;
}

.offered {
  background-color: #add8e6; /* Light Blue */
  border-radius: 100px;
  width: 10px;
  height: 10px;
  padding: 8px 16px;
}
