.company_details {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 100px;
  margin: 1.8rem 0;
  display: flex;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.company_details .company_logo_container {
  width: 30%;
  height: 100%;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.company_title_heading {
  color: #272727;
  font-weight: 500;
}

.company_title_sub_heading {
  color: #484747;
  font-size: 12px;
}

.company_details .company_others_details {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.company_details .company_others_details .company_others_details_con {
  flex: 1;
  text-align: left;
  padding: 10px;
  border-right: 1px solid #ddd;
  height: 60px;
}

.company_details .three_icon_btn {
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.company_details .company_logo_container .company_logo {
  border: 3px solid #ddd;
  border-radius: 10px;
  padding: 5px;
}

.company_details .company_logo_container .company_logo img {
  border-radius: 5px;
  height: 55px;
  width: 55px;
}

.detail_active_dot {
  position: absolute;
  height: 10px;
  width: 10px;
  background: green;
  border-radius: 50%;
}
