.savedJobs_header {
  height: 351px !important;
}

.savedJobs_content_inner_second {
  width: 100%;
}

.savedJobs_section_head {
  width: 50% !important;
  margin: auto !important;
}

@media (max-width: 1400px) and (min-width: 1201px) {
  .savedJobs_section_head {
    width: 75% !important;
    margin: auto !important;
  }
}

@media (max-width: 1200px) and (min-width: 991px) {
  .savedJobs_section_head {
    width: 80% !important;
    margin: auto !important;
  }
}

@media (max-width: 992px) and (min-width: 575px) {
  .savedJobs_section_head {
    width: 80% !important;
    margin: auto !important;
  }
}

@media (max-width: 576px) and (min-width: 150px) {
  .savedJobs_section_head {
    width: 80% !important;
    margin: auto !important;
  }

  .job-header {
    flex-direction: column;
    align-items: baseline;
  }

  .job-details {
    flex-direction: column;
    align-items: baseline;
  }

  .job-description {
    font-size: 13px;
  }

  .company-info {
    align-items: start;
  }

  .saved-jobs-company-review {
    flex-direction: column;
    gap: 0 !important;
  }

  .company-info div h2 {
    font-size: 12px;
  }

  .company-name {
    font-size: 11px;
    white-space: normal;
  }

  .rating {
    font-size: 10px;
  }

  .save-button{
    font-size: 10px;
  }
}

@media (max-width: 1750px) and (min-width: 1399px) {

  .savedJobs_section_head {
    width: 80% !important;
  }
}