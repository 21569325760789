.collage_stat_container {
    gap: 2rem;
    margin-bottom: 25px;
}

.campus_chart_section {
    width: 100%;
}

.campus__pieChartContainer {
    width: 100% !important;
    border-right: none !important;
}

.campus_bar_chart {
    width: 100% !important;
}

.campus_manager_chart_section {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 1.25rem;
    margin: 0 0;
}

.no_data_found {
    display: grid;
    place-items: center;
    padding-bottom: 3rem;
}

.campus_manager_chart_header {
    margin-bottom: 10px;
    border-bottom: 2px solid #c0c0c06b;
    padding-bottom: 16px;
}

.campus_manager_chart_title {
    font-size: 18px;
    color: #333;
    font-weight: 500;
}

.campus_manager_stats_container {
    padding: 10px 0;
}

.campus_manager_chart_container {
    width: 100%;
}

.campus_manager_scale_markers {
    padding-bottom: 0rem;
    display: flex;
    justify-content: space-between;
    padding-left: 22%;
    padding-bottom: 10px;
}

.campus_manager_scale_number {
    font-size: 0.75rem;
    color: #000000;
}

.campus_manager_department_wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 20px;
    padding-right: 17px;
}

.campus_manager_department_row {
    display: flex;
    align-items: center;
}

.campus_manager_department_label {
    font-size: 0.875rem;
    color: #495057;
    padding-right: 15px;
}

.campus_manager_progress_container {
    flex-grow: 1;
}

.campus_manager_progress {
    height: 19px;
    background-color: transparent;
    border-radius: 12px;
    overflow: hidden;
}

.campus_manager_progress_bar {
    height: 100%;
    background-color: #FFC26F;
    border-radius: 12px;
    transition: width 0.5s ease-out;
}

.campus_placement_graph {
    padding-top: 2rem;
    display: grid;
    text-align: center;
}

.campus_placement_graph__title b {
    font-size: 35px;
    font-weight: 500;
    color: #F38D00;
}

.campus_placement_graph__title p {
    font-size: 20px;
    display: grid;
}

.collage_campus_upcoming_date_callande {
    background-color: #F5F5F5;
    display: grid;
    place-items: center;
    padding: 16px 15px;
    width: 95px;
}

.collage_campus_upcoming_date_callande b {
    font-size: 14px;
    font-weight: 600;
}

.collage_campus_upcoming_date_callande p {
    font-size: 12px;
}

.collage_manager_campus_packages {
    display: grid;
    place-items: center;
}

.collage_manager_campus_packages b {
    font-weight: 400;
    font-size: 14px;
}

.collage_manager_campus_packages p {
    font-size: 12px;
}

._application_history_collage_scrollbar {
    overflow-y: auto;
    max-height: 365px;
    margin-bottom: 1rem;
}

._application_history_collage_scrollbar::-webkit-scrollbar {
    width: 8px;
}

._application_history_collage_scrollbar::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
}

._application_history_collage_scrollbar::-webkit-scrollbar-thumb {
    background-color: #FFC26F;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

._application_history_collage_scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #ac6502;
}

._application_history_collage_scrollbar::-webkit-scrollbar-corner {
    background-color: #FFC26F;
}

@media (max-width: 768px) {
    .campus_manager_department_label {
        font-size: 0.75rem;
    }

    .campus_manager_progress {
        height: 20px;
    }

    .campus_manager_chart_title {
        font-size: 16px;
    }
}

@media (max-width: 576px) {
    .campus_manager_scale_number {
        font-size: 0.7rem;
    }

    .campus_manager_department_label {
        font-size: 0.7rem;
    }

    .campus_manager_progress {
        height: 18px;
    }
}