.contact_parent {
  font-family: "Poppins", sans-serif;
  margin-top: 5rem;
}

/* Contact Header */
.contact_header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 451px;
  background-color: #011e39;
  color: aliceblue;
  padding-bottom: 6rem;
}

.contact_header h4 {
  padding-bottom: 15px;
  font-weight: 400;
  margin-bottom: 0;
  font-size: 20px;
}

.contact_header h2 {
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 47px;
}

.contact_header h6 {
  padding-bottom: 0px;
  font-weight: 300;
  font-size: 16px;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .contact_header h2 {
    font-size: 30px;
  }

  .contact_header h4 {
    font-size: 18px;
  }

  .contact_header h6 {
    font-size: 11px;
  }
}

@media (max-width: 992px) {
  .contact_header h2 {
    font-size: 26px;
  }

  .contact_header h4 {
    font-size: 16px;
  }

  .contact_header h6 {
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .contact_header {
    height: auto;
    padding: 20px 0;
    height: 270px;
  }

  .contact_header h2 {
    font-size: 24px;
  }

  .contact_header h4 {
    font-size: 14px;
  }

  .contact_header h6 {
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  .contact_header h2 {
    font-size: 20px;
  }

  .contact_header h4 {
    font-size: 12px;
  }

  .contact_header h6 {
    font-size: 8px;
  }
}

/* Contact Form */

/* Main Contact Form Section */
.contact_form_main {
  width: 100%;
  min-height: 800px;
  padding-top: 40px;
  margin-top: -7rem;
}

/* Contact Form Container */
.contact_form_container {
  width: 63%;
  padding: 1.7rem;
  border-radius: 30px;
  display: flex;
  margin: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #ffffff;
  min-height: 600px;
}

/* Contact Form Section One */
.contact_form_section_one {
  width: 35%;
  border-radius: 24px;
  background-color: #f5faff;
  padding: 2rem;
  min-height: 500px;
}

.contact_form_section_one h3 {
  margin-top: 20px;
  font-size: 21px;
  font-weight: 500;
  color: #111111;
}

.contact_form_section_one div {
  margin-top: 40px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.contact_form_section_one div img {
  height: 24px;
  margin-right: 20px;
}

.contact_form {
  width: 52%;
  margin-top: 10px;
  font-size: 14px;
  margin-left: 3%;
  font-family: "Poppins";
  border-radius: 16px;
  height: auto;
}

.contact_form_section_one_subheading {
  font-size: 15px;
}

.contact_form label {
  font-size: 14px;
  font-weight: 500;
  color: #111111f2;
}

.contact_form input,
.contact_form textarea {
  border: none;
  outline: none;
  width: 90%;
  font-size: 16px;
  padding: 10px 5px;
  /* border-bottom: 1.6px solid #6c6c6c; */
  border-bottom: 1.6px solid #3c3c3c73;
}

.contact_form input:focus,
.contact_form textarea:focus {
  box-shadow: none;
}

/* Error Message */
.contact_error_msg {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

/* Phone Input */
.react-tel-input {
  margin-top: 10px;
}

.react-tel-input .form-control {
  border: none !important;
  font-size: 16px;
  width: 90% !important;
  padding: 10px 5px;
  border-bottom: 1.6px solid #3c3c3c73 !important;
  border-radius: 0px !important;
  display: block !important;
}

.react-tel-input .form-control:focus {
  box-shadow: none;
  outline: none;
}

.contact-submit-button {
  background-color: #F38D00;
  color: white;
  font-weight: 400;
  font-size: 16px;
  font-family: "Poppins";
  width: 96%;
  height: 44px;
  border: none;
  border-radius: 12px;
  padding: 0;
}

.contact_message_input {
  width: 96% !important;
}

.email-container p {
  word-break: break-word;
  white-space: normal;
  line-height: 1.5; 
  font-size: 16px; 
  margin: 0;
}

@media (max-width: 1606px) and (min-width: 988px) {
  .contact_form_container {
    width: 80%;
    padding: 2rem;
    border-radius: 20px;
    display: flex;
    margin: auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #ffffff;
    min-height: 600px;
  }

  .contact_form_section_one {
    width: 40%;
    border-radius: 24px;
    background-color: #f5faff;
    padding: 2rem;
    min-height: 500px;
  }

  .contact_form {
    height: auto;
    width: 50%;
    margin-top: 10px;
    font-size: 14px;
    margin-left: 3%;
    font-family: "Poppins";
    font-weight: 400;
    border-radius: 16px;
  }
}

@media (max-width: 988px) and (min-width: 768px) {
  .contact_form_container {
    width: 85%;
    padding: 2rem;
    border-radius: 20px;
    display: flex;
    margin: auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #ffffff;
    min-height: 600px;
  }

  .contact_form_section_one {
    width: 100%;
    border-radius: 24px;
    background-color: #f5faff;
    padding: 2rem;
    min-height: 500px;
  }

  .contact_form {
    height: auto;
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
    margin-left: 3%;
    font-family: "Poppins";
    font-weight: 400;
    border-radius: 16px;
  }

  #counter_contact {
    margin-top: 40px !important;
  }
}

@media (max-width: 767px) and (min-width: 540px) {
  .contact_form_main {
    min-height: 800px;
    padding-top: 30px;
  }

  .contact_form_container {
    width: 85%;
    padding: 1rem;
    border-radius: 20px;
    display: flex;
    margin: auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #ffffff;
    min-height: 600px;
  }

  .contact_form_section_one {
    width: 100%;
    border-radius: 24px;
    background-color: #f5faff;
    padding: 1rem;
    min-height: 280px;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  }

  .contact_form {
    height: auto;
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
    margin-left: 3%;
    font-family: "Poppins";
    font-weight: 400;
    border-radius: 16px;
  }

  .contact-submit-button {
    background-color: #F38D00;
    color: white;
    font-weight: 400;
    font-size: 16px;
    font-family: "Poppins";
    width: 100%;
    height: 36px;
    /* Fixed height for button */
    border: none;
    border-radius: 12px;
    padding: 0;
    margin-top: 20px;
  }
  #counter_contact {
    margin-top: 1260px !important;
  }
}

@media (max-width: 540px) and (min-width: 150px) {
  .contact_form_main {
    width: 100%;
    min-height: 800px;
    padding-top: 30px;
  }

  .contact_form_container {
    width: 85%;
    padding: 1rem;
    border-radius: 20px;
    display: flex;
    margin: auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #ffffff;
    min-height: 600px;
    margin-bottom: 30px;
  }

  .contact_form_section_one {
    width: 100%;
    border-radius: 16px;
    background-color: #f5faff;
    padding: 0.8rem;
    min-height: 280px;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
  }

  .contact_form {
    height: auto;
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 400;
  }

  .contact-submit-button {
    background-color: #F38D00;
    color: white;
    font-weight: 400;
    font-size: 16px;
    font-family: "Poppins";
    width: 100%;
    height: 36px;
    /* Fixed height for button */
    border: none;
    border-radius: 12px;
    padding: 0;
    margin-top: 20px;
  }

  .contact_form_section_one h3 {
    font-size: 18px;
    font-weight: 500;
  }

  .contact_form_section_one div {
    margin-top: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .contact_form_section_one div img {
    height: 14px;
    margin-right: 20.5px;
  }
}

.react-tel-input .flag-dropdown .selected-flag .selected-dial-code {
  display: inline-block;
  margin-left: 0;
  font-size: 16px;
  font-weight: 500;
}
