:root {
  --primary-color: #011e39;
  --white: #fff;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --border-color: #ddd;
  --border-radius: 25px;
  --padding-standard: 20px;
  --error-color: red;
}

.college_details_perant_con {
  width: 80%;
  margin: auto;
}
.page-title {
  margin-top: 1.5rem;
  font-size: x-large;
  margin-bottom: 20px;
}

.college-details-container {
  background: var(--white);
  border-radius: var(--border-radius);
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.college-logo-container {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 10px;
  width: 200px;
  height: 200px;
}

.college-logo {
  width: 180px;
  aspect-ratio: 1/1;
  height: 180px;
  object-fit: cover;
  border-radius: 10px;
}

.college-info {
  padding: 0;
  box-shadow: none;
}

.college-info h6 {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.info-item {
  margin-bottom: 10px;
  display: flex;
}

.info-item label {
  font-weight: bold;
  width: 30%;
}
.college-details-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 14px;
  font-family: "Arial", sans-serif;
  box-shadow: 0 0 10px var(--shadow-color);
  border-radius: 10px;
  overflow: hidden;
}

.college-details-table table {
  width: 100%;
  border-spacing: 0;
  background-color: var(--white);
}

.college-details-table_head th {
  background-color: #011e39 !important;
  font-weight: bold;
  padding: 12px 15px;
  text-align: center;
  color: var(--white);
}

.college-details-table th:first-child {
  border-radius: 10px 0 0 0; /* Round top-left corner */
}

.college-details-table th:last-child {
  border-radius: 0 10px 0 0; /* Round top-right corner */
}

.college-details-table td {
  padding: 15px 15px;
  text-align: center;
  border-bottom: 1px solid var(--border-color);
  border-right: none;
}

.college-details-table tr:last-child td {
  border-bottom: none; /* Remove last row's bottom border */
}

/* college-details-table_head */
/* college-details-table */

.college-about {
  background-color: var(--white);
  padding: var(--padding-standard);
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px var(--shadow-color);
}

.college-about h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.loading,
.error {
  text-align: center;
  padding: 20px;
  font-size: 18px;
}

.error {
  color: var(--error-color);
}

.administrative-details {
  background: white;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.administrative-details h2 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #011e39;
}

.admin-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.admin-info p {
  display: flex;
  gap: 10px;
  background: #f8f8f8;
  padding: 15px;
  border-radius: 8px;
  margin: 0;
}

.admin-info .label {
  font-weight: 600;
  color: #333;
}

.admin-info .value {
  color: #666;
}

.admin-info p {
  margin-bottom: 12px;
  line-height: 1.5;
}

.college-details-grid {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 2rem;
}

/* Responsive styles */
@media screen and (max-width: 1024px) {
  .college-details-grid {
    grid-template-columns: 250px 1fr;
    gap: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .college-details-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .admin-info {
    grid-template-columns: 1fr;
  }

  .college_details_perant_con {
    width: 95%;
  }
}
