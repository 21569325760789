.premium-plan-page {
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  max-height: auto;
  padding: 20px; /* Add padding for mobile view */
}

.premium-plan-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 6rem;
}

.plan-options {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
  flex-wrap: wrap; /* Allow cards to wrap on smaller screens */
}

.plan-card {
  border-radius: 15px;
  border: none;
  padding: 20px;
  width: 100%; /* Full width for small screens */
  max-width: auto;
  height: 50vh;
  background-color: #fff;
  margin: 10px;
}

/* Active plan card style */
.plan-card.active {
  border: 1px solid #f38d00;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.plan-card:hover {
  border: 1px solid #f38d00;
  cursor: pointer;
}

.plan-card h3 {
  margin-bottom: 45px;
}

.plan-card p {
  margin-top: 20px;
}

.plan-card button {
  padding: 10px 15px;
  background-color: #fff;
  color: #f38d00;
  border-color: #f38d00;
  border-radius: 50px;
  border-width: 1.5px;
  cursor: pointer;
  width: 100%; /* Full width for button */
  max-width: 150px; /* Set a maximum width */
  margin-top: 25px;
}

.plan-card button:hover {
  background-color: #f38d00;
  color: white;
  border: none;
}

.get-started-div {
  display: flex;
  justify-content: center;
}
.correctimg img {
  margin-right: 15px;
}

.plancorrectimg {
  height: 100px;
}

.monthlyyearly {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

/* Media Queries */
@media (min-width: 768px) {
  .plan-card {
    width: 20%; /* Use 20% width for larger screens */
    height: auto; /* Keep the original height */
  }
}

.payment-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, #f2f2f2, #f4f6fc);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.payment-modal-content {
  background-color: white;
  padding-top: 20px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  align-items: center;
  height: 30%;
  width: 30%;
}

.payment-modal-header {
  border-bottom: 1px solid grey;
  padding-bottom: 10px; /* Space below the border */
  margin-bottom: 20px;
  width: 100%;
}

.payment-modal-content h3 {
  color: #f38d00;
}
.payment-modal-content button {
  padding: 7px 15px;
  background-color: #f38d00;
  color: #fff;
  border: 1.5px solid #f38d00;
  border-radius: 50px;
  width: 15rem;
  cursor: pointer;
  margin-top: 10px;
}

/* transaction history  */

/* CampusManagerInvitation.css */

.Transaction-page {
  margin: 30px;
  height: 100vh;
}

.transaction-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.transaction-page-header h4 {
  margin: 0; /* Remove default margin */
  font-size: 1.5rem; /* Adjust font size */
  color: #333; /* Darker text for visibility */
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff; /* White background for the table */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.transaction-body {
  width: 100%;
}

.transaction-table-header th {
  background-color: #011e39; /* Primary color for the header */
  color: #fff; /* White text for contrast */
  padding: 20px; /* Add padding for the header cells */
}

.transaction-body td {
  padding: 20px; /* Padding for table cells */
  border-bottom: 1px solid #ddd; /* Divider for rows */
}

.no-data {
  text-align: center;
  color: #999;
  padding: 20px; /* Add some padding for the no-data message */
}

.upgrade-plan-button {
  background-color: #f38d00;
  color: #fff;
  padding: 7px;
  width: 11rem;
  border-radius: 50px;
  border: none;
  height: 2.6rem;
  margin: 15px;
}

.candidate-header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.transaction-search-input-field {
  padding: 7px 7px 7px 20px;
  width: 17rem;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  outline: none;
  height: 2.6rem;
  border-radius: 50px;
  padding-left: 47px;
}

.transaction-search-input {
  position: relative;
}

.transaction-filter-dropdown {
  padding: 7px 7px 7px 20px;
  width: 17rem;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  outline: none;
  height: 2.6rem;
  border-radius: 50px;
  padding-left: 47px;
}

.transaction-search-input img {
  position: absolute;
  left: 17px;
  top: 13px;
}

.transaction-footer {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-top: 35px;
}

.transaction-fotter1 {
  background-color: #fff;

  justify-content: center;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
}
.transaction-fotter2 {
  background-color: #fff;

  justify-content: center;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
}
.transaction-fotter3 {
  background-color: #fff;

  justify-content: center;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
}

.transaction-footer p {
  font-weight: 500;
  font-family: "Poppins";
  font-size: x-large;
}

.transaction-table-header th:first-child {
  border-top-left-radius: 15px;
}

.transaction-table-header th:last-child {
  border-top-right-radius: 15px;
}
