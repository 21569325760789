.allJobs_main_container {
  padding-top: 5rem;
  font-family: "Poppins", sans-serif;
}

.contact_header {
  text-align: center;
}

.alljobs_header {
  padding-bottom: 0;
}

.alljobs_search_section {
  width: 55%;
  margin: auto;
  margin-top: -4rem;
}

.alljobs_jobs_section_head {
  width: 63%;
  margin: auto;
}

.alljobs_content_head_main {
  padding-top: 8rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.alljobs_box {
  padding-top: 14px;
  padding-bottom: 14px;
}

.alljobs_search_inputs {
  font-size: 15px;
  width: 182px !important;
}

.alljobs_box_img {
  width: 17px !important;
}

.alljobs_popular_search {
  color: #6c6c6c !important;
  padding-left: 7px;
  font-weight: 400 !important;
}

.allJobs_savedJobs_numbers {
  font-size: 35px;
  font-weight: 400;
  padding-top: 10px;
}

.filter-container {
  font-family: Arial, sans-serif;
  width: 300px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filter_Panel_Cancel {
  display: none;
}

.all_filters_btn {
  width: fit-content;
  padding: 7px 10px;
  margin-bottom: 2rem;
  background-color: #ffffff;
  border: 1px solid #00000036;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  cursor: pointer;
  display: flex;
  gap: 11px;
}

.icon {
  margin-right: 10px;
}

.filter-section {
  padding-top: 20px;
  padding-bottom: 3px;
}

.filter-section h3 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #2c2c2c;
}

.custom-pl-13 {
  padding-left: 16px;
}

.arrow {
  font-size: 14px;
}

.slider-container {
  padding: 10px 0;
  position: relative;
}

.slider {
  width: 100%;
  appearance: none;
  height: 3px;
  background: #d9d9d9;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  background: #ff7f00;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  z-index: 2;
}

.slider::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: #ff7f00;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  z-index: 2;
}

.slider-progress {
  position: absolute;
  height: 2px;
  background: #ff9d13a2;
  top: 26px;
  left: 0;
  pointer-events: none;
  transform: translateY(-50%);
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  color: #666;
  font-size: 14px;
}

.checkbox-label {
  display: block;
  position: relative;
  padding-left: 2.7rem;
  color: #3c3c3c;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  white-space: nowrap;
  width: fit-content;
}

.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-custom {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #ffffff;
  border: 2px solid #e0e0e0;
  border-radius: 4px;
}

.checkbox-label input:checked~.checkbox-custom {
  background-color: #ff7f00;
  border-color: #ff7f00;
}

.checkbox-custom:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-label input:checked~.checkbox-custom:after {
  display: block;
}

.checkbox-label .checkbox-custom:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.alljobs_content_inner_first {
  width: 24%;
}

.filter-panel {
  box-shadow: 0px 1px 3px 0px #9c9c9c;
  padding: 5px 30px 20px 30px;
  border-radius: 10px;
}

.job-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
}

.job-header h2 {
  font-size: 20px;
  font-weight: 600;
}

.sort-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 7px 8px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 150px;
}

.dropdown-arrow {
  margin-left: 10px;
}

.dropdown-menu {
  position: absolute;
  top: 95%;
  z-index: 55;
  width: 100%;
  display: block;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.dropdown-menu li {
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f5f5f5;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-container span {
  color: #7c8493;
  padding-right: 0;
}

.costom-pr-2 {
  padding-right: 12px !important;
}

.alljobs_content_inner_second {
  width: 71%;
}

.job-listings {
  font-family: "Poppins", sans-serif;
  width: 100%;
}

.job-listing {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 3px 0px #9c9c9cd1;
  padding: 15px 25px;
  margin-bottom: 25px;
}

.company-info {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.company-logo {
  width: 48px;
  height: 48px;
  margin-right: 16px;
  border: 2px solid #acbac7c7;
  border-radius: 8px;
  padding: 7px;
}

.company-name {
  font-weight: 500;
  margin-bottom: 4px;
  font-size: 14px;
  color: #2c2c2c;
  white-space: nowrap;
}

.rating {
  color: #666;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
}

.star {
  color: #faa61a;
  font-size: 14px;
}

.job-details {
  display: flex;
  font-size: 14px;
  color: #666;
  margin-bottom: 12px;
  align-items: center;
}

.job_listing_button_reverse {
  display: flex;
  gap: 2;
  justify-content: flex-end;
  flex-direction: row-reverse;
}

.detail {
  margin-right: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #2c2c2c;
  white-space: nowrap;
}

.detail .stand_icon {
  padding-left: 5px;
  font-weight: 200;
  font-size: 18px;
  color: black;
}

.details_job_icon {
  margin-top: -3px;
}

.jobDatails_description {
  padding: 20px 25px;
  box-shadow: 0px 0px 3px 0px #9c9c9cd1;
  border-radius: 13px;
  margin-bottom: 35px;
}

.listing-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding-top: 7px;
}

.posted-date {
  color: #2c2c2c;
  font-size: 13px;
}

.save-button {
  background: none;
  border: none;
  color: #2c2c2c;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  gap: 8px;
}

.save_button_letral {
  margin-top: 0 !important;
  padding: 7px 15px !important;
}

.company-info div h2 {
  margin: 0 0 4px 0;
  font-size: 16px;
  font-weight: 600;
  color: #111111e6;
}

/* job Details Section start */

.jobDetails_header {
  height: 330px;
}

.jobDetails_main_container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 63%;
  margin: auto;
  padding-top: 9rem;
  position: relative;
}

.jobDetails_first_container {
  width: 65%;
}

.jobDetails_second_container {
  width: 33%;
}

.No_similar_jobs_availble {
  position: absolute;
  right: 0;
  top: 12rem;
  padding: 1rem;
  border-radius: 15px;
  box-shadow: 0px 0px 3px 0px #9c9c9cd1;
  display: grid;
  place-items: center;
}

.jobDetails_b {
  font-size: 19px;
  font-weight: 600;
  color: #222222;
}

.custom_fonts {
  color: #111111;
  font-weight: 500;
}

.jobDetails_button {
  padding: 7px 17px;
  background-color: #f38d00;
  border: 1px solid transparent;
  border-radius: 8px;
  color: white;
  font-size: 14px;
  margin: 0px 5px;
}

.jobDetails_button:hover {
  background-color: #d98105;
}

.jobDetails_register_to_apply_btn {
  color: #dc8306;
  border: 1px solid #dc8306;
  padding: 7px 15px;
  font-weight: 500;
  font-size: 14px;
}

.jobDetails_register_to_apply_btn:hover {
  color: white;
  background-color: #dc8306;
}

.jobDatails_description h2 {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 1rem;
}

.jobDatails_description p {
  font-size: 14px;
  line-height: 1.5;
  padding-bottom: 12px;
}

.jobDatails_description ul {
  list-style-type: disc;
  padding-bottom: 5px;
  padding-left: inherit;
  padding-top: 13px;
}

.jobDatails_description ul li {
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

.jobDatails_description p span {
  color: #222222f0;
  font-weight: 600;
}

.about-company {
  padding: 20px 25px;
  box-shadow: 0px 0px 3px 0px #9c9c9cd1;
  border-radius: 8px;
}

.about-company h2 {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 1rem;
}

.about-company p {
  font-size: 14px;
  line-height: 1.5;
  padding-bottom: 12px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: fit-content;
}

.social-icon {
  color: #333;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #007bff;
}

/* login pop screen start */

.jobDetails_login_popup_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #0000001a;
  backdrop-filter: blur(1px);
  position: fixed;
  top: 0;
  z-index: 999;
}

.jobDetails_login_popup_second_main {
  margin: auto;
  width: 32%;
  background-color: #fff;
  border-radius: 20px;
  position: relative;
}

.jobDetails_login_popup_second_container {
  padding-top: 2rem;
  padding-bottom: 2.5rem;
}

.category-container-scroll {
  max-height: 170px;
  overflow-y: auto;
  overflow-x: hidden;
}

.category-container-scroll::-webkit-scrollbar {
  width: 5px;
}

.category-container-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.category-container-scroll::-webkit-scrollbar-thumb {
  background-color: orange;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
}

.category-container-scroll::-webkit-scrollbar-thumb:hover {
  background-color: darkorange;
}

.suggestions-list {
  position: absolute;
  border: 1px solid #cccccc85;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  width: 100%;
  top: 52px;
  left: 0;
}

.suggestions-list::-webkit-scrollbar {
  width: 6px;
}

.suggestions-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.suggestions-list::-webkit-scrollbar-thumb {
  background: #f38e00d0;
  border-radius: 5px;
}

.suggestions-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
  list-style: none;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

.jobDeatils_login_crossIcon {
  position: absolute;
  top: 9px;
  right: 15px;
  cursor: pointer;
}

.jobDetails_button:disabled,
.disabled_button {
  background-color: #d3d3d3;
  color: #808080;
  cursor: not-allowed;
  pointer-events: none;
}

.job_details_back_button {
  width: fit-content;
  border: none;
  background: no-repeat;
  margin-right: 1.3rem;
}

.letral_rating_container {
  display: flex;
  gap: 1rem;
}

@media (max-width: 1850px) and (min-width: 1751px) {

  .alljobs_jobs_section_head,
  .jobDetails_main_container {
    width: 84% !important;
  }

  .alljobs_search_inputs {
    width: fit-content;
  }
}

@media (max-width: 1750px) and (min-width: 1399px) {

  .alljobs_jobs_section_head,
  .jobDetails_main_container {
    width: 84% !important;
  }
}

@media (max-width: 1305px) and (min-width: 1200px) {
  .all_filters_btn {
    font-size: 12px !important;
  }

  .all_filters_btn span img {
    width: 14px;
  }

  .all_filters_btn span svg {
    width: 11px;
  }
}

@media (max-width: 1400px) and (min-width: 1199px) {

  .alljobs_jobs_section_head,
  .jobDetails_main_container {
    width: 85%;
  }

  .job-details {
    font-size: 14px;
  }
}

@media (max-width: 1320px) and (min-width: 991px) {

  .alljobs_jobs_section_head,
  .jobDetails_main_container {
    width: 85%;
  }

  .jobDetails_button {
    font-size: 12px;
  }

  .job-details {
    font-size: 12px;
  }

  .jobDetails_register_to_apply_btn {
    font-size: 12px;
  }
}

@media (max-width: 1191px) and (min-width: 991px) {
  .jobDetails_main_container {
    padding-top: 2rem;
  }

  .job-details {
    font-size: 14px !important;
    display: ruby;
  }

  .listing-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding-top: 7px;
  }

  .jobDetails_main_container {
    padding-top: 2rem;
  }

  .job_listing_button_reverse {
    margin-top: 1em;
  }

  .jobDetails_register_to_apply_btn {
    font-size: 12px;
  }

  .No_similar_jobs_availble {
    top: 5rem;
  }
}

@media (max-width: 1035px) and (min-width: 575px) {
  .alljobs_jobs_section_head {
    width: 85%;
  }

  .No_similar_jobs_availble {
    position: initial;
  }

  .jobDetails_button {
    font-size: 14px;
  }

  .job-details {
    font-size: 14px;
  }

  .jobDetails_main_container {
    padding-top: 2rem;
    width: 85%;
    display: flex;
    /* Ensure it's a flex container */
    flex-direction: column;
    gap: 20px;
    /* Add a gap for spacing */
  }

  .listing-footer {
    display: flex;
  }

  .jobDetails_first_container,
  .jobDetails_second_container {
    width: 100%;
    margin-bottom: 20px;
    /* Ensure there are margins */
  }
}

@media (max-width: 700px) and (min-width: 577px) {
  .saved-jobs-company-review {
    flex-direction: column;
    gap: 0 !important;
  }

  .company-name {
    font-size: 12px;
  }

  .company-info div h2 {
    font-size: 15px;
  }

  .listing-footer {
    display: flex;
  }

  .jobDetails_login_popup_second_main {
    width: 87%;
  }
}

@media (max-width: 576px) and (min-width: 430px) {

  .letral_rating_container {
    display: grid;
    gap: 0;
  }

  .alljobs_jobs_section_head {
    width: 85%;
  }

  .No_similar_jobs_availble {
    position: initial;
  }

  .job-details {
    display: ruby;
    /* Enable ruby display */
  }

  .detail .stand_icon {
    display: none;
  }

  .job_listing_button_reverse {
    margin-top: 1em;
  }

  .jobDetails_main_container {
    padding-top: 2rem;
    width: 85%;
    display: flex;
    /* Ensure it's a flex container */
    flex-direction: column;
    gap: 20px;
    /* Add a gap for spacing */
  }

  .listing-footer {
    display: flex;
  }

  .jobDetails_first_container,
  .jobDetails_second_container {
    width: 100%;
    margin-bottom: 20px;
  }

  .jobDetails_button {
    font-size: 10px;
  }

  .jobDetails_register_to_apply_btn {
    font-size: 10px;
  }

  .company-info div h2 {
    font-size: 13px !important;
  }

  .all_filters_btn {
    font-size: 11px;
  }

  .all_filters_btn span img {
    width: 13px;
  }

  .all_filters_btn span svg {
    width: 13px;
  }

  .job-header h2 {
    font-size: 18px;
  }

  .jobDetails_login_popup_second_main {
    width: 87%;
  }

  .more-options-dropdown {
    top: 28px;
    left: -7rem;
  }
}

@media (max-width: 430px) and (min-width: 150px) {

  .letral_rating_container {
    display: grid;
    gap: 0;
  }

  .alljobs_jobs_section_head {
    width: 85%;
  }

  .No_similar_jobs_availble {
    position: initial;
  }

  .jdrc_class {
    display: block !important;
  }

  /* .d-flex {
   display: block !important;
  } */

  .detail {
    margin-top: 5px;
  }

  .jobDetails_main_container {
    padding-top: 2rem;
  }

  .detail .stand_icon {
    display: none;
  }

  .jobDetails_main_container {
    width: 85%;
    flex-direction: column;
  }

  .listing-footer {
    display: flex;
  }

  .listing-footer-respon-grid {
    display: grid;
  }

  .jobDetails_first_container,
  .jobDetails_second_container {
    width: 100%;
    margin-bottom: 20px;
  }

  .job_listing_button_reverse {
    display: flex;
    gap: 0.7em;
    justify-content: flex-end;
    flex-direction: column-reverse;
  }

  .company-info div h2 {
    font-size: 14px !important;
  }

  .jobDetails_button {
    font-size: 10px;
  }

  .all_filters_btn {
    font-size: 11px;
  }

  .jobDetails_login_popup_second_main {
    width: 87%;
  }

  .more-options-dropdown {
    top: 28px;
    left: -7rem;
  }
}

@media (max-width: 1400px) and (min-width: 1100px) {
  .myApplication_section_head {
    width: 75% !important;
  }

  .filter-section h3 {
    font-size: 13px;
  }

  .checkbox-label {
    font-size: 14px;
  }

  .job-header h2 {
    font-size: 18px;
  }
}

@media (max-width: 1100px) and (min-width: 950px) {
  .myApplication_section_head {
    width: 78% !important;
  }
}

@media (max-width: 950px) and (min-width: 750px) {
  .myApplication_section_head {
    width: 85% !important;
  }

  .filter {
    padding: 6px 10px !important;
    font-size: 12px !important;
  }

  .status.shortlisted {
    padding: 6px 10px !important;
    font-size: 12px !important;
  }

  .application-details h3 {
    font-size: 13px !important;
    font-weight: 600 !important;
  }

  .application-date p:first-child {
    font-weight: 600 !important;
    font-size: 13px !important;
  }

  .myApplication_top_header b {
    font-size: 16px !important;
    font-weight: 600 !important;
  }

  .more-options {
    font-size: 25px !important;
  }
}

@media (max-width: 750px) and (min-width: 400px) {
  .myApplication_section_head {
    width: 88% !important;
  }

  .myApplication_top_header {
    flex-direction: column;
    /* Stack items vertically */
    gap: 1.5rem;
    /* Space between stacked items */
  }

  .filter {
    padding: 6px 10px !important;
    font-size: 12px !important;
  }

  .status.shortlisted {
    padding: 6px 10px !important;
    font-size: 12px !important;
  }

  .application-details h3 {
    font-size: 13px !important;
    font-weight: 600 !important;
  }

  .application-date p:first-child {
    font-weight: 600 !important;
    font-size: 11px !important;
  }

  .application-date p {
    font-size: 11px !important;
  }

  .myApplication_top_header b {
    font-size: 16px !important;
    font-weight: 600 !important;
  }

  .more-options {
    font-size: 25px !important;
  }

  .application-details p {
    font-size: 11px !important;
  }

  .search-input {
    width: 100% !important;
  }

  .search-location {
    width: 100% !important;
  }

  .search-select {
    width: 100% !important;
  }
}

@media (max-width: 460px) and (min-width: 150px) {
  .myApplication_top_header {
    padding: 0px 0.6rem 20px 0.2rem !important;
  }

  .status.shortlisted {
    display: none;
  }

  .status.inreview {
    display: none;
  }

  .status.rejected {
    display: none !important;
  }

  .myApplication_section_head {
    width: 88% !important;
  }

  .myApplication_top_header {
    flex-direction: column;
    /* Stack items vertically */
    gap: 1.5rem;
    /* Space between stacked items */
  }

  .filter {
    padding: 6px 10px !important;
    font-size: 12px !important;
  }

  .status.shortlisted {
    padding: 6px 10px !important;
    font-size: 12px !important;
  }

  .application-details h3 {
    font-size: 13px !important;
    font-weight: 600 !important;
  }

  .application-date p:first-child {
    font-weight: 600 !important;
    font-size: 11px !important;
  }

  .application-date p {
    font-size: 11px !important;
  }

  .myApplication_top_header b {
    font-size: 16px !important;
    font-weight: 600 !important;
  }

  .more-options {
    font-size: 25px !important;
  }

  .application-details p {
    font-size: 11px !important;
  }

  .search-input {
    width: 100% !important;
  }

  .search-location {
    width: 100% !important;
  }

  .search-select {
    width: 100% !important;
  }
}

@media (max-width: 1460px) and (min-width: 1399px) {
  .search-input {
    width: 100px !important;
  }

  .search-location {
    width: 7rem !important;
  }
}

@media (max-width: 1400px) and (min-width: 1000px) {
  .search-input {
    width: 100px !important;
  }

  .search-location {
    width: 7rem !important;
  }
}

@media (max-width: 1255px) and (min-width: 1195px) {
  .search-button {
    padding: 7px 15px !important;
  }
}

@media (max-width: 1200px) and (min-width: 850px) {
  .alljobs_jobs_section_head {
    width: 90%;
  }

  .alljobs_content_inner_first {
    width: 26%;
  }

  .custom-pl-13 {
    padding-left: 0px;
  }

  .box {
    width: 600px;
  }

  .all_filters_btn {
    font-size: 12px !important;
  }

  .all_filters_btn span img {
    width: 15px;
  }

  .all_filters_btn span svg {
    width: 12px;
  }

  .filter-section h3 {
    font-size: 13px;
  }

  .checkbox-label {
    font-size: 14px;
  }
}

.alljobs_content_inner_first {
  position: relative;
}

@media (max-width: 1000px) and (min-width: 851px) {
  .alljobs_search_section {
    width: 100%;
  }

  .box {
    width: 100%;
  }
}

@media (max-width: 850px) {
  .all_filters_btn {
    width: fit-content;
  }

  .alljobs_content_inner_first {
    width: fit-content;
    float: right;
  }

  .alljobs_content_head_main {
    padding-top: 8rem;
    display: block;
  }

  .filter_Panel_Cancel {
    cursor: pointer;
    background-color: white;
    border: none;
    justify-content: end;
    /* float: right; */
    width: 99%;
    font-size: large;
    /* align-items: flex-end; */
    display: flex;
    box-shadow: 1px 2px 3px 2px #c3bcbc85;
    width: fit-content;
    float: right;
    border-radius: 15px;
    padding: 6px;
  }

  .alljobs_search_section {
    width: 100%;
  }

  .dropdown-toggle {
    display: none;
  }

  .filter-panel {
    position: fixed;
    top: 21px;
    left: 10px;
    width: 95%;
    bottom: 0;
    background-color: white;
    z-index: 999;
    overflow-y: auto;
    padding: 20px;
  }

  .filter-panel.active {
    display: block;
    /* Show the filter panel when active */
  }

  .filter-section {
    margin-bottom: 20px;
    /* Spacing between sections */
  }

  .checkbox-container {
    margin-left: 10px;
    /* Indent checkbox items */
  }

  .checkbox-label {
    font-size: 16px;
    /* Larger font size for checkboxes */
    display: flex;
    /* Use flexbox for alignment */
    align-items: center;
    /* Center items vertically */
  }

  .checkbox-custom {
    margin-right: 10px;
    /* Space between checkbox and label */
  }

  .arrow {
    margin-left: auto;
    /* Push arrow to the right */
  }

  .alljobs_content_inner_second {
    width: 100%;
  }
}

@media (max-width: 675px) and (min-width: 575px) {
  .search-input {
    width: 100px !important;
  }

  .search-location {
    width: 7rem !important;
  }
}

@media (max-width: 768px) and (min-width: 575px) {
  .search-input {
    width: 100px !important;
    font-size: 12px;
  }
}