.campuse_student_request_container {
  width: 100%;
  height: auto;
  padding: 1.5rem 2rem;
}

.campuse_candidate_request_sub_container {
  width: 100%;
  height: 100%;
}

.campuse_student_request_header {
  display: flex;
  width: auto;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
}

.campuse_student_request_search-bar input {
  padding: 0.9rem 0.8rem;
  padding-left: 18px;
  margin-right: 1.5rem;
  border-radius: 30px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  outline: none;
  border: none;
}

.campuse_student_request_dropdown {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.campuse_student_request_dropdown,
.campuse_student_request_dropdown select {
  background-color: white;
  padding: 0.5rem 0.8rem;
  border: none;
  outline: none;
  border-radius: 30px;
  border: none;
  appearance: none;
  cursor: pointer;
}

.campuse_student_request_dropdown_section option {
  background-color: white;
  padding: 0.5rem 1.2rem;
  border: none;
  border-radius: 30px;
}

.campuse_student_request_list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: start;
}

.campuse_student_request_card {
  flex: 1 1 300px;
  max-width: 455px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.campuse_student_request_card p {
  font-family: "Poppins" !important;
  font-weight: 500;
  font-size: 18px;
}

.campuse_student_request_card p span {
  color: #474d47;
  font-weight: 300;
}

.campuse_student_request_actions {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  height: 42px;
}

.campuse_student_request_approve_button {
  font-size: 13px;
  background-color: #f38d00;
  color: #fff;
  border: none;
  padding: 6px 18px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 700;
  margin-right: 8px;
  transition: background-color 0.3s ease;
}

.campuse_student_request_approve_button:hover {
  background-color: #e07a00;
  /* Slightly darker shade for hover effect */
}

.rejected-btn {
  background-color: #e07a00;
}

.campuse_student_request_reject-button {
  background-color: #fff;
  color: #f38d00;
  border: 2px #f38d00 solid;
  padding: 6px 18px;
  border-radius: 20px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.details-link {
  display: inline-block;
  margin-top: 8px;
  color: #f38d00;
  text-decoration: none;
  white-space: nowrap;
  font-size: 14px;
}

.details-link:hover {
  text-decoration: underline;
}

.no_candidate_found {
  width: 26%;
  margin: auto;
  padding-top: 9rem;
}

.no_candidate_found p {
  font-size: 1.5rem;
  font-weight: 600;
}

@media (max-width: 1450px) and (min-width: 1201px) {
  .campuse_student_request_card {
    flex: 1 1 calc(45% - 16px);
  }
}

@media (max-width: 1201px) and (min-width: 180px) {
  .campuse_student_request_card {
    flex: 1 1 50%;
  }
}

/* Loader styling */

.no-requests-message {
  text-align: center;
  font-size: 1.5rem;
  color: #555;
  margin: 2rem 0;
}

.campuse_student_request_rejected_button {
  background-color: #fff;
  color: #f38d00;
  border: 2px #f38d00 solid;
  padding: 6px 18px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}
